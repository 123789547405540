<template>
  <b-tab title="Kriteria Discharge Planning">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-sm  table-bordered">
          <tbody>
            <tr>
              <th colspan="2" class="bg-primary">KRITERIA FAKTOR RESIKO: </th>
            </tr>
            <tr v-for="(v, k) in dataCriteria.ardpc_criteria" :key="k">
              <td width="400">{{ v.label }} </td>
              <td>
                <div>
                  <span class="text-capitalize">{{ (v.value||"").toLowerCase() }}</span>
                </div>
              </td>
            </tr>                  
          </tbody>
          <tbody v-if="(dataCriteria.ardpc_discharge_planning||[]).length">
            <tr>
              <th colspan="2" class="bg-primary">PERENCANAAN PULANG </th>
            </tr>
            <tr>
              <td colspan="2">
                <div class="d-flex">
                  <div class="d-flex align-items-center mr-2 mb-2" v-for="(v, k) in dataCriteria.ardpc_discharge_planning" :key="`dp-${k}`">
                    <i class="icon-checkmark-circle text-success"></i>
                    <span class="ml-1">{{ v }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-tab>
</template>


<script>
export default {
  name: "CriteriaDischargePlanning",
  props: {
    dataCriteria: {
      type: [Object, null],
      default: () => ({})
    }
  }
}
</script>