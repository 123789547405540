<template>  
  <div>
    <b-tab title="Kajian Persalinan" v-if="showTabKajianPersalinan">
      <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0">
        <b-tab title="Kajian Mandiri SC" v-if="!emptyKajianMandiri">
          <KajianMandiri :data="kajianData.kajianMandiri" />
        </b-tab>
        <b-tab title="Laporan Nifas" v-if="!emptyLaporanNifas">
          <LaporanNifas :data="kajianData.laporanNifas" :data-list="kajianData.laporanNifasData" />
        </b-tab>
        <b-tab title="Catatan Persalinan" v-if="!emptyCatatanPersalinan">
          <CatatanPersalinan :data="kajianData.catatanPersalinan" />
        </b-tab>
        <b-tab title="Laporan Persalinan" v-if="!emptyLaporanPersalinan">
          <LaporanPersalinan :data="kajianData.laporanPersalinan" />
        </b-tab>
      </b-tabs>
    </b-tab>
  </div>
</template>

<script>
import KajianMandiri from '@/components/Ranap/KajianPersalinanCmp/KajianMandiri.vue'
import LaporanPersalinan from '@/components/Ranap/KajianPersalinanCmp/LaporanPersalinan.vue'
import LaporanNifas from '@/components/Ranap/KajianPersalinanCmp/LaporanNifas.vue'
import CatatanPersalinan from '@/components/Ranap/KajianPersalinanCmp/CatatanPersalinan.vue'

export default {
  data(){
    return {
      idKajian: null,
      activeTab: 1,
      activeTabPerawat: 1,      
    }
  },
  props: {
    kajianData: Object,
    tab: Number
  },
  computed: {
    showTabKajianPersalinan(){
      return !this.emptyKajianMandiri || !this.emptyLaporanNifas || !this.emptyCatatanPersalinan || !this.emptyLaporanPersalinan
    },
    emptyKajianMandiri(){
      return !Object.keys(this.kajianData.kajianMandiri||{}).length
    },
    emptyCatatanPersalinan(){
      return !Object.keys(this.kajianData.catatanPersalinan||{}).length
    },
    emptyLaporanPersalinan(){
      return !(this.kajianData.laporanPersalinan||[]).length
    },
    emptyLaporanNifas(){
      return !Object.keys(this.kajianData.laporanNifas||{}).length && !(this.kajianData.laporanNifasData||[]).length
    }
  },
  components:{
    KajianMandiri,
    LaporanPersalinan,
    LaporanNifas,
    CatatanPersalinan
  }
}
</script>