<template>
<div>
  <button type="button" class="btn btn-success btn-labeled btn-labeled-left mt-2 mb-2" @click="downloadKajianMandiri(data)">
    <b class="mr-2">
      <i class="icon-download"></i>
    </b>
    <span>Download Kajian Mandiri SC</span>
  </button>

  <table class="table table-sm  table-bordered mb-2">
    <tbody>
      <tr>
        <th style="width:10%;" class="bg-primary">No </th>
        <th style="width:40%;" class="bg-primary"> Indikator Proses </th>
        <th class="bg-primary"> </th>
      </tr>

      <tr v-for="(v, k) in data.indikator_proses" :key="`ip-${k}`">
        <td>{{ k + 1 }} </td>
        <td>
          <span class="mb-0">{{ v.label }} </span>

          <template v-if="v.additional_label">
            <span class="mb-0"><strong>{{ v.option_value }}</strong> {{ v.additional_label }}</span>
          </template>
        </td>
        <td>
          <div>
            {{ v.value == "Y"  ? "Ya" : "Tidak" }}
          </div>
        </td>
      </tr>

      <tr>
        <td class="table-info">{{ (data.indikator_proses||[]).length + 1 }}</td>
        <td class="table-info" colspan="2"><strong>Asesmen persalinan pasien menggunakan partograf ditulis
            lengkap sebelum SC</strong></td>
      </tr>
      <tr v-for="(v, k) in data.asasemen_persalinan" :key="`ap-${k}`">
        <td>{{ getAlphabetFromIndex(k) }}.</td>
        <td>{{ v.label }}</td>
        <td>
          <div>
            {{ v.value == "Y" ? "Ya" : "Tidak" }}
          </div>
        </td>
      </tr>

      <tr>
        <td class="table-info">17</td>
        <td class="table-info" colspan="2"><strong>Pasien dilakukan dengan indikasi SC (pilih):</strong>
        </td>
      </tr>

      <tr v-for="(v, k) in data.indikasi_sc" :key="`isc-${k}`">
        <td>{{ getAlphabetFromIndex(k) }}).</td>
        <td>
          {{ v.label }} <br>
          {{ v.other_value }}
        </td>
        <td>
          <div>
            {{ v.value == "Y" ? "Ya" : "Tidak" }}
          </div>
        </td>
      </tr>

    </tbody>

  </table>
  <table class="table table-sm  table-bordered">
    <tbody>
      <tr>
        <th style="width:10%;" class="bg-primary">No </th>
        <th style="width:40%;" class="bg-primary">Indikator Luaran </th>
        <th class="bg-primary"> </th>
      </tr>

      <tr v-for="(v, k) in data.indikator_luaran" :key="`il-${k}`">
        <td>{{ k + 1 }}</td>
        <td>{{ v.label }} </td>
        <td>
          <div>
            {{ v.value == "Y" ? "Ya" : "Tidak" }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="table table-sm  table-bordered mb-2 mt-2 text-center">
    <tbody>
      <tr>
        <td class="align-top" style="width:50%;">
          <p style="margin:0;"><strong>Nama</strong></p>
          <span>{{ data.name }}</span>
        </td>
        <td class="align-top" width="50%">
          <p style="margin:0;"><strong>Tanggal Pengisian</strong></p>
          <span>{{ data.date | moment("LL") }}</span>
        </td>
        <!--
        <td class="align-top" width="33%">
          <div>
            <p style="margin:0;"><strong>Staf Penerima</strong></p>
            <span>{{ data.staff_name }}</span>
            <div>
              <img :src="data.signature" alt="" style="width: 100px; height: 100px;">
            </div>
          </div>
        </td>
        -->
      </tr>

    </tbody>
  </table>
</div>
</template>

<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data() {
    return {
      keadaanUmum: false,
      activeAnat: 0,
      openModalICDetail: false,
      rowIC: {}
    }
  },
  props: {
    data: Object,
  },
  computed: {
    // dataKajian() {
    //   return this.$parent.$parent.kajianData.kajianDokter
    // },
    // dataPenunjang() {
    //   return this.$parent.$parent.kajianData.kajianPenunjang
    // },
    Config() {
      return Config
    },
  },
  methods: {
    getAlphabetFromIndex(index) {
      return String.fromCharCode(index + 97)
    },

    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    downloadKajianMandiri(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

       Gen.apiRest(
        "/do/" + "RanapKajianMandiriSC", {
          data: {
            id_registrasi: data.aranr_id,
            type: 'download-kajian-mandiri-sc'
          }
        },
        "POST"
      ).then(res => {
        this.$swal.close()
        const linkSource = res.data
        const downloadLink = document.createElement("a")
        downloadLink.href = linkSource
        downloadLink.download = `Kajian-Mandiri-${new Date().getTime()}.pdf`
        downloadLink.click()

        this.$swal.close()
        this.$bvModal.hide("previewDownload")
         
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },
  }
}
</script>
