<template>
  <div>
    <div class="card shadow-0 border" v-if="dataKajian.row.ap_usia <= 13">
      <div class="card-header bg_head_panel">
        <h5 class="card-title font-weight-semibold">Antropometri (Pasien Anak kurang dari 13 tahun)</h5>
      </div>
      <table class="table table-bordered table-sm">
        <tr>
          <td width="33%">
            <div class="result_tab">
              <h4>Berat Badan</h4>
              <p v-if="dataKajian.row.arankg_bb">{{dataKajian.row.arankg_bb||"-"}}kg</p>
              <p v-else> - </p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Tinggi Badan / Panjang Badan</h4>
              <p v-if="dataKajian.row.arankg_tb">{{dataKajian.row.arankg_tb||"-"}}cm</p>
              <p v-else> - </p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>BB Ideal</h4>
              <p v-if="dataKajian.row.arankg_bb_ideal">{{dataKajian.row.arankg_bb_ideal||"-"}}kg</p>
              <p v-else> - </p>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="result_tab">
              <h4>% BB Ideal</h4>
              <p v-if="dataKajian.row.arankg_bb_ideal_pecentage">{{dataKajian.row.arankg_bb_ideal_pecentage||"-"}}%</p>
              <p v-else> - </p>
            </div>
          </td>
          <td>
            <div class="result_tab">
              <h4>Z Score</h4>
              <p>{{getConfigDynamic(Config.mr.zScore,dataKajian.row.arankg_zscore)||"-"}}</p>
            </div>
          </td>
          <td>
            <div class="result_tab">
              <h4>Status Gizi</h4>
              <p> {{dataKajian.row.arankg_status_gizi}} </p>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="card shadow-0 border" v-else>
      <div class="card-header bg_head_panel">
        <h5 class="card-title font-weight-semibold">Antropometri (Pasien Dewasa lebih dari 13 tahun)</h5>
      </div>
      <table class="table table-bordered table-sm">
        <tr>
          <td width="33%">
            <div class="result_tab">
              <h4>Berat Badan</h4>
              <p v-if="dataKajian.row.arankg_bb">{{dataKajian.row.arankg_bb||"-"}}kg</p>
              <p v-else> - </p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Tinggi Badan / Panjang Badan</h4>
              <p v-if="dataKajian.row.arankg_tb">{{dataKajian.row.arankg_tb||"-"}}cm</p>
              <p v-else> - </p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>BB Ideal</h4>
              <p v-if="dataKajian.row.arankg_bb_ideal">{{dataKajian.row.arankg_bb_ideal||"-"}}kg</p>
              <p v-else> - </p>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="result_tab">
              <h4>% BB Ideal</h4>
              <p v-if="dataKajian.row.arankg_bb_ideal_pecentage">{{dataKajian.row.arankg_bb_ideal_pecentage||"-"}}%</p>
              <p v-else> - </p>
            </div>
          </td>
          <td>
            <div class="result_tab">
              <h4>IMT</h4>
              <p v-if="dataKajian.row.arankg_imt">{{dataKajian.row.arankg_imt||"-"}}kg/m2</p>
              <p v-else> - </p>
            </div>
          </td>
          <td>
            <div class="result_tab">
              <h4>Status Gizi Ibu</h4>
              <p>{{dataKajian.row.arankg_status_gizi||"-"}}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="card shadow-0 border">
      <div class="card-header">
        <h5 class="card-title font-weight-semibold">Kebiasaan Makan</h5>
      </div>
      <table class="table table-bordered table-sm">
        <tr>
          <td width="33%">
            <div class="result_tab">
              <h4>Alergi Makanan</h4>
              <p>{{getConfigDynamic(Config.mr.yesNoOpt,dataKajian.row.arankg_alergi_makan)||"-"}}</p>
              <small v-if="dataKajian.row.arankg_alergi_makan == 'Y'">{{dataKajian.row.arankg_alergi_makan_text||"-"}}</small>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Pola Makan</h4>
              <p>{{getConfigDynamic(Config.mr.ranapGiziPolaMakan,dataKajian.row.arankg_pola_makan)||"-"}}</p>
            </div>
          </td>
          <td width="33%">
            <div class="result_tab">
              <h4>Cara Pemberian</h4>
              <p>{{getConfigDynamic(Config.mr.ranapGiziCaraPemberian,dataKajian.row.arankg_cara_pemberian)||"-"}}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    
    <div class="row">
      <div class="col-12">
          <div class="card mb-0">
          <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
          </div>
          <div class="card-body p-3">
              <div class="row">
                  <div class="col-md-12">
                      <h6>Hasil Pemeriksaan Lab</h6>
                      <table class="table table-bordered table-striped table-sm patient-table">
                          <thead>
                          <tr>
                              <th>Hasil Pemeriksaan </th>
                              <th>Hasil</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(v,k) in (dataPenunjang.dataHasilLab||[])" :key="k+'hasil'">
                              <td>{{v.value}}</td>
                              <td>
                                  <div class="result_tab form-group mb-0">
                                  <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                  </div>
                              </td>
                          </tr>
                          <tr v-if="!(dataPenunjang.dataHasilLab||[]).length">
                              <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                          </tr>
                          </tbody>
                      </table>
                  </div>

                  <div class="col-md-12 mt-3">
                      <h6>Hasil Pemeriksaan Radiologi</h6>
                      <table class="table table-bordered table-striped table-sm patient-table">
                          <thead>
                          <tr>
                              <th>Hasil Pemeriksaan </th>
                              <th>Hasil</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologi||[])" :key="k+'hasil'">
                              <td>{{v.value}}</td>
                              <td>
                                  <div class="result_tab form-group mb-0">
                                  <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                  </div>
                              </td>
                          </tr>
                          <tr v-if="!(dataPenunjang.dataHasilRadiologi||[]).length">
                              <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              <div class="row mt-3">
                  <div class="col-md-12">
                      <h6>Hasil Pemeriksaan Lab Dokter</h6>
                      <table class="table table-bordered table-striped table-sm patient-table">
                          <thead>
                          <tr>
                              <th>Hasil Pemeriksaan </th>
                              <th>Hasil</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(v,k) in (dataPenunjang.dataHasilLabDokter||[])" :key="k+'hasil'">
                              <td>{{v.value}}</td>
                              <td>
                                  <div class="result_tab form-group mb-0">
                                  <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                  </div>
                              </td>
                          </tr>
                          <tr v-if="!(dataPenunjang.dataHasilLabDokter||[]).length">
                              <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                          </tr>
                          </tbody>
                      </table>
                  </div>

                  <div class="col-md-12 mt-3">
                      <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                      <table class="table table-bordered table-striped table-sm patient-table">
                          <thead>
                          <tr>
                              <th>Hasil Pemeriksaan </th>
                              <th>Hasil</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                              <td>{{v.value}}</td>
                              <td>
                                  <div class="result_tab form-group mb-0">
                                  <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                  </div>
                              </td>
                          </tr>
                          <tr v-if="!(dataPenunjang.dataHasilRadiologiDokter||[]).length">
                              <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card shadow-0 border">
          <div class="card-header">
            <h5 class="card-title font-weight-semibold">Fisik/Klinis</h5>
          </div>
          <table class="table table-sm table-bordered">
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Keluhan</h4>
                  <p>{{getConfigDynamic(Config.mr.yesNoOptKeluhan,dataKajian.row.arankg_fisik_keluhan)||"-"}}</p>

                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <div class="det-check-item" v-if="dataKajian.row.arankg_is_diare == 'Y'">
                    <i class="icon-checkmark-circle text-success"></i>
                    <div class="d-flex flex-column">
                      <span class="font-weight-semibold">Diare</span>
                      <span>{{dataKajian.row.arankg_diare_text||"-"}}</span>
                    </div>
                  </div>
                  <div class="det-check-item" v-if="dataKajian.row.arankg_is_muntah == 'Y'">
                    <i class="icon-checkmark-circle text-success"></i>
                    <div class="d-flex flex-column">
                      <span class="font-weight-semibold">Muntah</span>
                      <span>{{dataKajian.row.arankg_muntah_text||"-"}}</span>
                    </div>
                  </div>
                  <div class="det-check-item" v-if="dataKajian.row.arankg_is_lainnya == 'Y'">
                    <i class="icon-checkmark-circle text-success"></i>
                    <div class="d-flex flex-column">
                      <span class="font-weight-semibold">Muntah</span>
                      <span>{{dataKajian.row.arankg_lainnya_text||"-"}}</span>
                    </div>
                  </div>
                  
                  <div class="det-check-item" v-if="dataKajian.row.arankg_is_gangguan_menelan == 'Y'">
                    <i class="icon-checkmark-circle text-success"></i>
                    <div class="d-flex flex-column">
                      <span class="font-weight-semibold">Gangguan Menelan</span>
                    </div>
                  </div>

                  <div class="det-check-item" v-if="dataKajian.row.arankg_is_konstipasi == 'Y'">
                    <i class="icon-checkmark-circle text-success"></i>
                    <div class="d-flex flex-column">
                      <span class="font-weight-semibold">Konstipasi</span>
                    </div>
                  </div>

                  
                  <div class="det-check-item" v-if="dataKajian.row.arankg_is_gangguan_mengunyah == 'Y'">
                    <i class="icon-checkmark-circle text-success"></i>
                    <div class="d-flex flex-column">
                      <span class="font-weight-semibold">Gangguan Mengunyah</span>
                    </div>
                  </div>
                  
                  
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <table class="table table-bordered table-sm">
        <tr>
          <td>
            <div class="result_tab">
              <h4>Riwayat Personal</h4>
              <p>{{dataKajian.row.arankg_riwayat_personal||"-"}}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="card shadow-0 border">
      <div class="card-header">
        <h5 class="card-title font-weight-semibold">Problem , Etiologi, Sign & Symptom</h5>
      </div>
      <table class="table table-bordered table-sm">
        <tr class="table-info">
          <th width="33%"><span class="font-weight-semibold">Problem</span></th>
          <th width="33%"><span class="font-weight-semibold">Etiology/Penyebab</span></th>
          <th width="33%"><span class="font-weight-semibold">Tanda dan Gejala</span></th>
        </tr>
        <tr>
          <td>
            <table class="table table-bordered table-sm">
              <tr v-for="(v,k) in (dataKajian.row.arankg_problem||[])" :key="k">
                <td>
                  <div class="result_tab">
                    <h4>Problem #{{k+1}}</h4>
                    <p>{{v.id ? (v.name||"-") : (v.text||"-")}}</p>
                  </div>
                </td>
              </tr>
              <tr v-if="!(dataKajian.row.arankg_problem||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </table>
          </td>
          <td>
            <table class="table table-bordered table-sm">
              <tr v-for="(v,k) in (dataKajian.row.arankg_etiologi||[])" :key="k">
                <td>
                  <div class="result_tab">
                    <h4>Etiologi #{{k+1}}</h4>
                    <p>{{v.id ? (v.name||"-") : (v.text||"-")}}</p>
                  </div>
                </td>
              </tr>
              <tr v-if="!(dataKajian.row.arankg_etiologi||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </table>
          </td>
          <td>
            <table class="table table-bordered table-sm">
              <tr v-for="(v,k) in (dataKajian.row.arankg_sign||[])" :key="k">
                <td>
                  <div class="result_tab">
                    <h4>Tanda dan Gejala #{{k+1}}</h4>
                    <p>{{v.id ? (v.name||"-") : (v.text||"-")}}</p>
                  </div>
                </td>
              </tr>
              <tr v-if="!(dataKajian.row.arankg_sign||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    
    <div class="card shadow-0 border">
      <div class="card-header">
        <h5 class="card-title font-weight-semibold">Diagnosa & Intervensi</h5>
      </div>
      <table class="table table-bordered table-sm">
        <tr class="table-info">
          <th width="33%"><span class="font-weight-semibold">Diagnosis Gizi</span></th>
          <th width="33%"><span class="font-weight-semibold">Intervensi</span></th>
          <th width="33%"><span class="font-weight-semibold">Tujuan Intervensi</span></th>
        </tr>
        <tr>
          <td>
            <table class="table table-bordered table-sm">
              <tr v-for="(v,k) in (dataKajian.row.arankg_diagnosa_gizi||[])" :key="k">
                <td>
                  <div class="result_tab">
                    <h4>Diagnosis #{{k+1}}</h4>
                    <p>{{v.id ? (v.name||"-") : (v.text||"-")}}</p>
                  </div>
                </td>
              </tr>
              <tr v-if="!(dataKajian.row.arankg_diagnosa_gizi||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </table>
          </td>
          <td>
            <table class="table table-bordered table-sm">
              <tr v-for="(v,k) in (dataKajian.row.arankg_intervensi||[])" :key="k">
                <td>
                  <div class="result_tab">
                    <h4>Intervensi #{{k+1}}</h4>
                    <p>{{v.id ? (v.name||"-") : (v.text||"-")}}</p>
                  </div>
                </td>
              </tr>
              <tr v-if="!(dataKajian.row.arankg_intervensi||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </table>
          </td>
          <td>
            <table class="table table-bordered table-sm">
              <tr v-for="(v,k) in (dataKajian.row.arankg_tujuan_intervensi||[])" :key="k">
                <td>
                  <div class="result_tab">
                    <h4>Tujuan Intervensi #{{k+1}}</h4>
                    <p>{{v.id ? (v.name||"-") : (v.text||"-")}}</p>
                  </div>
                </td>
              </tr>
              <tr v-if="!(dataKajian.row.arankg_tujuan_intervensi||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>

    <div class="card shadow-0 border">
      <table class="table table-bordered table-sm">
        <thead>
          <tr>
            <th colspan="3">Intervensi Makanan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="33%">
              <div class="result_tab">
                <h4>Cara Pemberian Lewat Mulut</h4>
                <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOpt,dataKajian.row.arankg_cara_pemberian_lewat_mulut)||"-"}}</p>
                <template v-if="dataKajian.row.arankg_cara_pemberian_lewat_mulut == 'Y'">
                  <small v-for="(v,k) in (dataKajian.row.arankg_cara_pemberian_lewat_mulut_text||[])" :key="k">{{v||"-"}}</small>
                </template>
              </div>
            </td>
            <td width="33%">
              <div class="result_tab">
                <h4>Cara Pemberian Lewat Pipa</h4>
                <p class="mb-0">{{getConfigDynamic(Config.mr.yesNoOpt,dataKajian.row.arankg_cara_pemberian_lewat_pipa)||"-"}}</p>
                <template v-if="dataKajian.row.arankg_cara_pemberian_lewat_pipa == 'Y'">
                  <small v-for="(v,k) in (dataKajian.row.arankg_cara_pemberian_lewat_pipa_text||[])" :key="k">{{v||"-"}}</small>
                </template>
              </div>
            </td>
            <td width="33%">
              <div class="result_tab">
                <h4></h4>
                <p></p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered table-sm">
        <thead>
          <tr>
            <th>Intervensi Makanan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div v-for="(v,k) in (dataKajian.row.arankg_implementasi||[])" :key="k" class="det-check-item"><i class="icon-checkmark-circle text-success"></i><span>{{v}}</span></div>
              <span v-if="!(dataKajian.row.arankg_implementasi||[]).length">Tidak ada Intervensi</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  // data(){
  //   return {
  //     keadaanUmum: false,
  //     activeAnat: 0,  
  //     openModalICDetail: false,
  //     rowIC: {}
  //   }
  // },

  computed: {
    dataKajian(){
      return this.$parent.$parent.kajianData.kajianGizi
    },
    dataPenunjang(){
      return this.$parent.$parent.kajianData.kajianPenunjang
    },
    Config(){ return Config },
  },
  
  methods: {
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    uploader(filename, def="/assets/images/user-placeholder.svg") {
      def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL +def : def
      if(!filename){
        return def
      }
      return this.apiUrl + '/uploader/' + filename
    },
  }
}
</script>