<template>
	<div>
		<div>

			<div class="row">
				<div class="col-md-6 mb-2">
					<label for="">Data Kajian</label>
					<v-select @input="changeKajianAwal($event)" placeholder="Pilih Data Kajian" v-model="row.arm_id"
						:options="historyKajianPerawat" label="text" :reduce="v=>v.value"></v-select>
				</div>
			</div>

			<div class="row" v-if="row.arm_notes">
				<div class="col-md-12">
					<b-alert show fade>{{row.arm_notes||"-"}}</b-alert>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<b-alert show fade>Nama Inputer : {{row.bu_full_name||"-"}}</b-alert>
				</div>
			</div>

			<div class="card">
				<div class="card-header bg_head_panel">
					<h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
				</div>
				<div class="card-body p-3 d-none d-lg-block">
					<table class="table table-bordered">
						<tbody>
							<tr>
								<td>
									<div class="result_tab">
										<h4>No. Rekam Medis</h4>
										<p>{{row.ap_code||"-"}}</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<h4>Nama Pasien</h4>
										<p>{{row.ap_fullname||"-"}}</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<h4>Tanggal Lahir</h4>
										<p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<h4>Jenis Kelamin</h4>
										<p>{{row.cg_label||"-"}}</p>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-body p-3 d-lg-none">
					<table class="table table-bordered">
						<tbody>
							<tr>
								<td>
									<div class="result_tab">
										<h4>No. Rekam Medis</h4>
										<p>{{row.ap_code||"-"}}</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<h4>Nama Pasien</h4>
										<p>{{row.ap_fullname||"-"}}</p>
									</div>
								</td>
							</tr>
							<tr>	
								<td>
									<div class="result_tab">
										<h4>Tanggal Lahir</h4>
										<p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<h4>Jenis Kelamin</h4>
										<p>{{row.cg_label||"-"}}</p>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card">
				<div class="card-header bg-info">
					<h5 class="card-title font-weight-semibold">Subjective</h5>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<div>

								<div class="form-row">
									<div class="col-md-12">
										<div class="table-responsive">
											<table class="table table-bordered">
												<tbody>
													<tr>
														<td>
															<div class="result_tab">
																<h4>Keluhan Utama</h4>
																<p>{{row.aps_keluhan||"-"}}</p>
															</div>
														</td>
														<td>
															<div class="result_tab">
																<h4>Riwayat Penyakit</h4>
																<p>{{row.aps_anamnesa||"-"}}</p>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>

								<div class="form-row mt-2">
									<div class="col-md-6">
										<div class="card mb-0">
											<div class="card-header bg_head_panel">
												<h6 class="card-title font-weight-semibold">Alergi</h6>
											</div>
											<div class="card-body p-3">
												<div class="form-row" v-if="row.aps_has_alergi == 'Y'">
													<div class="col-md-12">
														<div class="table-responsive">
															<table class="table table-bordered">
																<tbody>
																	<tr>
																		<th><strong>Jenis</strong></th>
																		<th><strong>Nama</strong></th>
																	</tr>
																	<tr v-for="(v,k) in (row.aps_alergi||[])" :key="k+'ob'">
																		<td>
																			<div class="result_tab">
																				{{v.jenis||"-"}}
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				{{v.name||"-"}}
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
												<div class="form-row" v-else>
													<div class="col-md-12">
														<span>Tidak Ada Alergi</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="card mb-0">
											<div class="card-header bg_head_panel">
												<h6 class="card-title font-weight-semibold">Obat Rutin</h6>
											</div>
											<div class="card-body p-3">
												<div class="form-row" v-if="row.aps_has_obat_rutin == 'Y'">
													<div class="col-md-12">
														<div class="table-responsive">
															<table class="table table-bordered">
																<tbody>
																	<tr>
																		<td>
																			<div class="result_tab">
																				<h4>Keterangan</h4>
																				<p>{{row.aps_keterangan_obat_rutin||"-"}}</p>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
												<div class="form-row" v-else>
													<div class="col-md-12">
														<span>Tidak Ada Alergi</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-header bg-info">
					<h5 class="card-title font-weight-semibold">Objective</h5>
				</div>
				<div class="card-body">
					<div class="row">

						<div class="col-md-12">
							<div>
								<div class="form-row mt-2">
									<div class="col-md-12">
										<div class="result_tab mb-2">
											<h4>Objective</h4>
											<p>{{row.apo_desc||"-"}}</p>
										</div>
										<div class="card mb-0">
											<div class="card-header bg_head_panel">
												<h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
											</div>
											<div class="card-body p-3">
												<div class="form-row">
													<div class="col-md-12">
														<div class="table-responsive">
															<table class="table table-bordered">
																<tbody>
																	<tr>
																		<td>
																			<div class="result_tab">
																				<h4>Tekanan Darah</h4>
																				<p v-if="row.apo_ttv_tekanan_darah_min || row.apo_ttv_tekanan_darah_max">
																					{{row.apo_ttv_tekanan_darah_min}} / {{row.apo_ttv_tekanan_darah_max}} mmHG</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				<h4>Nadi</h4>
																				<p v-if="row.apo_ttv_nadi">{{row.apo_ttv_nadi||"-"}}/mnt</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				<h4>Gula Darah</h4>
																				<p v-if="row.apo_ttv_gula_darah">{{row.apo_ttv_gula_darah||"-"}}mg/dL</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div class="result_tab">
																				<h4>Pernafasan</h4>
																				<p v-if="row.apo_ttv_pernafasan">{{row.apo_ttv_pernafasan||"-"}}x/mnt - Reguler
																				</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				<h4>SPO2</h4>
																				<p v-if="row.apo_ttv_spo2">{{row.apo_ttv_spo2||"-"}}%</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				<h4>Suhu</h4>
																				<p v-if="row.apo_ttv_suhu">{{row.apo_ttv_suhu||"-"}}C</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div class="result_tab">
																				<h4>Berat Badan</h4>
																				<p v-if="row.apo_ttv_weight">{{row.apo_ttv_weight||"-"}}kg</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				<h4>Tinggi</h4>
																				<p v-if="row.apo_ttv_height">{{row.apo_ttv_height||"-"}}Cm</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				<h4>Lingkar Kepala</h4>
																				<p v-if="row.apo_ttv_lingkar_kepala">{{row.apo_ttv_lingkar_kepala||"-"}}cm</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td v-if="row.ap_usia >= 2">
																			<div class="result_tab">
																				<h4>BMI</h4>
																				<p v-if="row.apo_ttv_bmi">{{row.apo_ttv_bmi}}m2</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td v-if="row.ap_usia <= 15">
																			<div class="result_tab">
																				<h4>Luas Permukaan Tubuh Anak</h4>
																				<p v-if="row.apo_ttv_luas_permukaan_anak">
																					{{row.apo_ttv_luas_permukaan_anak}}kg/m2</p>
																				<p v-else> - </p>
																			</div>
																		</td>
																		<td>
																			<div class="result_tab">
																				<h4>Kesadaran</h4>
																				<p>{{row.mk_name || "-"}}</p>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>


					<!--TBC here lanjutan-->
					<div class="row mt-2" v-if="row.apo_is_tbc == 'Y'">
						<div class="col-md-12">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Kajian TBC
									</h6>
								</div>
								<div class="card-body p-3">
									<div class="form-row">
										<div class="col-md-12">
											<div class="table-responsive">
												<table class="table table-bordered">
													<tbody>
														<tr>
															<td>
																<div class="result_tab">
																	<h4>Kondisi Umum</h4>
																	<p>{{row.apo_tb_kondisi_umum||"-"}}</p>
																</div>
															</td>
															<td colspan="3">
																<div class="result_tab">
																	<h4>Glasgow Coma Scale (GSC)</h4>
																	<p>E : {{getConfig('configStatusGSCE',row.apo_tb_gsc_e)||"-"}}</p>
																	<p>V : {{getConfig('configStatusGSCV',row.apo_tb_gsc_v)||"-"}}</p>
																	<p>M : {{getConfig('configStatusGSCM',row.apo_tb_gsc_m)||"-"}}</p>
																</div>
															</td>
														</tr>
														<tr>
															<th colspan="99"><strong>Kepala</strong></th>
														</tr>
														<tr>
															<td>
																<div class="result_tab">
																	<h4>Konjungtiva</h4>
																	<p>{{row.apo_tb_kep_konjungtiva||"-"}}</p>
																</div>
															</td>
															<td>
																<div class="result_tab">
																	<h4>Sianosis</h4>
																	<p>{{row.apo_tb_kep_sianosis == 'Y' ? "Ya" : "Tidak"}}</p>
																</div>
															</td>
															<td>
																<div class="result_tab">
																	<h4>Sklera</h4>
																	<p>{{row.apo_tb_kep_sklera||"-"}}</p>
																</div>
															</td>
															<td>
																<div class="result_tab">
																	<h4>Hidung</h4>
																	<p>{{row.apo_tb_kep_hidung||"-"}}</p>
																</div>
															</td>
														</tr>
														<tr>
															<th colspan="99"><strong>Thorax / Dada</strong></th>
														</tr>
														<tr>
															<td>
																<div class="result_tab">
																	<h4>Paru-Paru</h4>
																	<p>
																		{{row.apo_tb_trx_paru == "Lain-lain" ? row.apo_tb_trx_paru_lainnya : row.apo_tb_trx_paru}}
																	</p>
																</div>
															</td>
															<td>
																<div class="result_tab">
																	<h4>Respirasi</h4>
																	<p>{{row.apo_tb_trx_respirasi||"-"}}</p>
																</div>
															</td>
															<td>
																<div class="result_tab">
																	<h4>Alat bantu nafas</h4>
																	<p>{{row.apo_tb_trx_alat_bantu_nafas == "Y" ? "Ya" : "Tidak"}}</p>
																</div>
															</td>
															<td>
																<div class="result_tab">
																	<h4>Jantung</h4>
																	<p>
																		{{row.apo_tb_trx_jantung == "Lain-lain" ? row.apo_tb_trx_jantung_lainnya : row.apo_tb_trx_jantung}}
																	</p>
																</div>
															</td>
														</tr>
														<tr>
															<th colspan="99"><strong>Abdomen</strong></th>
														</tr>
														<tr>
															<td>
																<div class="result_tab">
																	<h4>Nyeri</h4>
																	<p>{{row.apo_tb_abd_nyeri == "Y" ? "Ya": "Tidak"}}</p>
																</div>
															</td>
															<td>
																<div class="result_tab">
																	<h4>Bising Usus</h4>
																	<p>{{row.apo_tb_abd_bising_usus == 'Y' ? "Ya" : "Tidak"}}</p>
																</div>
															</td>
															<td colspan="2">
																<div class="result_tab">
																	<h4>Kodisi</h4>
																	<p>
																		{{row.apo_tb_abd_kondisi == "Lain-lain" ? row.apo_tb_abd_kondisi_lainnya : row.apo_tb_abd_kondisi}}
																	</p>
																</div>
															</td>
														</tr>
														<tr>
															<th colspan="99"><strong>Ekstremitas</strong></th>
														</tr>
														<tr>
															<td colspan="2">
																<div class="result_tab">
																	<h4>Ederma</h4>
																	<p>{{row.apo_tb_eks_edema == "Y" ? "Ya" : "Tidak"}}</p>
																</div>
															</td>
															<td colspan="2">
																<div class="result_tab">
																	<h4>Akaral</h4>
																	<p>{{row.apo_tb_eks_akaral||"-"}}</p>
																</div>
															</td>
														</tr>

														<tr>
															<th colspan="99"><strong>Hasil</strong></th>
														</tr>
														<tr>
															<td colspan="2">
																<div class="result_tab">
																	<h4>Hasil Pemeriksaan TCM</h4>
																	<p>{{row.apo_hasil_tcm||"-"}}</p>
																</div>
															</td>
															<td colspan="2">
																<div class="result_tab">
																	<h4>Hasil Pemeriksaan BTA</h4>
																	<p>{{row.apo_hasil_bta||"-"}}</p>
																</div>
															</td>
														</tr>
														<tr>
															<td colspan="2">
																<div class="result_tab">
																	<h4>Hasil Pemeriksaan Rontgen</h4>
																	<p>{{row.apo_hasil_rontgen||"-"}}</p>
																</div>
															</td>
															<td colspan="2">
																<div class="result_tab">
																	<h4>Hasil Pemeriksaan Test</h4>
																	<p>{{row.apo_hasil_test||"-"}}</p>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--TBC here lanjutan-->

				</div>
			</div>

			<div class="card">
				<div class="card-header bg-info">
					<h5 class="card-title font-weight-semibold">Assessment</h5>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<ul class="nav nav-tabs nav-tabs-bottom">
								<li class="nav-item" v-for="(v,k) in diagnosa" :key="k">
									<a href="javascript:;" @click="changeTabSDKI(k+1)" data-toggle="tab" :data-target="'#diagTab'+(k+1)"
										:class="activeTabSDKI == (k+1) ? 'nav-link active' : 'nav-link'">{{v.msd_code + " - " +v.msd_name||"Diagnosa baru"}}
									</a>
								</li>
							</ul>
							<div class="tab-content card-body p-0">
								<div v-for="(v,k) in diagnosa" :key="k+'a'"
									:class="activeTabSDKI == (k+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#diagTab'+(k+1)">
									<div class="row">
										<div class="col-md-12">
											<div class="table-responsive">
												<table class="table table-bordered">
													<tbody>
														<tr>
															<td>
																<div class="result_tab">
																	<h4>Diagnosa</h4>
																	<p>{{v.msd_code ? v.msd_code + ' - ' + v.msd_name : '-'}}</p>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="form-row mt-2">
										<div class="col-md-6">
											<div class="card mb-0">
												<div class="card-header bg_head_panel">
													<h6 class="card-title font-weight-semibold">Penyebab</h6>
												</div>
												<div class="card-body p-3">
													<div class="form-row">
														<div class="col-md-12">
															<template v-for="(v,k) in (diagnosa[k].apa_penyebab||[])">
																<div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
																	<i class="icon-checkmark-circle text-success"></i>
																	<span class="ml-1">{{v}}</span>
																</div>
															</template>
															<p v-if="!(diagnosa[k].apa_penyebab||[]).length">Tidak Ada</p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-6">
											<div class="card mb-0">
												<div class="card-header bg_head_panel">
													<h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
												</div>
												<div class="card-body p-3">
													<div class="form-row">
														<div class="col-md-6">
															<template v-for="(v,k) in (diagnosa[k].apa_faktor_resiko||[])">
																<div :key="k+'faktor-res'" class="d-flex align-items-center  mb-2">
																	<i class="icon-checkmark-circle text-success"></i>
																	<span class="ml-1">{{v}}</span>
																</div>
															</template>
															<p v-if="!(diagnosa[k].apa_faktor_resiko||[]).length">Tidak Ada</p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-6">
											<div class="card mb-0">
												<div class="card-header bg_head_panel">
													<h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
												</div>
												<div class="card-body p-3">
													<div class="form-row">
														<div class="col-md-6">
															<h6 class="font-weight-semibold">Subjective</h6>
															<template v-for="(v,k) in (diagnosa[k].apa_gejala_mayor_subjektif||[])">
																<div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
																	<i class="icon-checkmark-circle text-success"></i>
																	<span class="ml-1">{{v}}</span>
																</div>
															</template>
															<p v-if="!(diagnosa[k].apa_gejala_mayor_subjektif||[]).length">Tidak Ada</p>
														</div>

														<div class="col-md-6">
															<h6 class="font-weight-semibold">Objective</h6>
															<template v-for="(v,k) in (diagnosa[k].apa_gejala_mayor_objektif||[])">
																<div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
																	<i class="icon-checkmark-circle text-success"></i>
																	<span class="ml-1">{{v}}</span>
																</div>
															</template>
															<p v-if="!(diagnosa[k].apa_gejala_mayor_objektif||[]).length">Tidak Ada</p>

														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-6">
											<div class="card mb-0">
												<div class="card-header bg_head_panel">
													<h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
												</div>
												<div class="card-body p-3">
													<div class="form-row">
														<div class="col-md-6">
															<h6 class="font-weight-semibold">Subjective</h6>
															<template v-for="(v,k) in (diagnosa[k].apa_gejala_minor_subjektif||[])">
																<div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
																	<i class="icon-checkmark-circle text-success"></i>
																	<span class="ml-1">{{v}}</span>
																</div>
															</template>
															<p v-if="!(diagnosa[k].apa_gejala_minor_subjektif||[]).length">Tidak Ada</p>
														</div>

														<div class="col-md-6">
															<h6 class="font-weight-semibold">Objective</h6>
															<template v-for="(v,k) in (diagnosa[k].apa_gejala_minor_objektif||[])">
																<div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
																	<i class="icon-checkmark-circle text-success"></i>
																	<span class="ml-1">{{v}}</span>
																</div>
															</template>
															<p v-if="!(diagnosa[k].apa_gejala_minor_objektif||[]).length">Tidak Ada</p>
														</div>
													</div>
												</div>
											</div>
										</div>

									</div>
									<div class="card mt-3">
										<div class="card-header bg-info">
											<h5 class="card-title font-weight-semibold">Planning</h5>
										</div>
										<div class="card-body">
											<div class="row">
												<div class="col-md-12">
													<div class="tab-content card-body p-0">
														<div class="tab-pane fade show active" id="d1101">
															<div class="form-row mt-2">
																<div class="col-md-12">
																	<div class="card mb-0">
																		<div class="card-header bg_head_panel">
																			<h6 class="card-title font-weight-semibold">Intervensi Keperawatan</h6>
																		</div>
																		<div class="card-body p-3">
																			<div class="form-row">
																				<div class="col-md-12">
																					<ul class="nav nav-tabs nav-tabs-bottom">
																						<li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
																							<a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab"
																								:data-target="'#intvTab'+(k2+1)"
																								:class="v.apa_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
																								{{ v2.msi_code + " - " +v2.msi_name || "Intervensi Baru"}}</a>
																						</li>
																					</ul>

																					<div class="tab-content card-body p-0">
																						<div v-for="(v2,k2) in v.siki" :key="k2+'sik'"
																							:class="v.apa_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'"
																							:id="'#intvTab1'+(k2+1)">
																							<div class="row">
																								<div class="col-md-12">
																									<div class="table-responsive">
																										<table class="table table-bordered">
																											<tbody>
																												<tr>
																													<td>
																														<div class="result_tab">
																															<h4> Intervensi</h4>
																															<p>
																																{{v2.msi_code ? v2.msi_code + ' - ' + v2.msi_name : '-'}}
																															</p>
																														</div>
																													</td>
																												</tr>
																											</tbody>
																										</table>
																									</div>
																								</div>
																								<div class="col-md-12">
																									<table
																										class="table table-bordered table-striped table-hover table-sm patient-table">
																										<thead>
																											<tr>
																												<th class="table-primary" colspan="99">Tindakan</th>
																											</tr>
																										</thead>
																										<tbody>
																											<tr>
																												<th colspan="2" class="table-light bg_head_panel">OBSERVASI</th>
																											</tr>
																											<template
																												v-for="(vo,ko) in diagnosa[k]['siki'][k2]['app_tindakan_observasi']">
																												<tr :key="ko+'ko'" v-if="vo.value">
																													<td colspan="2">{{vo.name}}</td>
																												</tr>
																											</template>
																											<tr
																												v-if="!countTindakan(diagnosa[k]['siki'][k2]['app_tindakan_observasi'])">
																												<td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
																											</tr>
																										</tbody>
																										<tbody>
																											<tr>
																												<th colspan="2" class="table-light bg_head_panel">TERAPEUTIK
																												</th>
																											</tr>
																											<template
																												v-for="(vt,kt) in diagnosa[k]['siki'][k2]['app_tindakan_terapeutik']">
																												<tr :key="kt+'kt'" v-if="vt.value">
																													<td colspan="2">{{vt.name}}</td>
																												</tr>
																											</template>
																											<tr
																												v-if="!countTindakan(diagnosa[k]['siki'][k2]['app_tindakan_terapeutik'])">
																												<td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
																											</tr>
																										</tbody>

																										<tbody>
																											<tr>
																												<th colspan="2" class="table-light bg_head_panel">EDUKASI</th>
																											</tr>
																											<template
																												v-for="(ve,ke) in diagnosa[k]['siki'][k2]['app_tindakan_edukasi']">
																												<tr :key="ke+'ke'" v-if="ve.value">
																													<td colspan="2">{{ve.name}}</td>
																												</tr>
																											</template>
																											<tr
																												v-if="!countTindakan(diagnosa[k]['siki'][k2]['app_tindakan_edukasi'])">
																												<td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
																											</tr>

																										</tbody>

																										<tbody>
																											<tr>
																												<th colspan="2" class="table-light bg_head_panel">KOLABORASI
																												</th>
																											</tr>
																											<template
																												v-for="(vk,kk) in diagnosa[k]['siki'][k2]['app_tindakan_kolaborasi']">
																												<tr :key="kk+'kk'" v-if="vk.value">
																													<td colspan="2">{{vk.name}}</td>
																												</tr>
																											</template>
																											<tr
																												v-if="!countTindakan(diagnosa[k]['siki'][k2]['app_tindakan_kolaborasi'])">
																												<td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
																											</tr>
																										</tbody>
																									</table>
																								</div>

																								<div class="col-md-12">
																									<div class="table-responsive">
																										<table class="table table-bordered">
																											<tbody>
																												<tr>
																													<td>
																														<div class="result_tab">
																															<h4> Nama Perawat</h4>
																															<p>{{v2.app_nama_perawat||"-"}}</p>
																														</div>
																													</td>
																													<td>
																														<div class="result_tab">
																															<h4> Waktu Tindakan</h4>
																															<p>{{v2.app_tindakan_pada||"-"}}</p>
																														</div>
																													</td>
																												</tr>
																												<tr>
																													<td colspan="2">
																														<div class="result_tab">
																															<h4> Tindakan Keperawatan</h4>
																															<p>{{v2.app_info_tindakan||"-"}}</p>
																														</div>
																													</td>
																												</tr>
																											</tbody>
																										</table>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>

																			</div>
																		</div>
																	</div>

																</div>



															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-header bg-info">
					<h5 class="card-title font-weight-semibold">Pengkajian Resiko Jatuh</h5>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<div class="table-responsive">
								<table class="table table-bordered">
									<tbody>

										<tr>
											<td width="50%">
												<div class="result_tab">
													<h4>Cara Berjalan pasien (salah satu atau lebih)</h4>
													<p v-for="(v,k) in (row.akp_pengkajian_A||[])" :key="k+'pA'">{{v||"-"}}</p>
													<p v-if="!(row.akp_pengkajian_A||[]).length">(Tidak Ada)</p>
												</div>
											</td>
											<td width="50%">
												<div class="result_tab">
													<p v-for="(v,k) in (row.akp_pengkajian_B||[])" :key="k+'pB'">{{v||"-"}}</p>
													<p v-if="!(row.akp_pengkajian_B||[]).length">(Tidak Ada)</p>
												</div>
											</td>

										</tr>
									</tbody>
								</table>
							</div>
							<div>
							</div>
						</div>
						<div class="col-md-12 mt-2">
							<table class="table table-bordered table-striped table-hover table-sm patient-table">
								<thead>
									<tr>
										<th width="50%">HASIL</th>
										<th width="50%">TINDAKAN</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(v,k) in (row.akp_tindakan||[])" :key="k+'tindakan'">
										<td v-if="k==0" :rowspan="(row.akp_tindakan||[]).length">{{row.akp_hasil||"-"}}</td>
										<td>{{v||"-"}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div class="card">
				<div class="card-header bg_head_panel">
					<h6 class="card-title font-weight-semibold">Resep Alkes</h6>
				</div>
				<div class="card-body p-3">
					<table class="table table-bordered table-sm">
						<thead>
							<tr>
								<th>Jenis</th>
								<th>Nama</th>
								<th>Jumlah</th>
								<th>Frekuensi</th>
								<th>Keterangan</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(v,k) in (resepDokter||[])" :key="'resepDokter'+k">
								<td>
									<div class="result_tab">
										<p>{{getConfigDynamic(Config.mr.configResep,v.ard_jenis)||"-"}}
										</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<p v-if="v.ard_item_id == 99999">
											{{v.ard_item_lainnya||"-"}}
										</p>
										<p v-else>
											{{v.ard_nama||"-"}}
										</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<p>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<p>
											<span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
											<span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
										</p>
									</div>
								</td>
								<td>
									<div class="result_tab">
										<p>{{v.ard_keterangan || "-"}}</p>
									</div>
								</td>
							</tr>
							<tr v-if="!(resepDokter||[]).length" class="table-info">
								<td class="text-center" colspan="99">Tidak Ada Data</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
// import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
    // extends: GlobalVue,
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        diagnosa: Array,
        mSDKI: Array,
        mSIKI: Array,
        mKesadaran: Array,
        resepDokter: Array,
        historyKajianPerawat: Array
    },
    data(){
        return {
            activeTabSDKI: 1,
        }
    },
    methods: {
        changeKajianAwal(e){
            if(this.row['isDok']){
                this.$parent.$parent.$parent.viewKajianPerawat(e)
            }else{
                this.$parent.$parent.$parent.toDetail({kajianPerawatId : e},this.row.arm_ar_id)
            }
        },
        toList(){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{}) 
        },
        toDetail(){
            let v = this.row
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian:v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
        },
        otherConditional(){
            let v = this.row
            return v.arm_created_by == this.$parent.$parent.$parent.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date)
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },
        countTindakan(data){
            let total = 0
            for(let i = 0; i < (data||[]).length; i++){
                if(data[i]['value']){
                    total += 1
                }
            }
            return total
        },
        getConfig(mrName,value){
            let text = ''
            if(value){
                let index = this.Config.mr[mrName].findIndex(x => x.value == value)
                if(index !== -1){
                    text = this.Config.mr[mrName][index]['text']
                }
            }
            return text
        },
        getMaster(mrName,value){
            let text = ''
            if(value){
                let index = this[mrName].findIndex(x => x.value == value)
                if(index !== -1){
                    text = this[mrName][index]['text']
                }
            }
            return text
        },
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        
        changeTabSDKI(k){
            this.activeTabSDKI = k
        },
        
        changeTabSIKI(k2,k){
            this.diagnosa[k].apa_last_tab_siki = k2
        }
    },
    
}
</script>
