<template>
  <div class="mt-2">
    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Identitas</h6>
      </div>
      <div class="card-body p-3">
        <div class="mb-3">
          <table class="table table-bordered">
            <tr>
              <td width="20%">
                <div class="result_tab">
                  <h4>Nama Bayi</h4>
                  <p>{{dataKajian.row.ap_fullname||"-"}}</p>
                </div>
              </td>
              <td width="20%">
                <div class="result_tab">
                  <h4>Tanggal Lahir</h4>
                  <p>
                    <span v-if="dataKajian.row.ap_dob">{{dataKajian.row.ap_dob|moment('DD MMMM YYYY')}}</span>
                    <span v-else> - </span>
                  </p>
                </div>
              </td>
              <td width="20%">
                <div class="result_tab">
                  <h4>Jenis Kelamin</h4>
                  <p>{{getConfigDynamic(Config.mr.StatusKelamin,dataKajian.row.ap_gender)||"-"}}</p>
                </div>
              </td>
              <td width="20%">
                <div class="result_tab">
                  <h4>Nama Ayah</h4>
                  <p>{{dataKajian.row.arpdo_ayah_nama||"-"}}</p>
                </div>
              </td>
              <td width="20%">
                <div class="result_tab">
                  <h4>Anak Ke-</h4>
                  <p>{{dataKajian.row.arankaabl_anak_ke||"-"}}</p>
                </div>
              </td>
            </tr>
          </table>
          <div class="row">
            <div class="col-md-6">
              <table class="table table-sm table-bordered mt-3">
              <thead>
                  <tr>
                  <th class="bg-success" colspan="2">Biodata Ibu</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                  <td>Nama Ibu</td>
                  <td>{{dataKajian.row.arpdo_ibu_nama||"-"}}</td>
                  </tr>
                  <tr>
                  <td>Tanggal Lahir</td>
                  <td>
                      <span v-if="dataKajian.row.arpdo_ibu_dob">
                      {{dataKajian.row.arpdo_ibu_dob | moment("DD MMMM YYYY")}}
                      </span>
                      <span v-else> - </span>
                  </td>
                  </tr>
                  <tr>
                  <td>Umur</td>
                  <td>
                      <span v-if="dataKajian.row.arpdo_ibu_dob">
                      {{ dataKajian.row.arpdo_ibu_dob | moment("from", "now", true) }}
                      </span>
                      <span v-else> - </span>
                  </td>
                  
                  </tr>
                  <tr>
                  <td>Suku/bangsa </td>
                  <td>{{dataKajian.row.ibu_suku||"-"}}</td>
                  </tr>
                  <tr>
                  <td>Agama </td>
                  <td>{{dataKajian.row.ibu_agama||"-"}}
                  <span v-if="dataKajian.row.ibu_agama == 'Lain-lain'">: {{dataKajian.row.arpdo_ibu_agama_text||"-"}}</span>
                  </td>
                  </tr>
                  <tr>
                  <td>Pendidikan </td>
                  <td>{{dataKajian.row.ibu_pendidikan||"-"}}
                  <span v-if="dataKajian.row.ibu_pendidikan == 'Lain-lain'">: {{dataKajian.row.arpdo_ibu_pendidikan_text||"-"}}</span>
                  </td>
                  
                  </tr>
                  <tr>
                  <td>Pekerjaan</td>
                  <td>{{dataKajian.row.ibu_pekerjaan||"-"}}
                  <span v-if="dataKajian.row.ibu_pekerjaan == 'Lain-lain'">: {{dataKajian.row.arpdo_ibu_pekerjaan_text||"-"}}</span>
                  </td>
                  </tr>
                  <tr>
                  <td>Alamat </td>
                  <td>{{dataKajian.row.arpdo_ibu_alamat_ktp||"-"}}</td>
                  </tr>
                  <tr>
                  <td>Status Perkawinan </td>
                  <td>{{getConfigDynamic(Config.mr.StatusPernikahan,dataKajian.row.arpdo_ibu_status_perkawinan)||"-"}}</td>
                  </tr>
              </tbody>
              </table>
            </div>
            <div class="col-md-6">
              <table class="table table-sm table-bordered mt-3">
              <thead>
                  <tr>
                  <th class="bg-primary" colspan="2">Biodata Ayah</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                  <td>Nama Ayah</td>
                  <td>{{dataKajian.row.arpdo_ayah_nama||"-"}}</td>
                  </tr>
                  <tr>
                  <td>Tanggal Lahir</td>
                  <td>
                      <span v-if="dataKajian.row.arpdo_ayah_dob">
                      {{dataKajian.row.arpdo_ayah_dob | moment("DD MMMM YYYY")}}
                      </span>
                      <span v-else> - </span>
                  </td>
                  </tr>
                  <tr>
                  <td>Umur</td>
                  <td>
                      <span v-if="dataKajian.row.arpdo_ayah_dob">
                      {{ dataKajian.row.arpdo_ayah_dob | moment("from", "now", true) }}
                      </span>
                      <span v-else> - </span>
                  </td>
                  
                  </tr>
                  <tr>
                  <td>Suku/bangsa </td>
                  <td>{{dataKajian.row.ayah_suku||"-"}}</td>
                  </tr>
                  <tr>
                  <td>Agama </td>
                  <td>{{dataKajian.row.ayah_agama||"-"}}
                  <span v-if="dataKajian.row.ayah_agama == 'Lain-lain'">: {{dataKajian.row.arpdo_ayah_agama_text||"-"}}</span>
                  </td>
                  </tr>
                  <tr>
                  <td>Pendidikan </td>
                  <td>{{dataKajian.row.ayah_pendidikan||"-"}}
                  <span v-if="dataKajian.row.ayah_pendidikan == 'Lain-lain'">: {{dataKajian.row.arpdo_ayah_pendidikan_text||"-"}}</span>
                  </td>
                  </tr>
                  <tr>
                  <td>Pekerjaan</td>
                  <td>{{dataKajian.row.ayah_pekerjaan||"-"}}
                  <span v-if="dataKajian.row.ayah_pekerjaan == 'Lain-lain'">: {{dataKajian.row.arpdo_ayah_pekerjaan_text||"-"}}</span>
                  </td>
                  </tr>
                  <tr>
                  <td>Alamat </td>
                  <td>{{dataKajian.row.arpdo_ayah_alamat_ktp||"-"}}</td>
                  </tr>
                  <tr>
                  <td>Status Perkawinan </td>
                  <td>{{getConfigDynamic(Config.mr.StatusPernikahan,dataKajian.row.arpdo_ayah_status_perkawinan)||"-"}}</td>
                  </tr>
              </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Cap Ibu dan Bayi</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm">
              <tr>
                <td width="28%">
                  <div class="result_tab">
                    <h4>Cap Ibu Jari Tangan Ibu Bayi</h4>
                    <div v-if="(dataKajian.row.arankaabl_cap_tangan_bayi)">
                      <a :href="uploader(dataKajian.row.arankaabl_cap_tangan_bayi)" target="_blank" class="btn btn-sm btn-labeled-left btn-success pl-3">
                        <b><i class="icon-download"></i></b>
                        <span>Unduh File</span>
                      </a>
                    </div>
                    <div v-else> - </div>
                  </div>
                </td>
                <td width="28%">
                  <div class="result_tab">
                    <h4>Cap Kaki Kiri dan Kanan Bayi</h4>
                    <div v-if="(dataKajian.row.arankaabl_cap_kaki_bayi)">
                      <a :href="uploader(dataKajian.row.arankaabl_cap_kaki_bayi)" target="_blank" class="btn btn-sm btn-labeled-left btn-success pl-3">
                        <b><i class="icon-download"></i></b>
                        <span>Unduh File</span>
                      </a>
                    </div>
                    <div v-else> - </div>
                  </div>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </div>

    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Riwayat Kesehatan</h6>
      </div>
      <div class="card-body">
        <div class="mt-3">
          <table class="table table-bordered table-sm">
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Riwayat Ibu</h4>
                  <p v-if="dataKajian.row.arankaabl_is_riwayat_penyakit == 'Y'">{{dataKajian.row.arankaabl_is_riwayat_penyakit_name||"-"}}</p>
                  <p v-else> - </p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Riwayat Keluarga</h4>
                  <p v-if="dataKajian.row.arankaabl_is_riwayat_penyakit_keluarga == 'Y'">{{dataKajian.row.arankaabl_is_riwayat_penyakit_keluarga_name||"-"}}</p>
                  <p v-else> - </p>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="mb-3">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr class="table-info">
                <th colspan="3"><span class="font-weight-semibold">Riwayat Intranatal</span></th>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Persalinan</h4>
                    <p v-if="dataKajian.row.arankaabl_jenis_persalinan == 'Lain-lain'">
                      {{dataKajian.row.arankaabl_jenis_persalinan_lainnya}}
                    </p>
                    <p v-else>{{dataKajian.row.arankaabl_jenis_persalinan||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Penolong</h4>
                    
                    <div v-for="(v,k) in (dataKajian.row.arankaabl_penolong||[])" :key="k" class="det-check-item">
                      <i class="icon-checkmark-circle text-success"></i>                      
                      <span v-if="v=='Lain-lain'">{{v||"-"}}</span>
                      <span v-else>{{v||"-"}}</span>
                    </div>
                    <span v-if="!(dataKajian.row.arankaabl_penolong||[]).length">Tidak ada Data</span>
                    
                    <!--
                    <p v-if="dataKajian.row.arankaabl_penolong == 'Lain-lain'">
                      {{dataKajian.row.arankaabl_penolong_lainnya}}
                    </p>
                    <p v-else>{{dataKajian.row.arankaabl_penolong||"-"}}</p>
                    -->
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="table-info">
                <th colspan="3"><span class="font-weight-semibold">Lama Persalinan</span></th>
              </tr>
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Kala I</h4>
                    <p>{{dataKajian.row.arankaabl_kala_1||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Kala II</h4>
                    <p>{{dataKajian.row.arankaabl_kala_2||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Komplikasi Persalinan</h4>
                    <p>{{dataKajian.row.arankaabl_komplikasi_persalinan||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Ketuban</h4>
                    <p>{{dataKajian.row.arankaabl_ketuban||"-"}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="wrap_line">
          <h3>Kondisi Bayi</h3>
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Status Praesens</h6>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered table-sm">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Keadaan Umum</h4>
                      <p>{{dataKajian.row.arankaabl_keadaan_umum||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jenis Kelamin</h4>
                      <p>{{getConfigDynamic(Config.mr.genderText,dataKajian.row.arankaabl_jenis_kelamin)||"-"}}</p>

                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Berat Badan</h4>
                      <p v-if="dataKajian.row.arankaabl_bbl">{{dataKajian.row.arankaabl_bbl}} gram</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Panjang</h4>
                      <p v-if="dataKajian.row.arankaabl_panjang">{{dataKajian.row.arankaabl_panjang}} cm</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Lingkar Kepala</h4>
                      <p v-if="dataKajian.row.arankaabl_lingkar_kepala">{{dataKajian.row.arankaabl_lingkar_kepala}} cm</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Lingkar Dada</h4>
                      <p v-if="dataKajian.row.arankaabl_lingkar_dada">{{dataKajian.row.arankaabl_lingkar_dada}} cm</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Nadi</h4>
                      <p v-if="dataKajian.row.arankaabl_nadi">{{dataKajian.row.arankaabl_nadi}} x/mnt</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>RR</h4>
                      <p v-if="dataKajian.row.arankaabl_rr">{{dataKajian.row.arankaabl_rr}} x/mnt</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Suhu Badan</h4>
                      <p v-if="dataKajian.row.arankaabl_suhu">{{dataKajian.row.arankaabl_suhu}} &deg;C</p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">APGAR</h6>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Kriteria</th>
                        <th>1 Menit</th>
                        <th>5 Menit</th>
                        <th>10 Menit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="table-warning">Denyut Jantung</th>
                        <td>{{dataKajian.row.arankaabl_denyut_jantung_1||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_denyut_jantung_5||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_denyut_jantung_10||"-"}}</td>
                      </tr>
                      <tr>
                        <th class="table-warning">Nafas</th>
                        <td>{{dataKajian.row.arankaabl_nafas_1||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_nafas_5||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_nafas_10||"-"}}</td>
                      </tr>
                      <tr>
                        <th class="table-warning">Tonus Otot</th>
                        <td>{{dataKajian.row.arankaabl_tonus_otot_1||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_tonus_otot_5||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_tonus_otot_10||"-"}}</td>
                      </tr>
                      <tr>
                        <th class="table-warning">Reflek</th>
                        <td>{{dataKajian.row.arankaabl_reflek_1||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_reflek_5||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_reflek_10||"-"}}</td>
                      </tr>
                      <tr>
                        <th class="table-warning">Warna Kulit</th>
                        <td>{{dataKajian.row.arankaabl_wk_1||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_wk_5||"-"}}</td>
                        <td>{{dataKajian.row.arankaabl_wk_10||"-"}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="table-info">
                        <td class="text-info-700">
                          <span class="text-uppercase">TOTAl</span>
                        </td>
                        <td class="text-info-700">
                          <span class="text-uppercase">{{dataKajian.row.arankaabl_total_1_menit||0}}</span>
                        </td>
                        <td class="text-info-700">
                          <span class="text-uppercase">{{dataKajian.row.arankaabl_total_5_menit||0}}</span>
                        </td>
                        <td class="text-info-700">
                          <span class="text-uppercase">{{dataKajian.row.arankaabl_total_10_menit||0}}</span>
                        </td>
                        <!-- Penilaian APGAR
                        7 - 11 = Risiko Rendah
                        >= 12 = Risiko Tinggi
                        -->
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap_line">
          <h3>Pemeriksaan Khusus</h3>
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Inspeksi</h6>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Kepala</h4>
                        <p>{{getConfigDynamic(Config.mr.normalTidakNormalOptTP,dataKajian.row.arankaabl_inpeksi_kepala)||"-"}} <span>: {{dataKajian.row.arankaabl_inpeksi_kepala_text||"-"}}</span> </p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Wajah</h4>
                        <p>{{getConfigDynamic(Config.mr.normalTidakNormalOptTP,dataKajian.row.arankaabl_wajah)||"-"}} <span>: {{dataKajian.row.arankaabl_wajah_text||"-"}}</span> </p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Leher</h4>
                        <p>{{getConfigDynamic(Config.mr.normalTidakNormalOptTP,dataKajian.row.arankaabl_leher)||"-"}} <span>: {{dataKajian.row.arankaabl_leher_text||"-"}}</span> </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Dada</h4>
                        <p>{{getConfigDynamic(Config.mr.normalTidakNormalOptTP,dataKajian.row.arankaabl_dada)||"-"}} <span>: {{dataKajian.row.arankaabl_dada_text||"-"}}</span> </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Abdomen</h4>
                        <p>{{getConfigDynamic(Config.mr.normalTidakNormalOptTP,dataKajian.row.arankaabl_abdomen)||"-"}} <span>: {{dataKajian.row.arankaabl_abdomen_text||"-"}}</span> </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Ekstremitas</h4>
                        <table class="table table-bordered table-sm">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Atas</h4>
                                <p>{{getConfigDynamic(Config.mr.normalTidakNormalOptTP,dataKajian.row.arankaabl_ekstremitas_atas)||"-"}} <span>: {{dataKajian.row.arankaabl_ekstremitas_atas_text||"-"}}</span> </p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Bawah</h4>
                                <p>{{getConfigDynamic(Config.mr.normalTidakNormalOptTP,dataKajian.row.arankaabl_ekstremitas_bawah)||"-"}} <span>: {{dataKajian.row.arankaabl_ekstremitas_bawah_text||"-"}}</span> </p>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Anus</h4>
                        <p>{{getConfigDynamic(Config.mr.mAnusLubang,dataKajian.row.arankaabl_anus)||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Elimnasi</h4>
                        <table class="table table-bordered table-sm">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Miksi</h4>
                                <p>{{getConfigDynamic(Config.mr.mSudahBelum,dataKajian.row.arankaabl_el_miksi)||"-"}}</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Meconium</h4>
                                <p>{{getConfigDynamic(Config.mr.mSudahBelum,dataKajian.row.arankaabl_el_meconium)||"-"}}</p>
                                <p>{{dataKajian.row.arankaabl_el_meconium_text||"-"}}</p>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Terapi</h4>
                        <p v-for="(v,k) in (dataKajian.row.arankaabl_terapi||[])" :key="k">{{v||"-"}}</p>
                        <p v-if="dataKajian.row.arankaabl_terapi_date">{{dataKajian.row.arankaabl_terapi_date | moment("DD MMMM YYYY") }}</p>
                        <span v-else> - </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>IMD (Inisiasi Menyusui Dini)</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankaabl_imd)||"-"}}</p>
                        <p>{{dataKajian.row.arankaabl_imd_text}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Pengkajian Risiko Jatuh Neonatus</h6>
          </div>
          <div class="card-body p-3">
            <table class="table table-bordered table-sm">
              <tr>
                <td width="50%">
                  <div class="result_tab">
                    <h4>Intervensi</h4>
                    <div class="mt-1">
                      <div v-for="(v,k) in (dataKajian.row.arankaabl_neo_intervensi||[])" :key="k" class="det-check-item"><i
                          class="icon-checkmark-circle text-success"></i><span>{{v||"-"}}</span></div>
                      <span v-if="!(dataKajian.row.arankaabl_neo_intervensi||[]).length">Tidak ada Data</span>
                    </div>
                  </div>
                </td>
                <td width="50%">
                  <div class="result_tab">
                    <h4>Edukasi yang Diberikan</h4>
                    <div class="mt-1">
                      <div v-for="(v,k) in (dataKajian.row.arankaabl_neo_edukasi||[])" :key="k" class="det-check-item"><i
                          class="icon-checkmark-circle text-success"></i><span>{{v||"-"}}</span></div>
                      <span v-if="!(dataKajian.row.arankaabl_neo_edukasi||[]).length">Tidak ada Data</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <div class="result_tab">
                    <h4>Sasaran Edukasi</h4>
                    <div class="mt-1">
                      <div v-for="(v,k) in (dataKajian.row.arankaabl_neo_sasaran||[])" :key="k" class="det-check-item"><i
                          class="icon-checkmark-circle text-success"></i><span>{{v||"-"}}</span></div>

                      <div v-if="(dataKajian.row.arankaabl_neo_sasaran||[]).indexOf('Lainnya') != -1" class="det-check-item"><i
                          class="icon-checkmark-circle text-success"></i><span>{{dataKajian.row.arankaabl_neo_sasaran_lainnya||"-"}}</span></div>

                      <span v-if="!(dataKajian.row.arankaabl_neo_sasaran||[]).length">Tidak ada Data</span>
                    </div>
                  </div>
                </td>
                <td width="50%">
                  <div class="result_tab">
                    <h4>Evaluasi</h4>
                    <div class="mt-1">
                      <div v-for="(v,k) in (dataKajian.row.arankaabl_neo_evaluasi||[])" :key="k" class="det-check-item"><i
                          class="icon-checkmark-circle text-success"></i><span>{{v||"-"}}</span></div>
                      <span v-if="!(dataKajian.row.arankaabl_neo_evaluasi||[]).length">Tidak ada Data</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')


export default {
  // data(){
  // }
  computed: {
    dataKajian(){
      return this.$parent.$parent.kajianData.kajianPerawat
    },
    dataPenunjang(){
      return this.$parent.$parent.kajianData.kajianPenunjang
    },
    Config(){ return Config },
  },
  
  methods: {
    uploader(filename){
      return process.env.VUE_APP_API_URL + '/uploader/' + filename
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
  },
}

</script>