<template>
  <div class="mt-2">
    <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
      <li class="nav-item"><a href="javascript:;" @click="activeTab = 1" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kajian Awal</a></li>
      <li class="nav-item"><a href="javascript:;" @click="activeTab = 2" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kebutuhan Pasien</a></li>
      <li class="nav-item"><a href="javascript:;" @click="activeTab = 3" :class="activeTab == 3 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kajian Nyeri</a></li>
      <li class="nav-item"><a href="javascript:;" @click="activeTab = 4" :class="activeTab == 4 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Resiko Jatuh</a></li>
      <li class="nav-item"><a href="javascript:;" @click="activeTab = 5" :class="activeTab == 5 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Asuhan Keperawatan </a></li>      
    </ul>
    
    <div class="tab-content">
      <!--- KAJIAN AWAL -->
      <div :class="activeTab == 1 ? 'tab-pane fade active show':'tab-pane fade'">
        <div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Keluhan Utama</h4>
                      <p>{{dataKajian.row.arankaa_keluhan_utama||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Riwayat Penyakit Dahulu</h4>
                      <p>{{dataKajian.row.arankaa_riwayat_penyakit||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Riwayat Penyakit Sekarang</h4>
                      <p>{{dataKajian.row.arankaa_riwayat_penyakit_sekarang||"-"}}</p>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-row mt-2">
            <div class="col-md-6">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Alergi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive" v-if="dataKajian.row.arankaa_alergi == 'Y'">
                        <table class="table table-bordered">
                          <tbody>
                              <tr>
                                <th><strong>Jenis</strong></th>
                                <th><strong>Nama</strong></th>
                              </tr>
                              <tr v-for="(v,k) in (dataKajian.row.arankaa_has_alergi||[])" :key="k+'ob'">
                                <td>
                                    <div class="result_tab">
                                    {{v.jenis||"-"}}
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    {{v.name||"-"}}
                                    </div>
                                </td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="form-row" v-else>
                        <span>Tidak Ada Alergi</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-12">
              <div class="card">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Riwayat Pengobatan</h6>
                </div>
                <div class="card-body p-3">
                  <div class="mb-3">
                    <table class="table table-bordered table-sm">
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Riwayat Pengobatan</h4>
                            <p>{{dataKajian.row.arankaa_riwayat_pengobatan||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th colspan="3">Riwayat Penggunaan Obat</th>
                        </tr>
                        <tr>
                          <th>Nama Obat</th>
                          <th>Dosis</th>
                          <th>Waktu Penggunaan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (dataKajian.row.arankaa_riwayat_penggunaan_obat||[])" :key="k+'riwayatobat'">
                          <td>{{v.nama||"-"}}</td>
                          <td>{{v.dosis||"-"}}</td>
                          <td>{{v.penggunaan||"-"}}</td>
                        </tr>
                        <tr v-if="!(dataKajian.row.arankaa_riwayat_penggunaan_obat||[]).length">
                          <td colspan="99" class="text-center">Tidak Ada Data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
              </div>
              <div class="card-body p-3">
                <div class="form-row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                              <td>
                              <div class="result_tab">
                                  <h4>Tekanan Darah</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_tekanan_darah_min || dataKajian.row.arankaa_ttv_tekanan_darah_max">{{dataKajian.row.arankaa_ttv_tekanan_darah_min}} / {{dataKajian.row.arankaa_ttv_tekanan_darah_max}} mmHG</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                              <td>
                              <div class="result_tab">
                                  <h4>Nadi</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_nadi">{{dataKajian.row.arankaa_ttv_nadi||"-"}}/mnt</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                              <td>
                              <div class="result_tab">
                                  <h4>Gula Darah</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_gula_darah">{{dataKajian.row.arankaa_ttv_gula_darah||"-"}}mg/dL</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                          </tr>
                          <tr>
                              <td>
                              <div class="result_tab">
                                  <h4>Pernafasan</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_pernafasan">{{dataKajian.row.arankaa_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                              <td>
                              <div class="result_tab">
                                  <h4>SPO2</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_spo2">{{dataKajian.row.arankaa_ttv_spo2||"-"}}%</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                              <td>
                              <div class="result_tab">
                                  <h4>Suhu</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_suhu">{{dataKajian.row.arankaa_ttv_suhu||"-"}}C</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                          </tr>
                          <tr>
                              <td>
                              <div class="result_tab">
                                  <h4>Berat Badan</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_weight">{{dataKajian.row.arankaa_ttv_weight||"-"}}kg</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                              <td>
                              <div class="result_tab">
                                  <h4>Tinggi</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_height">{{dataKajian.row.arankaa_ttv_height||"-"}}Cm</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                              <td>
                              <div class="result_tab">
                                  <h4>Lingkar Kepala</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_lingkar_kepala">{{dataKajian.row.arankaa_ttv_lingkar_kepala||"-"}}cm</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                          </tr>
                          <tr>
                              <td v-if="dataKajian.row.ap_usia >= 1">
                              <div class="result_tab">
                                  <h4>BMI</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_bmi">{{dataKajian.row.arankaa_ttv_bmi}}m2</p>
                                  <p v-else> - </p>
                              </div>
                              </td>
                              <td v-if="dataKajian.row.ap_usia <= 15">
                                  <div class="result_tab">
                                  <h4>Luas Permukaan Tubuh Anak</h4>
                                  <p v-if="dataKajian.row.arankaa_ttv_luas_permukaan_anak">{{dataKajian.row.arankaa_ttv_luas_permukaan_anak}}kg/m2</p>
                                  <p v-else> - </p>
                                  </div>
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Kesadaran GCS</h6>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered">
              <tbody>
                  <tr>
                  <td>
                      <div class="result_tab">
                      <h4>Respons Mata</h4>
                      <p>{{getConfigDynamic(Config.mr.ksResponseMata,dataKajian.row.aranktk_response_mata)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.aranktk_response_mata||0}})</span></p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                      <h4>Respons Verbal</h4>
                      <p>{{getConfigDynamic(Config.mr.ksResponseVerbal,dataKajian.row.aranktk_response_verbal)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.aranktk_response_verbal||0}})</span></p>
                      </div>
                  </td>
                  <td>
                      <div class="result_tab">
                      <h4>Respons Motorik</h4>
                      <p>{{getConfigDynamic(Config.mr.ksResponseMotorik,dataKajian.row.aranktk_response_motorik)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.aranktk_response_motorik||0}})</span></p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td colspan="3">
                      <div class="result_tab">
                      <h4>Nilai GCS</h4>
                      <p>{{dataKajian.row.aranktk_nilai_gcs||0}} - 
                      <span v-if="dataKajian.row.aranktk_nilai_gcs >= 14"> Composmentis</span> 
                      <span v-else-if="dataKajian.row.aranktk_nilai_gcs >= 12 && dataKajian.row.aranktk_nilai_gcs <= 13">Apatis</span>
                      <span v-else-if="dataKajian.row.aranktk_nilai_gcs >= 10 && dataKajian.row.aranktk_nilai_gcs <= 11">Delirium</span>
                      <span v-else-if="dataKajian.row.aranktk_nilai_gcs >= 7 && dataKajian.row.aranktk_nilai_gcs <= 9">Somonolen</span>
                      <span v-else-if="dataKajian.row.aranktk_nilai_gcs >= 5 && dataKajian.row.aranktk_nilai_gcs <= 6">Sopor</span>
                      <span v-else-if="dataKajian.row.aranktk_nilai_gcs >= 4 && dataKajian.row.aranktk_nilai_gcs <= 4">Semi-Comma</span>
                      <span v-else>Coma</span>

                      <span class="ml-2" style="margin-right:20px;">E: 
                          {{dataKajian.row.aranktk_response_mata||0}} 
                      </span>
                      <span style="margin-right:20px;">M: 
                          {{dataKajian.row.aranktk_response_motorik||0}}                                 
                      </span>
                      <span style="margin-right:20px;">V: 
                          {{dataKajian.row.aranktk_response_verbal||0}}
                      </span>
                      </p>
                      </div>
                  </td>
                  </tr>
              </tbody>
              </table>
            </div>
          </div>
          
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Riwayat Kehamilan/Persalinan, Imunisasi dan
                Tumbuh Kembang</h6>
            </div>
            <div class="card-body mt-3">
              <div class="mb-3">
                <table class="table table-bordered table-sm">
                  <tr>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Usia Ibu saat Hamil</h4>
                        <p v-if="dataKajian.row.arankaa_usia_ibu_hamil">{{dataKajian.row.arankaa_usia_ibu_hamil}} Tahun</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Gravida</h4>
                        <p>{{dataKajian.row.arankaa_gravida||"-"}}</p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Gangguan Hamil (Trimester I)</h4>
                        <p>{{dataKajian.row.arankaa_gangguan_hamil||"-"}}</p>
                      </div>
                    </td>
                    <td width="25%">
                      <div class="result_tab">
                        <h4>Tipe Persalinan</h4>
                        <p>{{dataKajian.row.arankaa_type_persalinan||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>BB Lahir</h4>
                        <p v-if="dataKajian.row.arankaa_bb">{{dataKajian.row.arankaa_bb}} kg</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>TB</h4>
                        <p v-if="dataKajian.row.arankaa_panjang">{{dataKajian.row.arankaa_panjang}} cm</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Lingkar Kepala</h4>
                        <p v-if="dataKajian.row.arankaa_lingkar_kepala">{{dataKajian.row.arankaa_lingkar_kepala}} cm</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>BB Saat Dikaji</h4>
                        <p v-if="dataKajian.row.arankaa_bb_saat_dikaji">{{dataKajian.row.arankaa_bb_saat_dikaji}} kg</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>TB Saat Dikaji</h4>
                        <p v-if="dataKajian.row.arankaa_tb">{{dataKajian.row.arankaa_tb}} kg</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Imunisasi Dasar</h4>
                        <p>{{dataKajian.row.arankaa_imunisasi_dasar||"-"}}</p>
                        <p v-if="dataKajian.row.arankaa_imunisasi_dasar == 'Tidak lengkap'">{{dataKajian.row.arankaa_imunisasi_dasar_lainnya||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4></h4>
                        <p></p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4></h4>
                        <p></p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="row" v-if="dataKajian.row.ap_usia <= 3">
                <div class="col-md-9">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th colspan="4">Riwayat tumbuh kembang (dikaji pada pasien usia ≤ 3 tahun)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="table-warning">Tengkurap,usia</td>
                        <td>
                          <span v-if="dataKajian.row.arankaa_riwayat_tk_tengkurap">
                          {{dataKajian.row.arankaa_riwayat_tk_tengkurap}} bulan</span>
                          <span v-else> - </span>
                        </td>
                        <td class="table-warning">Duduk,usia</td>
                        <td>
                          <span v-if="dataKajian.row.arankaa_riwayat_tk_duduk">
                          {{dataKajian.row.arankaa_riwayat_tk_duduk}} bulan</span>
                          <span v-else> - </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="table-warning">Berdiri,usia</td>
                        <td>
                          <span v-if="dataKajian.row.arankaa_riwayat_tk_berdiri">
                          {{dataKajian.row.arankaa_riwayat_tk_berdiri}} bulan</span>
                          <span v-else> - </span>
                        </td>
                        <td class="table-warning">Berjalan,usia</td>
                        <td>
                          <span v-if="dataKajian.row.arankaa_riwayat_tk_berjalan">
                          {{dataKajian.row.arankaa_riwayat_tk_berjalan}} bulan</span>
                          <span v-else> - </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="table-warning">Bicara </td>
                        <td>
                          <span v-if="dataKajian.row.arankaa_riwayat_tk_bicara">
                          {{dataKajian.row.arankaa_riwayat_tk_bicara}} bulan</span>
                          <span v-else> - </span>
                        </td>
                        <td class="table-warning">Tumbuh Gigi </td>
                        <td>
                          <span v-if="dataKajian.row.arankaa_riwayat_tk_tumbuh_gigi">
                          {{dataKajian.row.arankaa_riwayat_tk_tumbuh_gigi}} bulan</span>
                          <span v-else> - </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          <div class="card">
              <div class="card-header bg_head_panel" @click="keadaanUmum = !keadaanUmum">
              <h6 class="card-title font-weight-semibold" 
              >Keadaan Umum</h6>
              </div>
              <div class="card-body p-3" v-if="keadaanUmum">
              <table class="table table-bordered">
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Kepala</h4>
                      <p v-if="dataKajian.row.arankaa_vs_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_kepala == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_kepala_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Mata</h4>
                      <p v-if="dataKajian.row.arankaa_vs_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_mata == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_mata_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Hidung</h4>
                      <p v-if="dataKajian.row.arankaa_vs_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_hidung == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_hidung_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Rambut</h4>
                      <p v-if="dataKajian.row.arankaa_vs_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_rambut == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_rambut_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Bibir</h4>
                      <p v-if="dataKajian.row.arankaa_vs_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_bibir == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_bibir_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Gigi Geligi</h4>
                      <p v-if="dataKajian.row.arankaa_vs_gigi == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_gigi == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_gigi_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Lidah</h4>
                      <p v-if="dataKajian.row.arankaa_vs_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_lidah == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_lidah_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Langit-Langit</h4>
                      <p v-if="dataKajian.row.arankaa_vs_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_langit == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_langit_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Leher</h4>
                      <p v-if="dataKajian.row.arankaa_vs_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_leher == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_leher_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Tenggorokan</h4>
                      <p v-if="dataKajian.row.arankaa_vs_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_tenggorokan == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_tenggorokan_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Tonsil</h4>
                      <p v-if="dataKajian.row.arankaa_vs_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_tonsil == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_tonsil_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Dada</h4>
                      <p v-if="dataKajian.row.arankaa_vs_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_dada == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_dada_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Payudara</h4>
                      <p v-if="dataKajian.row.arankaa_vs_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_payudara == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_payudara_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Punggung</h4>
                      <p v-if="dataKajian.row.arankaa_vs_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_punggung == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_punggung_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Perut</h4>
                      <p v-if="dataKajian.row.arankaa_vs_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_perut == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_perut_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Genital</h4>
                      <p v-if="dataKajian.row.arankaa_vs_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_genital == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_genital_text||"-"}}</p>
                      </div>
                  </td>
                  
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Anus</h4>
                      <p v-if="dataKajian.row.arankaa_vs_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_anus == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_anus_text||"-"}}</p>
                      </div>
                  </td>
                  
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Lengan Atas</h4>
                      <p v-if="dataKajian.row.arankaa_vs_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_lengan_atas == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_lengan_atas_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Lengan Bawah</h4>
                      <p v-if="dataKajian.row.arankaa_vs_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_lengan_bawah_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Jari Tangan</h4>
                      <p v-if="dataKajian.row.arankaa_vs_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_jari_tangan == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_jari_tangan_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Kuku Tangan</h4>
                      <p v-if="dataKajian.row.arankaa_vs_kuku == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_kuku == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_kuku_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Sendi Tangan</h4>
                      <p v-if="dataKajian.row.arankaa_vs_sendi_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_sendi_tangan == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_sendi_tangan_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Tungkai Atas</h4>
                      <p v-if="dataKajian.row.arankaa_vs_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_tungkai_atas_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Tungkai Bawah</h4>
                      <p v-if="dataKajian.row.arankaa_vs_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_tungkai_bawah_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Jari Kaki</h4>
                      <p v-if="dataKajian.row.arankaa_vs_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_jari_kaki == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_jari_kaki_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Kuku Kaki</h4>
                      <p v-if="dataKajian.row.arankaa_vs_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_kuku_kaki_text||"-"}}</p>
                      </div>
                  </td>
                  <td width="33%">
                      <div class="result_tab">
                      <h4>Sendi Kaki</h4>
                      <p v-if="dataKajian.row.arankaa_vs_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                      <p v-else-if="dataKajian.row.arankaa_vs_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                      <p v-else>{{dataKajian.row.arankaa_vs_persendian_kaki_text||"-"}}</p>
                      </div>
                  </td>
                  </tr>
                  <tr>
                      <td width="33%" colspan="3">
                          <div class="result_tab">
                          <h4>Saraf</h4>
                          <p v-if="dataKajian.row.arankaa_vs_saraf == 'N'">TAK (Tidak Ada Kelainan)</p>
                          <p v-else-if="dataKajian.row.arankaa_vs_saraf == 'TP'">Tidak Diperiksa</p>
                          <p v-else>
                              <table class="table table-bordered">
                                  <tr>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Refleks Fisiologis</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_r_fisiologis||"-"}}</p>
                                          </div>
                                      </td>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Refleks Patologis</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_r_patologis||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Babinski</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_babinski||"-"}}</p>
                                          </div>
                                      </td>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Lasegue</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_lasegue||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Bregard</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_bregard||"-"}}</p>
                                          </div>
                                      </td>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Sicard</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_sicard||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Kaku Duduk</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_kaku_duduk||"-"}}</p>
                                          </div>
                                      </td>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Kernig Sign</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_kernig_sign||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Brudzinski 1</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_brudzinski||"-"}}</p>
                                          </div>
                                      </td>
                                      <td width="50%">
                                          <div class="result_tab">
                                          <h4>Brudzinski 2</h4>
                                          <p>{{dataKajian.row.arankaa_vs_saraf_brudzinski2||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                              </table>
                          </p>
                          </div>
                      </td>
                  </tr>
              </table>
              </div>
          </div>
          
          <div class="form-row mt-2">
            <div class="col-md-8">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Psikologi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Status Psikologis Pasien</h4>
                              <p>{{getConfigDynamic(Config.mr.psikologiPot,dataKajian.row.arankaa_status_psikologi)||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Status Mental</h4>
                              <p>{{getConfigDynamic(Config.mr.ranapStatusMental,dataKajian.row.arankaa_status_mental)||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td v-if="(dataKajian.row.arankaa_status_mental||[]).indexOf('Ada perilaku kekerasan yang dialami pasien sebelumnya') != -1">
                            <div class="result_tab">
                              <h4>Perilaku kekerasan yang dialami pasien sebelumnya</h4>
                              <p>{{dataKajian.row.arankaa_perilaku_kekerasan||"-"}}</p>
                            </div>
                          </td>
                          
                          <td v-if="(dataKajian.row.arankaa_status_mental||[]).indexOf('Ada kelainan perilaku, sebutkan') != -1">
                            <div class="result_tab">
                              <h4>Perilaku kelainan yang dialami pasien sebelumnya</h4>
                              <p>{{dataKajian.row.arankaa_kelainan_perilaku||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Sosial Ekonomi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Pendidikan</h4>
                              <p>{{getConfigDynamic(mPendidikan,dataKajian.row.arankaa_pendidikan)||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Pekerjaan</h4>
                              <p>{{getConfigDynamic(mPekerjaan,dataKajian.row.arankaa_pekerjaan)||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Spiritual</h6>
              </div>
              <div class="card-body p-3">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <div class="result_tab">
                            <h4>Agama</h4>
                            <p>{{getConfigDynamic(mAgama,dataKajian.row.arankaa_agama)||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Keyakinan</h4>
                            <p>{{dataKajian.row.arankaa_keyakinan||"-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Nilai-nilai Pasien atau Keluarga</h4>
                            <p>{{dataKajian.row.arankaa_nilai||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-12">
              <div class="card mb-0">
              <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
              </div>
              <div class="card-body p-3">
                  <div class="row">
                      <div class="col-md-12">
                          <h6>Hasil Pemeriksaan Lab</h6>
                          <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                              <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(v,k) in (dataPenunjang.dataHasilLab||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                      <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                      </div>
                                  </td>
                              </tr>
                              <tr v-if="!(dataPenunjang.dataHasilLab||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>

                      <div class="col-md-12 mt-3">
                          <h6>Hasil Pemeriksaan Radiologi</h6>
                          <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                              <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologi||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                      <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                      </div>
                                  </td>
                              </tr>
                              <tr v-if="!(dataPenunjang.dataHasilRadiologi||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>

                  <div class="row mt-3">
                      <div class="col-md-12">
                          <h6>Hasil Pemeriksaan Lab Dokter</h6>
                          <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                              <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(v,k) in (dataPenunjang.dataHasilLabDokter||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                      <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                      </div>
                                  </td>
                              </tr>
                              <tr v-if="!(dataPenunjang.dataHasilLabDokter||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>

                      <div class="col-md-12 mt-3">
                          <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                          <table class="table table-bordered table-striped table-sm patient-table">
                              <thead>
                              <tr>
                                  <th>Hasil Pemeriksaan </th>
                                  <th>Hasil</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(v,k) in (dataPenunjang.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                  <td>{{v.value}}</td>
                                  <td>
                                      <div class="result_tab form-group mb-0">
                                      <a class="btn_view" :href="uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                      </div>
                                  </td>
                              </tr>
                              <tr v-if="!(dataPenunjang.dataHasilRadiologiDokter||[]).length">
                                  <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      <!--- KAJIAN AWAL -->
      
      <!--- KEB PASIEN -->
      <div :class="activeTab == 2 ? 'tab-pane fade active show':'tab-pane fade'">
        <div class="form-row">
          <div class="col-md-6">
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Nutrisi</h6>
              </div>
              <div class="card-body p-3">
                <table class="table table-sm table-bordered">
                  <tbody>
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Intake Nutrisi Lewat</h4>
                          <p v-for="(v,k) in (dataKajian.row.arankkp_intake_nutrisi||[])" :key="k">{{v||"-"}}</p>
                          <span v-if="!(dataKajian.row.arankkp_intake_nutrisi||[]).length">Tidak ada data</span>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Masalah yang Berhubungan dengan Nutrisi</h4>
                          <p>{{dataKajian.row.arankkp_nutrisi_obesitas||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Elimnasi</h6>
              </div>
              <div class="card-body p-3">
                <table class="table table-sm table-bordered">
                  <tbody>
                    <tr class="table-secondary">
                      <th class="font-weight-semibold" colspan="2">BAB</th>
                    </tr>
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Kondisi</h4>
                          <p v-if="dataKajian.row.arankkp_bab_kondisi == 'L'">{{dataKajian.row.arankkp_bab_kondisi_text||"-"}}</p>
                          <p v-else>{{dataKajian.row.arankkp_bab_kondisi||"-"}}</p>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Frekuensi BAB /hari</h4>
                          <p>{{dataKajian.row.arankkp_bab_frekuensi||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="table-secondary">
                      <th class="font-weight-semibold" colspan="2">BAK</th>
                    </tr>
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Kondisi</h4>
                          <p v-if="dataKajian.row.arankkp_bak_kondisi == 'L'">{{dataKajian.row.arankkp_bak_kondisi_text||"-"}}</p>
                          <p v-else>{{dataKajian.row.arankkp_bak_kondisi||"-"}}</p>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Kateter</h4>
                          <p>{{dataKajian.row.arankkp_bak_frekuensi||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Proteksi dan Risiko</h6>
            </div>
            <div class="card-body p-3">
              <div class="table-responsive">
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Status Mental</h4>
                        <p v-if="dataKajian.row.arankkp_status_mental == 'L'">{{dataKajian.row.arankkp_status_mental_text||"-"}}</p>
                        <p v-else>{{dataKajian.row.arankkp_status_mental||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tipe dan Frekuensi</h4>
                        <p>{{dataKajian.row.arankkp_tipe_frekuensi||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th colspan="2">Pengkajian Restrain</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Pengkajian Restrain</h4>
                        <p>{{getConfigDynamic(Config.mr.isRestrain,dataKajian.row.arankkp_pengkajian_restrain)||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <div class="result_tab">
                        <h4>Pernah menggunakan restrain sebelumnya?</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOpt,dataKajian.row.arankkp_is_have_restrain)||"-"}}</p>
                      </div>
                    </td>
                    <td width="50%">
                      <div class="result_tab">
                        <h4>Dimana?</h4>
                        <p>{{dataKajian.row.arankkp_lokasi_restrain||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Edukasi dengan keluarga dan pasien untuk penggunaan restrain</h4>
                        <p>{{dataKajian.row.arankkp_is_edukasi_restrain == 'Y' ? "Ya":"Tidak"}}</p>                      
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th colspan="2">Pengkajian Risiko Jatuh</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Pengkajian Risiko Jatuh</h4>
                        <p v-if="dataKajian.row.arankkp_pengkajian_resiko_jatuh !== 'L'">{{getConfigDynamic(Config.mr.ranapKPRiwayatJatuh,dataKajian.row.arankkp_pengkajian_resiko_jatuh)||"-"}}</p>
                        <p v-else>{{dataKajian.row.arankkp_pengkajian_resiko_jatuh_text||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Kebutuhan Komunikasi dan Edukasi</h6>
            </div>
            <div class="card-body p-3">
              <div class="table-responsive">
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Hubungan Pasien dengan Keluarga</h4>
                        <p>{{getConfigDynamic(Config.mr.baikNoOpt,dataKajian.row.arankkp_hub_pasien)||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Pekerjaan</h4>
                        <p v-if="dataKajian.row.arankkp_pekerjaan == 99999">{{dataKajian.row.arankkp_pekerjaan_text||"-"}}</p>
                        <p v-else>{{getConfigDynamic(mPekerjaan,dataKajian.row.arankkp_pekerjaan)||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Bimbingan Rohani?</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOpt,dataKajian.row.arankkp_has_bimbingan_rohani)||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Rohaniawan yang Dibutuhkan</h4>
                        <p>{{getConfigDynamic(mAgama,dataKajian.row.arankkp_rohanian)||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Sebutkan nilai/keyakinan keluarga</h4>
                        <p>{{dataKajian.row.arankkp_nilai_keyakinan||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="mt-2">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Assesment Restrain</h6>
            </div>
            <div class="mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Tekanan Darah</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_tekanan_darah_min || dataKajian.row.arankkp_ttv_tekanan_darah_max">{{dataKajian.row.arankkp_ttv_tekanan_darah_min}} / {{dataKajian.row.arankkp_ttv_tekanan_darah_max}} mmHG</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Nadi</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_nadi">{{dataKajian.row.arankkp_ttv_nadi||"-"}}/mnt</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Gula Darah</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_gula_darah">{{dataKajian.row.arankkp_ttv_gula_darah||"-"}}mg/dL</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Pernafasan</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_pernafasan">{{dataKajian.row.arankkp_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>SPO2</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_spo2">{{dataKajian.row.arankkp_ttv_spo2||"-"}}%</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Suhu</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_suhu">{{dataKajian.row.arankkp_ttv_suhu||"-"}}C</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Berat Badan</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_weight">{{dataKajian.row.arankkp_ttv_weight||"-"}}kg</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Tinggi</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_height">{{dataKajian.row.arankkp_ttv_height||"-"}}Cm</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Lingkar Kepala</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_lingkar_kepala">{{dataKajian.row.arankkp_ttv_lingkar_kepala||"-"}}cm</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td v-if="dataKajian.row.ap_usia >= 1">
                                <div class="result_tab">
                                    <h4>BMI</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_bmi">{{dataKajian.row.arankkp_ttv_bmi}}m2</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td v-if="dataKajian.row.ap_usia <= 15">
                                    <div class="result_tab">
                                    <h4>Luas Permukaan Tubuh Anak</h4>
                                    <p v-if="dataKajian.row.arankkp_ttv_luas_permukaan_anak">{{dataKajian.row.arankkp_ttv_luas_permukaan_anak}}kg/m2</p>
                                    <p v-else> - </p>
                                    </div>
                                </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Kesadaran GCS</h6>
              </div>
              <div class="card-body p-3">
                <table class="table table-bordered">
                <tbody>
                    <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Respons Mata</h4>
                        <p>{{getConfigDynamic(Config.mr.ksResponseMata,dataKajian.row.arankkp_tingkat_kesadaran_mata)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.arankkp_tingkat_kesadaran_mata||0}})</span></p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Respons Verbal</h4>
                        <p>{{getConfigDynamic(Config.mr.ksResponseVerbal,dataKajian.row.arankkp_tingkat_kesadaran_verbal)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.arankkp_tingkat_kesadaran_verbal||0}})</span></p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Respons Motorik</h4>
                        <p>{{getConfigDynamic(Config.mr.ksResponseMotorik,dataKajian.row.arankkp_tingkat_kesadaran_motorik)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.arankkp_tingkat_kesadaran_motorik||0}})</span></p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td colspan="3">
                        <div class="result_tab">
                        <h4>Nilai GCS</h4>
                        <p>{{dataKajian.row.arankkp_tingkat_kesadaran||0}} - 
                        <span v-if="dataKajian.row.arankkp_tingkat_kesadaran >= 14"> Composmentis</span> 
                        <span v-else-if="dataKajian.row.arankkp_tingkat_kesadaran >= 12 && dataKajian.row.arankkp_tingkat_kesadaran <= 13">Apatis</span>
                        <span v-else-if="dataKajian.row.arankkp_tingkat_kesadaran >= 10 && dataKajian.row.arankkp_tingkat_kesadaran <= 11">Delirium</span>
                        <span v-else-if="dataKajian.row.arankkp_tingkat_kesadaran >= 7 && dataKajian.row.arankkp_tingkat_kesadaran <= 9">Somonolen</span>
                        <span v-else-if="dataKajian.row.arankkp_tingkat_kesadaran >= 5 && dataKajian.row.arankkp_tingkat_kesadaran <= 6">Sopor</span>
                        <span v-else-if="dataKajian.row.arankkp_tingkat_kesadaran >= 4 && dataKajian.row.arankkp_tingkat_kesadaran <= 4">Semi-Comma</span>
                        <span v-else>Coma</span>

                        <span class="ml-2" style="margin-right:20px;">E: 
                            {{dataKajian.row.arankkp_tingkat_kesadaran_mata||0}} 
                        </span>
                        <span style="margin-right:20px;">M: 
                            {{dataKajian.row.arankkp_tingkat_kesadaran_motorik||0}}                                 
                        </span>
                        <span style="margin-right:20px;">V: 
                            {{dataKajian.row.arankkp_tingkat_kesadaran_verbal||0}}
                        </span>
                        </p>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
              </div>
            </div>

            <div class="mt-2 row">
              <div class="card mb-0 col-md-4">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Hasil Observasi</h6>
                </div>
                <div class="card-body p-3">
                  <ul v-if="(dataKajian.row.arankkp_hasil_observasi||[]).length">
                    <li v-for="(v,k) in (dataKajian.row.arankkp_hasil_observasi||[])" :key="k">
                      {{v||"-"}}
                    </li>
                  </ul>
                  <span v-else>Tidak ada data</span>
                </div>
              </div>
              
              <div class="card mb-0 col-md-4">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Pertimbangan Klinis</h6>
                </div>
                <div class="card-body p-3">
                  <ul v-if="(dataKajian.row.arankkp_hasil_klinis||[]).length">
                    <li v-for="(v,k) in (dataKajian.row.arankkp_hasil_klinis||[])" :key="k">
                      {{v||"-"}}
                    </li>
                  </ul>
                  <span v-else>Tidak ada data</span>
                </div>
              </div>
              
              <div class="card mb-0 col-md-4">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Penilaian dan Order Dokter</h6>
                </div>
                <div class="card-body p-3">
                  <ul v-if="(dataKajian.row.arankkp_hasil_penilaian||[]).length">
                    <li v-for="(v,k) in (dataKajian.row.arankkp_hasil_penilaian||[])" :key="k">
                      {{v||"-"}}
                    </li>
                  </ul>
                  <span v-else>Tidak ada data</span>
                </div>
              </div>
            </div>

          </div>
        </div>
        -->
      </div>
      <!--- KEB PASIEN -->

      <!--- KAJIAN NYERI -->
      <div :class="activeTab == 3 ? 'tab-pane fade active show':'tab-pane fade'">
        <div class="mt-2"> 
          <div class="row g-2" v-if="dataKajian.row.arankkn_is_nyeri == 'Y'">
            <!-- Skala Nyeri jika pasien berusia lebih dari sama dengan 6 tahun -->
            <div class="col-12" v-if="dataKajian.row.ap_usia > 6">
              <div class="row align-items-center">
                <div class="col-md">
                  <div>
                    <label for="">Skala Nyeri</label>
                      <div class="pain-scale no-pain" v-if="dataKajian.row.arankkn_skala_nyeri_value == 0">
                        <img :src="assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                        <strong>Tidak Ada Nyeri</strong>
                      </div>
                      <div class="pain-scale mild-pain" v-if="dataKajian.row.arankkn_skala_nyeri_value >= 1 && dataKajian.row.arankkn_skala_nyeri_value <= 3">
                          <img :src="assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Sedikit Nyeri</strong>
                      </div>
                      <div class="pain-scale moderate-pain" v-if="dataKajian.row.arankkn_skala_nyeri_value >= 4 && dataKajian.row.arankkn_skala_nyeri_value <= 5">
                          <img :src="assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Agak Mengganggu</strong>
                      </div>
                      <div class="pain-scale severe-pain" v-if="dataKajian.row.arankkn_skala_nyeri_value >= 6 && dataKajian.row.arankkn_skala_nyeri_value <= 7">
                          <img :src="assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Mengganggu Aktivitas</strong>
                      </div>
                      <div class="pain-scale worst-pain" v-if="dataKajian.row.arankkn_skala_nyeri_value >= 8 && dataKajian.row.arankkn_skala_nyeri_value <= 9">
                          <img :src="assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Sangat Mengganggu</strong>
                      </div>
                      <div class="pain-scale worst-pain" v-if="dataKajian.row.arankkn_skala_nyeri_value >= 10">
                          <img :src="assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Tak Tertahankan</strong>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Skala Nyeri jika pasien berusia kurang dari 6 tahun -->
            <div class="col-12" v-else>
              <div class="form-group">
                <label for="kidPainScale">Skala Nyeri<strong class="text-danger">*</strong></label>
                <table class="table table-bordered pain-scale-table">
                  <thead>
                    <tr>
                      <th>Pengkajian</th>
                      <th>Hasil</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in dataKajian.row.arankkn_skala_nyeri" :key="k+'akn'">
                      <th class="table-warning text-center"><img :src="assetLocal(v.img)"
                        style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                        {{v.name}}</th>
                      <td>
                        {{getConfigDynamic(v.configVal,v.value)||"-"}}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="table-info">
                      <td colspan="4" class="text-info-700">
                          <span>NILAI SKALA NYERI: {{dataKajian.row.arankkn_skala_nyeri_value||"-"}}</span>
                          <span v-if="dataKajian.row.arankkn_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                          <span v-else-if="dataKajian.row.arankkn_skala_nyeri_value >= 1 && dataKajian.row.arankkn_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                          <span v-else-if="dataKajian.row.arankkn_skala_nyeri_value >= 4 && dataKajian.row.arankkn_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                          <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div class="card mt-2">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold pl-2">Pemeriksaan Fisik (Termasuk pemeriksan Neurologis
                  dan
                  Muskoloskeletal)</h6>
              </div>
              <div class="card-body p-3">
                  <div class="row gx-2">
                      <div class="col-lg-4">
                      <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                      <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in dataKajian.row.arankkn_pemeriksaan_fisik||[]" :key="k+'nav'">
                          <a href="javascript:;" data-toggle="tab" 
                          :class="activeAnat == k ? 'nav-link active':'nav-link'">
                          <div class="d-flex justify-content-between align-items-center">
                              <span>{{v.name||"(Anatomi Baru)"}}</span>
                              <div>
                              <i class="icon-chevron-right"></i>
                              </div>
                          </div>
                          </a>
                      </li>
                      </ul>
                      </div>
                      <div class="col-lg-8">
                      <div class="tab-content">
                          <div v-for="(v,k) in dataKajian.row.arankkn_pemeriksaan_fisik||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                          <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                              <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                              <div class="body_wrapper">
                                  <img :src="assetLocal(v.image)" class="img-fluid w-100" alt="">
                              </div>
                              <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                                  :style="`top:${v1.y}%;left:${v1.x}%`">
                                  <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                  :title="v1.value"></a>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                      </div>
                  </div>
              </div>
            </div>

            <table class="table table-sm table-bordered mt-2">
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Frekuensi Nyeri</h4>
                    <p>{{dataKajian.row.arankkn_frekuensi_nyeri||"-"}}</p>
                  </div>
                </td>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Lama Nyeri</h4>
                    <p>{{dataKajian.row.arankkn_lama_nyeri||"-"}}</p>
                  </div>
                </td>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Menjalar?</h4>
                    <p>{{getConfigDynamic(Config.mr.ranapKNMenjalar,dataKajian.row.arankkn_menjalar)||"-"}}
                    <span v-if="dataKajian.row.arankkn_menjalar == 'Y'">{{dataKajian.row.arankkn_menjalar_text||"-"}}</span>
                    </p>
                    
                  </div>
                </td>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Kualitas Nyeri</h4>
                    <p>{{dataKajian.row.arankkn_kualitas_nyeri||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="4" width="33%">
                  <div class="result_tab">
                    <h4>Faktor pemicu/yang memperberat</h4>
                    <p>{{dataKajian.row.arankkn_faktor_pemicu||"-"}}</p>
                  </div>
                </td>
                <td colspan="4" width="33%">
                  <div class="result_tab">
                    <h4>Faktor yang mengurangi</h4>
                    <p>{{dataKajian.row.arankkn_faktor_mengurangi||"-"}}</p>
                  </div>
                </td>
                <td colspan="4" width="33%">
                  <div class="result_tab">
                    <h4>Tindak Lanjut Skala Nyeri</h4>
                    <p>{{dataKajian.row.arankkn_tindak_lanjut_skala_nyeri||"-"}}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="row" v-else>
            <div class="col-md-12">
              <span>Tidak ada Nyeri</span>
            </div>
          </div>
        </div>
      </div>
      <!--- KAJIAN NYERI -->

      <!--- KAJIAN RESIKO JATUH -->
      <div :class="activeTab == 4 ? 'tab-pane fade active show':'tab-pane fade'">
        <div class="card" v-if="dataKajian.row.ap_usia <= 18 || (dataKajian.row.ap_usia == null && dataKajian.row.ap_dob == null 
        && dataKajian.row.arankrj_resiko_type == '1')">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Kajian Humpty Dumpty</h6>
          </div>
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Umur</h4>
                    <p v-if="dataKajian.row.arankrj_humpty_umur != null">{{getConfigDynamic(Config.mr.hdUmur,dataKajian.row.arankrj_humpty_umur)}} <strong class="text-info-800">({{dataKajian.row.arankrj_humpty_umur}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p v-if="dataKajian.row.arankrj_humpty_jenis_kelamin != null">{{getConfigDynamic(Config.mr.hdJK,dataKajian.row.arankrj_humpty_jenis_kelamin)}} <strong class="text-info-800">({{dataKajian.row.arankrj_humpty_jenis_kelamin}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Diagnosis</h4>
                    <p v-if="dataKajian.row.arankrj_humpty_diagnosis != null">{{getConfigDynamic(Config.mr.hdDiagnosis,dataKajian.row.arankrj_humpty_diagnosis)}} <strong class="text-info-800">({{dataKajian.row.arankrj_humpty_diagnosis}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Gangguan Kognitif</h4>
                    <p v-if="dataKajian.row.arankrj_humpty_gangguan_kognitif != null">{{getConfigDynamic(Config.mr.hdGangguanKognitif,dataKajian.row.arankrj_humpty_gangguan_kognitif)}} <strong class="text-info-800">({{dataKajian.row.arankrj_humpty_gangguan_kognitif}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Faktor Lingkungan</h4>
                    <p v-if="dataKajian.row.arankrj_humpty_faktor_lingkungan != null">{{getConfigDynamic(Config.mr.hdFaktorLingkungan,dataKajian.row.arankrj_humpty_faktor_lingkungan)}} <strong class="text-info-800">({{dataKajian.row.arankrj_humpty_faktor_lingkungan}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Respon Pembedahan</h4>
                    <p v-if="dataKajian.row.arankrj_humpty_respon_pembedahan != null">{{getConfigDynamic(Config.mr.hdPembedahan,dataKajian.row.arankrj_humpty_respon_pembedahan)}} <strong class="text-info-800">({{dataKajian.row.arankrj_humpty_respon_pembedahan}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Penggunaan obat</h4>
                    <p v-if="dataKajian.row.arankrj_humpty_respon_penggunaan_obat != null">{{getConfigDynamic(Config.mr.hdPenggunaanObat,dataKajian.row.arankrj_humpty_respon_penggunaan_obat)}} <strong class="text-info-800">({{dataKajian.row.arankrj_humpty_respon_penggunaan_obat}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="table-info">
                <td colspan="5" class="text-info-700">
                  <span class="text-uppercase">Tingkat Risiko Humpty Dumpty: {{dataKajian.row.arankrj_humpty_value}}</span>
                  <span v-if="dataKajian.row.arankrj_humpty_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Tidak Beresiko</span>
                  <span v-else-if="dataKajian.row.arankrj_humpty_value >= 7 && dataKajian.row.arankrj_humpty_value <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                  <span v-else-if="dataKajian.row.arankrj_humpty_value >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        
        <div class="card" v-else-if="dataKajian.row.ap_usia > 18 && dataKajian.row.ap_usia <= 60 || (dataKajian.row.ap_usia == null && dataKajian.row.ap_dob == null && dataKajian.row.arankrj_resiko_type == '2')">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Kajian Morse Fall Scale</h6>
          </div>
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Riwayat Jatuh atau dalam 3 bulan terakhir</h4>
                    <p v-if="dataKajian.row.arankrj_morshe_riwayat_jatuh != null">{{getConfigDynamic(Config.mr.snRiwayatJatuh,dataKajian.row.arankrj_morshe_riwayat_jatuh)}} <strong class="text-info-800">({{dataKajian.row.arankrj_morshe_riwayat_jatuh}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Diagnosis medis sekunder > 1</h4>
                    <p v-if="dataKajian.row.arankrj_morshe_diagnosa != null">{{getConfigDynamic(Config.mr.snDiagnosa,dataKajian.row.arankrj_morshe_diagnosa)}} <strong class="text-info-800">({{dataKajian.row.arankrj_morshe_diagnosa}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Alat bantu jalan</h4>
                    <p v-if="dataKajian.row.arankrj_morshe_alat_bantu != null">{{getConfigDynamic(Config.mr.snAlatBantuJalan,dataKajian.row.arankrj_morshe_alat_bantu)}} <strong class="text-info-800">({{dataKajian.row.arankrj_morshe_alat_bantu}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pakai infus</h4>
                    <p v-if="dataKajian.row.arankrj_morshe_inpus != null">{{getConfigDynamic(Config.mr.snPakaiInfus,dataKajian.row.arankrj_morshe_inpus)}} <strong class="text-info-800">({{dataKajian.row.arankrj_morshe_inpus}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Cara berjalan / berpindah</h4>
                    <p v-if="dataKajian.row.arankrj_morshe_cara_jalan != null">{{getConfigDynamic(Config.mr.snCaraJalan,dataKajian.row.arankrj_morshe_cara_jalan)}} <strong class="text-info-800">({{dataKajian.row.arankrj_morshe_cara_jalan}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Status mental</h4>
                    <p v-if="dataKajian.row.arankrj_morshe_status_mental != null">{{getConfigDynamic(Config.mr.snStatusMental,dataKajian.row.arankrj_morshe_status_mental)}} <strong class="text-info-800">({{dataKajian.row.arankrj_morshe_status_mental}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="table-info">
                <td colspan="5" class="text-info-700">
                  <span class="text-uppercase">Tingkat Risiko Morse Fall Scale: {{dataKajian.row.arankrj_morshe_value}}</span>
                  <span v-if="dataKajian.row.arankrj_morshe_value < 24" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                  <span v-else-if="dataKajian.row.arankrj_morshe_value >= 25 && dataKajian.row.arankrj_morshe_value <= 44" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                  <span v-else-if="dataKajian.row.arankrj_morshe_value > 45" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="card" v-else-if="dataKajian.row.ap_usia > 60 || (dataKajian.row.ap_usia == null && dataKajian.row.ap_dob == null && dataKajian.row.arankrj_resiko_type == '3')">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Kajian Skala Ontario Modified Stratify - Sydney Scoring</h6>
          </div>
          <table class="table table-sm table-bordered">
            <tbody>
              <tr class="table-warning">
                <th colspan="3">
                  <span class="font-weight-semibold">Riwayat Jatuh <span class="text-info">({{dataKajian.row.arankrj_riwayat_jatuh_is_jatuh=='Y' || dataKajian.row.arankrj_riwayat_jatuh_is_jatuh2bulan=='Y'?6:0}})</span></span>
                </th>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Apakah pasien datang ke rumah sakit karena jatuh?</h4>
                    
                    <p v-if="dataKajian.row.arankrj_riwayat_jatuh_is_jatuh != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_riwayat_jatuh_is_jatuh)}}</p>
                    <p v-else> - </p>

                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jika tidak, apakah pasien mengalami jatuh dalam 2 bulan terakhir?</h4>
                    <p v-if="dataKajian.row.arankrj_riwayat_jatuh_is_jatuh2bulan != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_riwayat_jatuh_is_jatuh2bulan)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td></td>
              </tr>
              <tr class="table-warning">
                <th colspan="3">
                  <span class="font-weight-semibold">Status Mental <span class="text-info">({{(dataKajian.row.arankrj_status_mental_dellirium == "Y" || dataKajian.row.arankrj_status_mental_disorientasi == "Y" ||
                  dataKajian.row.arankrj_status_mental_agitasi == "Y") ? 14 : 0}})</span></span>
                </th>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Apakah pasien delirium? (tidak membuat keputusan, pola pikir tidak terorganisir, gangguan daya ingat)</h4>
                    <p v-if="dataKajian.row.arankrj_status_mental_dellirium != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_status_mental_dellirium)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Apakah pasien disorientasi? (salah menyebutkan waktu, tempat atau orang)</h4>
                    <p v-if="dataKajian.row.arankrj_status_mental_disorientasi != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_status_mental_disorientasi)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Apakah pasien mengalami agatis? (ketakutan, gelisah dan cemas)</h4>
                    <p v-if="dataKajian.row.arankrj_status_mental_agitasi != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_status_mental_agitasi)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr class="table-warning">
                <th colspan="3">
                  <span class="font-weight-semibold">Penglihatan <span class="text-info">(
                    {{(dataKajian.row.arankrj_status_mental_dellirium == "Y" || dataKajian.row.arankrj_status_mental_disorientasi == "Y" ||
                    dataKajian.row.arankrj_status_mental_agitasi == "Y" )?1:0}}
                    )</span></span>
                </th>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Apakah pasien memakai kacamata?</h4>
                    <p v-if="dataKajian.row.arankrj_penglihatan_kacamata != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_penglihatan_kacamata)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Apakah pasien mengeluh penglihatan buram?</h4>
                    <p v-if="dataKajian.row.arankrj_penglihatan_buram != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_penglihatan_buram)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Apakah pasien mempunyai glaucoma, katarak, atau degenerasi makula?</h4>
                    <p v-if="dataKajian.row.arankrj_penglihatan_glaucoma != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_penglihatan_glaucoma)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr class="table-warning">
                <th colspan="3">
                  <span class="font-weight-semibold">Kebiasaan <span class="text-info">({{dataKajian.row.arankrj_kebiasaan == 'Y'?2:0}})</span></span>
                </th>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Apakah terdapat perubahan perilaku berkemih? (frekuensi, urgensi, inkontinensia, nokturia)</h4>
                    <p v-if="dataKajian.row.arankrj_kebiasaan != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajian.row.arankrj_kebiasaan)}}</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr class="table-warning">
                <th colspan="3">
                  <span class="font-weight-semibold">Transfer (dari tempat tidur ke kursi dan kembali ke tempat tidur)</span>
                </th>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Transfer</h4>
                    <p v-if="dataKajian.row.arankrj_transfer != null">{{getConfigDynamic(Config.mr.syTransfer,dataKajian.row.arankrj_transfer)}} <strong class="text-info-800">({{dataKajian.row.arankrj_transfer}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr class="table-warning">
                <th colspan="3">
                  <span class="font-weight-semibold">Mobilitas</span>
                </th>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="result_tab">
                    <h4>Mobilitas</h4>
                    <p v-if="dataKajian.row.arankrj_mobilitas != null">{{getConfigDynamic(Config.mr.syMobilitas,dataKajian.row.arankrj_mobilitas)}} <strong class="text-info-800">({{dataKajian.row.arankrj_mobilitas}})</strong></p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="table-info">
                <td colspan="5" class="text-info-700">
                  <span class="text-uppercase">Tingkat Risiko Ontario Modified Stratify - Sydney Scoring: {{dataKajian.row.arankrj_sydney_value}}</span>

                  <span v-if="dataKajian.row.arankrj_sydney_value < 6" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                  <span v-else-if="dataKajian.row.arankrj_sydney_value >= 6 && dataKajian.row.arankrj_sydney_value < 17" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                  <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                </td>
                <!-- Penilaian Tingkat Risiko Ontario Modified Stratify - Sydney Scoring
                0 - 5 = Risiko Rendah
                6 - 16 = Risiko Sedang
                17 - 30 = Risiko Tinggi
                -->
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="card-body mt-2">
          <table class="table table-bordered table-striped table-hover table-sm patient-table">
            <thead>
              <tr>
                <th>HASIL</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                    <span v-if="dataKajian.row.arankrj_type_intervensi == 'S'">Intervensi Resiko Jatuh Standar</span>
                    <span v-else-if="dataKajian.row.arankrj_type_intervensi == 'T'">Intervensi Resiko Jatuh Tinggi</span>
                    <span v-else>Tidak Beresiko</span>
                </td>
                <td>
                  <ul v-if="(dataKajian.row.arankrj_data_intervensi||[]).length">
                    <li v-for="(v,k) in (dataKajian.row.arankrj_data_intervensi||[])" :key="k">
                      {{v||"-"}}
                    </li>
                  </ul>
                  <span v-else>Tidak ada</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Jadwal Monitoring</h6>
          </div>
          
          <table class="table table-bordered table-striped table-hover table-sm patient-table">
            <thead>
              <tr>
                <th>Jadwal</th>
                <th>Tanggal dan Jam</th>
              </tr>
              <tr v-for="(v,k) in (dataKajian.row.arankrj_jadwal_monitoring||[])" :key="k">
                <td>
                  Ke-{{k+1}}
                </td>
                <td> 
                <span v-if="v.date"> {{v.date| moment("DD MMMM YYYY")}} </span>
                <span v-else> - </span>
                 {{v.jam||"-"}}</td>
              </tr>
              <tr v-if="!(dataKajian.row.arankrj_jadwal_monitoring||[]).length">
                <td colspan="99" class="text-center">Tidak ada Data</td>
              </tr>
            </thead>
          </table>
        </div>

        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Checklist Pengaman</h6>
          </div>
          <table class="table table-bordered table-sm">
            <tbody>
              <tr class="table-warning">
                <th><span class="font-weight-semibold">TEMPAT TIDUR</span></th>
                <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
                </tr>
                <tr class="table-secondary ">
                <td colspan="2">
                    Pegangan sisi tempat tidur
                </td>
              </tr>
              <tr>
                <td>
                  Mudah dinaikkan dan diturunkan
                </td>
                <td>
                  {{dataKajian.row.arankrj_bed_cek_naik_turun == 'Y' ? "Ya" : "Tidak"}}
                </td>
              </tr>
              <tr>
                <td>
                    Terkunci dengan aman saat dinaikkan
                </td>
                <td>
                  {{dataKajian.row.arankrj_bed_cek_aman_saat_naik == 'Y' ? "Ya" : "Tidak"}}
                </td>
              </tr>

                <tr>
                <td>
                    Hanya digunakan untuk mobilitas
                </td>
                <td>
                  {{dataKajian.row.arankrj_bed_cek_saat_mobilitas == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Roda
                </td>
                </tr>
                
                <tr>
                <td>
                    Mudah berputar/diarahkan, tidak melekat
                </td>
                <td>
                  {{dataKajian.row.arankrj_bed_cek_roda == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Rem
                </td>
                </tr>

                <tr>
                <td>
                    Mengamankan tempat tidur saat dioperasikan
                </td>
                <td>
                  {{dataKajian.row.arankrj_bed_cek_rem == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Mekanik
                </td>
                </tr>

                <tr>
                <td>
                  Pengaturan ketinggian tempat tidur mudah dilakukan
                </td>
                <td>
                  {{dataKajian.row.arankrj_bed_cek_mekanik == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Meja samping tempat tidur
                </td>
                </tr>

                <tr>
                <td>
                    Roda terkunci dengan baik
                </td>
                <td>
                    {{dataKajian.row.arankrj_bed_cek_meja_terkunci == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Letaknya disamping tempat tidur, menempel didinding
                </td>
                <td>
                    {{dataKajian.row.arankrj_bed_cek_meja_didinding == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>
            </tbody>
            
            <tbody>
                <tr class="table-warning">
                <th><span class="font-weight-semibold">TIANG INFUS</span></th>
                <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Tiang
                </td>
                </tr>

                <tr>
                <td>
                    Mudah dinaikkan dan diturunkan
                </td>
                <td>
                    {{dataKajian.row.arankrj_tiang_cek_naik_turun == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Stabil, tidak mudah goyang
                </td>
                <td>
                    {{dataKajian.row.arankrj_tiang_cek_stabil == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Roda
                </td>
                </tr>

                <tr>
                <td>
                    Mudah berputar/diarahkan, tidak melekat
                </td>
                <td>
                    {{dataKajian.row.arankrj_tiang_cek_berputar == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>


            </tbody>
            
            <tbody>
                <tr class="table-warning">
                <th><span class="font-weight-semibold">TUMPUAN KAKI</span></th>
                <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
                </tr>
                <tr class="table-secondary ">
                <td colspan="2">
                    Kaki kursi
                </td>
                </tr>

                <tr>
                <td>
                    Proteksi karet anti-selip dikesemua kaki
                </td>
                <td>
                    {{dataKajian.row.arankrj_tumpuan_proteksi == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Stabil, tidak goyang
                </td>
                <td>
                    {{dataKajian.row.arankrj_tumpuan_stabil == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Bagian atas kursi
                </td>
                </tr>

                <tr>
                <td>
                    Permukaan tidak licin
                </td>
                <td>
                    {{dataKajian.row.arankrj_tumpuan_tidak_licin == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>
            </tbody>

            <tbody>
                <tr class="table-warning">
                <th><span class="font-weight-semibold">BEL PANGGILAN/ PENCAHAYAAN</span></th>
                <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
                </tr>
                <tr class="table-secondary ">
                <td colspan="2">
                    Operasional
                </td>
                </tr>

                <tr>
                <td>
                    Lampu diluar kamar
                </td>
                <td>
                    {{dataKajian.row.arankrj_bel_lampu_kamar == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Alarm berbunyi di counter
                </td>
                <td>
                    {{dataKajian.row.arankrj_bel_alarm == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Nomor kamar muncul di monitor
                </td>
                <td>
                    {{dataKajian.row.arankrj_bel_nomor_kamar == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Sinyal panel kamar
                </td>
                <td>
                    {{dataKajian.row.arankrj_bel_sinyal_kamar == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Akses
                </td>
                </tr>

                <tr>
                <td>
                    Mudah diraih saat di kamar mandi
                </td>
                <td>
                    {{dataKajian.row.arankrj_bel_mudah_diraih == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Dalam jangkauan saat pasien di tempat tidur
                </td>
                <td>
                    {{dataKajian.row.arankrj_bel_tempat_tidur == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>
            </tbody>

            <tbody>
                <tr class="table-warning">
                <th><span class="font-weight-semibold">WALKER/ CANE</span></th>
                <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
                </tr>
                <tr class="table-secondary ">
                <td colspan="2">
                    Keamanan
                </td>
                </tr>

                <tr>
                <td>
                    Ujung karet pada alat berfungsi dengan baik
                </td>
                <td>
                    {{dataKajian.row.arankrj_walker_ujung_karet == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Stabil
                </td>
                <td>
                    {{dataKajian.row.arankrj_walker_stabil == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>
            </tbody>

            <tbody>
                <tr class="table-warning">
                <th><span class="font-weight-semibold">TOILET BERJALAN</span></th>
                <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
                </tr>
                <tr class="table-secondary ">
                <td colspan="2">
                    Roda
                </td>
                </tr>

                <tr>
                <td>
                    Mudah berputar/diarahkan, tidak melekat
                </td>
                <td>
                    {{dataKajian.row.arankrj_toilet_berputar == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Stabil saat pasien duduk diatasnya
                </td>
                <td>
                    {{dataKajian.row.arankrj_toilet_stabil == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>


                <tr class="table-secondary ">
                <td colspan="2">
                    Rem
                </td>
                </tr>

                <tr>
                <td>
                    Mengamankan tempat tidur saat dioperasikan
                </td>
                <td>
                    {{dataKajian.row.arankrj_rem == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

            </tbody>

            <tbody>
                <tr class="table-warning">
                <th><span class="font-weight-semibold">KURSI BERODA (MOBILITY CHAIR)</span></th>
                <th class="font-weight-semibold"><span class="font-weight-semibold"></span></th>
                </tr>
                <tr class="table-secondary ">
                <td colspan="2">
                    Kursi
                </td>
                </tr>

                <tr>
                <td>
                    Tingginya disesuaikan dengan pasien, untuk meminimalisir terjatuh/ terjungkal
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_tinggi_sesuai == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Roda
                </td>
                </tr>

                <tr>
                <td>
                    Mudah berputar/diarahkan, tidak melekat
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_roda_berputar == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>
                <tr class="table-secondary ">
                <td colspan="2">
                    Rem
                </td>
                </tr>

                <tr>
                <td>
                    Dioperasikan saat kursi dalam posisi diam
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_rem_dioperasikan == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Mengamankan toilet saat dioperasikan
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_rem_aman == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr>
                <td>
                    Pengaman kursi
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_rem_pengaman == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Tumpuan kaki
                </td>
                </tr>

                <tr>
                <td>
                    Dapat dilipat
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_kaki_dapat_dilipat == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Posisi
                </td>
                </tr>

                <tr>
                <td>
                    Diposisikan dengan derajat kemiringan yang sesuai untuk mencegah terjungkal kedepan/
                    merosot
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_kaki_derajat_kemiringan == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

                <tr class="table-secondary ">
                <td colspan="2">
                    Nampan
                </td>
                </tr>

                <tr>
                <td>
                    Dalam posisi aman
                </td>
                <td>
                    {{dataKajian.row.arankrj_kursi_kaki_posisi_aman == 'Y' ? "Ya" : "Tidak"}}
                </td>
                </tr>

            </tbody>
          </table>
        </div>


      </div>
      <!--- KAJIAN RESIKO JATUH -->

      <!--- ASUHAN KEPERAWATAN -->
      <div :class="activeTab == 5 ? 'tab-pane fade active show':'tab-pane fade'">
        <div class="form-row">
            <div class="col-md-12">
            <div class="card border shadow-0">
                <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)</h5>
                </div>
                <div class="card-body">
                <ul class="nav nav-tabs nav-tabs-bottom">
                    <li class="nav-item" v-for="(v,k) in (dataKajian.diagnosa||[])" :key="k">
                        <a href="javascript:;" @click="changeTabSDKI(k+1)" data-toggle="tab" 
                        :data-target="'#diagTab'+(k+1)" 
                        :class="activeTabSDKI == (k+1) ? 'nav-link active' : 'nav-link'">
                        {{v.msd_code + " - " +v.msd_name||"Diagnosa baru"}}                
                        </a>
                    </li>
                </ul>
                <div class="tab-content card-body p-0">
                    <div v-for="(v,k) in (dataKajian.diagnosa||[])" :key="k+'a'" 
                    :class="activeTabSDKI == (k+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#diagTab'+(k+1)">
                    <div class="row">
                        <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                            <tbody>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Diagnosa</h4>
                                    <p>{{v.msd_code ? v.msd_code + ' - ' + v.msd_name : '-'}}</p>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    <div class="form-row mt-2">
                        <div class="col-md-6">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Penyebab</h6>
                            </div>
                            <div class="card-body p-3">
                            <div class="form-row">
                                <div class="col-md-12">
                                <template v-for="(v,k) in (dataKajian.diagnosa[k].arkaksd_penyebab||[])">
                                    <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{v}}</span>
                                    </div>
                                </template>
                                <p v-if="!(dataKajian.diagnosa[k].arkaksd_penyebab||[]).length">Tidak Ada</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                        <div class="col-md-6">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
                            </div>
                            <div class="card-body p-3">
                            <div class="form-row">
                                <div class="col-md-6">
                                <template v-for="(v,k) in (dataKajian.diagnosa[k].arkaksd_faktor_resiko||[])">
                                    <div :key="k+'faktor-res'" class="d-flex align-items-center  mb-2">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{v}}</span>
                                    </div>
                                </template>
                                <p v-if="!(dataKajian.diagnosa[k].arkaksd_faktor_resiko||[]).length">Tidak Ada</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
                            </div>
                            <div class="card-body p-3">
                            <div class="form-row">
                                <div class="col-md-6">
                                <h6 class="font-weight-semibold">Subjective</h6>
                                <template v-for="(v,k) in (dataKajian.diagnosa[k].arkaksd_gejala_mayor_subjektif||[])">
                                    <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{v}}</span>
                                    </div>
                                </template>
                                <p v-if="!(dataKajian.diagnosa[k].arkaksd_gejala_mayor_subjektif||[]).length">Tidak Ada</p>
                                </div>
                                <div class="col-md-6">
                                <h6 class="font-weight-semibold">Objective</h6>
                                <template v-for="(v,k) in (dataKajian.diagnosa[k].arkaksd_gejala_mayor_objektif||[])">
                                    <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{v}}</span>
                                    </div>
                                </template>
                                <p v-if="!(dataKajian.diagnosa[k].arkaksd_gejala_mayor_objektif||[]).length">Tidak Ada</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
                            </div>
                            <div class="card-body p-3">
                            <div class="form-row">
                                <div class="col-md-6">
                                <h6 class="font-weight-semibold">Subjective</h6>
                                <template v-for="(v,k) in (dataKajian.diagnosa[k].arkaksd_gejala_minor_subjektif||[])">
                                    <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{v}}</span>
                                    </div>
                                </template>
                                <p v-if="!(dataKajian.diagnosa[k].arkaksd_gejala_minor_subjektif||[]).length">Tidak Ada</p>
                                </div>
                                <div class="col-md-6">
                                <h6 class="font-weight-semibold">Objective</h6>
                                <template v-for="(v,k) in (dataKajian.diagnosa[k].arkaksd_gejala_minor_objektif||[])">
                                    <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{v}}</span>
                                    </div>
                                </template>
                                <p v-if="!(dataKajian.diagnosa[k].arkaksd_gejala_minor_objektif||[]).length">Tidak Ada</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-12">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Standar Luaran Keperawatan Indonesia
                                (SLKI)
                            </h6>
                            </div>
                            <div class="card-body p-3">
                            <div class="form-row">
                                <div class="col-md-12">
                                <ul class="nav nav-tabs nav-tabs-bottom">
                                    <li class="nav-item" v-for="(v1,k1) in v.slki" :key="'Luaran'+k1">
                                    <a href="javascript:;" @click="changeTabSLKI(k1+1,k)" data-toggle="tab" 
                                    :data-target="'#luarTab'+(k1+1)" :class="v.arkaksd_last_tab_slki == (k1+1) ? 'nav-link active' : 'nav-link'">
                                    {{  v1.msl_code + " - " + v1.msl_name || "Luaran Baru"}}</a>
                                    </li>
                                </ul>
                                <div class="tab-content card-body p-0">
                                    <div v-for="(v1,k1) in v.slki" :key="k1+'lu'" :class="v.arkaksd_last_tab_slki == (k1+1) ? 'tab-pane fade show active':'tab-pane fade'"  :id="'#luarTab'+(k1+1)">
                                    <div class="row">
                                        <div class="col-md-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4> Luaran</h4>
                                                    <p>{{v1.msl_code ? v1.msl_code + ' - ' + v1.msl_name : '-'}}</p>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                        <div class="col-md-12">
                                        <table
                                            class="table table-bordered table-striped table-hover table-sm patient-table">
                                            <thead>
                                            <tr>
                                                <th>Kriteria</th>
                                                <th>Skor</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(v11,k11) in (v1.arkaksl_kriteria_hasil||[])" :key="k11+'kh'">
                                                <td>
                                                {{v11.name||"-"}}
                                                </td>
                                                <td>{{v11.hasil||"-"}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-12">
                        <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Standar Intervensi Keperawatan Indonesia
                                (SIKI)
                            </h6>
                            </div>
                            <div class="card-body p-3">
                            <div class="form-row">
                                <div class="col-md-12">
                                <ul class="nav nav-tabs nav-tabs-bottom">
                                        
                                    <li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
                                    <a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab" 
                                    :data-target="'#intvTab'+(k2+1)" :class="v.arkaksd_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
                                    {{ v2.msi_code + " - " +v2.msi_name || "Intervensi Baru"}}
                                    </a>
                                    </li>
                                </ul>
                                <div class="tab-content card-body p-0">
                                    <div v-for="(v2,k2) in v.siki" :key="k2+'sik'" :class="v.arkaksd_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#intvTab1'+(k2+1)">
                                    <div class="row">
                                        <div class="col-md-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4> Intervensi</h4>
                                                    <p>{{v2.msi_code ? v2.msi_code + ' - ' + v2.msi_name : '-'}}</p>
                                                    </div>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                        <div class="col-md-12">
                                        <table
                                            class="table table-bordered table-striped table-hover table-sm patient-table">
                                            <thead>
                                                <tr>
                                                <th class="table-primary" colspan="99">Tindakan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th colspan="2" class="table-light bg_head_panel">OBSERVASI</th>
                                                </tr>
                                                <template v-for="(vo,ko) in dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_observasi']" >
                                                    <tr :key="ko+'ko'" v-if="vo.value">
                                                        <td colspan="2">{{vo.name}}</td>
                                                    </tr>
                                                </template>
                                                <tr v-if="!countTindakan(dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_observasi'])">
                                                    <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                <th colspan="2" class="table-light bg_head_panel">TERAPEUTIK</th>
                                                </tr>
                                                <template v-for="(vt,kt) in dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_terapeutik']" >
                                                    <tr :key="kt+'kt'" v-if="vt.value">
                                                        <td colspan="2">{{vt.name}}</td>
                                                    </tr>
                                                </template>
                                                <tr v-if="!countTindakan(dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_terapeutik'])">
                                                    <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                </tr>
                                            </tbody>
                                            
                                            <tbody>
                                                <tr>
                                                <th colspan="2" class="table-light bg_head_panel">EDUKASI</th>
                                                </tr>
                                                <template v-for="(ve,ke) in dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_edukasi']" >
                                                    <tr :key="ke+'ke'" v-if="ve.value">
                                                        <td colspan="2">{{ve.name}}</td>
                                                    </tr>
                                                </template>
                                                <tr v-if="!countTindakan(dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_edukasi'])">
                                                    <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                </tr>
                                                
                                            </tbody>
                                            
                                            <tbody>
                                                <tr>
                                                <th colspan="2" class="table-light bg_head_panel">KOLABORASI</th>
                                                </tr>
                                                <template v-for="(vk,kk) in dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_kolaborasi']" >
                                                    <tr :key="kk+'kk'" v-if="vk.value">
                                                        <td colspan="2">{{vk.name}}</td>
                                                    </tr>
                                                </template>
                                                <tr v-if="!countTindakan(dataKajian.diagnosa[k]['siki'][k2]['arkaksi_tindakan_kolaborasi'])">
                                                    <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>         
                    <div class="card border shadow-0">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Tindakan Keperawatan</h6>
                        </div>
                        <div>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Diagnosa</th>
                                        <th>Tindakan Keperawatan</th>
                                        <th width="185">PPA</th>
                                        <th>Waktu</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(vti,kti) in (v.arkaksd_tindakan_keperawatan||[])" :key="kti">
                                        <td v-if="kti == 0"
                                            :rowspan="(v.arkaksd_tindakan_keperawatan||[]).length">
                                            {{v.text||"Diagnosa baru"}}</td>
                                        <td>{{vti.name||"-"}}</td>
                                        <td>      
                                            <p>{{getConfigDynamic(Config.mr.isPerawatOrDokter,vti.ppa)||"-"}}</p>
                                            <p>{{vti.ppaLainnya || "-"}}</p>
                                        </td>
                                        <td>                     
                                            <span v-if="vti.dates"> {{vti.dates| moment("DD MMMM YYYY")}} </span>
                                            <span v-else> - </span>
                                            {{vti.time.HH}} {{vti.time.mm}}
                                        </td>
                                    </tr>
                                    <tr v-if="!(v.arkaksd_tindakan_keperawatan||[]).length">
                                        <td colspan="99">Tidak ada Data</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="card-body" v-if="(v.arkaksd_tindakan_keperawatan||[]).length">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Nama Perawat</h4>
                                    <p>{{dataKajian.diagnosa[k]['arkaksd_nama_perawat']||"-"}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Waktu Tindakan</h4>
                                    <p>{{dataKajian.diagnosa[k]['arkaksd_tindakan_pada']||"-"}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Tindakan Keperawatan</h4>
                                    <p>{{dataKajian.diagnosa[k]['arkaksd_informasi_tindakan']||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
      <!--- ASUHAN KEPERAWATAN -->
    </div>
  </div>
</template>

<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')


export default {
  data(){
    return {
      activeTab: 1,
      keadaanUmum: false,
    
      mAlergi: [],
      mKesadaran: [],
      mPekerjaan: [],
      mPendidikan: [],
      mAgama: [],  

      activeAnat: 0,
      activeTabSDKI: 1,
      activeTabSLKI: 1,
      activeTabSIKI: 1
    }
  },
  computed: {
    dataKajian(){
      return this.$parent.$parent.kajianData.kajianPerawat
    },
    dataPenunjang(){
      return this.$parent.$parent.kajianData.kajianPenunjang
    },
    dischargePlanningCriteria(){
      return this.$parent.$parent.kajianData.dischargePlanningCriteria
    },
    Config(){ return Config },
  },
  methods: {
    
    changeTabAnat(k, isAdd = false) {
      this.activeAnat = k
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    getMaster(){
      let url = 'RanapKAPerawatAnak'+'/'+ this.dataKajian.row.aranrm_aranr_id
      Gen.apiRest(
        "/get/"+url+'?master=1'
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
      })
    },
    countTindakan(data){
      let total = 0
      for(let i = 0; i < (data||[]).length; i++){
          if(data[i]['value']){
              total += 1
          }
      }
      return total
    },
    changeTabSDKI(k){
      this.activeTabSDKI = k
    },
    changeTabSLKI(k1,k){
      this.dataKajian.diagnosa[k].arkaksd_last_tab_slki = k1
    },
    changeTabSIKI(k2,k){
      this.dataKajian.diagnosa[k].arkaksd_last_tab_siki = k2
    },
    uploader(filename){
      return process.env.VUE_APP_API_URL + '/uploader/' + filename
    }
  },
  mounted(){
    this.getMaster()
  },
}
</script>