<template>
  <div class="">
    <div class="row mt-2">
      <div class="col-md-12">
        <table class="table table-bordered table-striped table-hover table-sm patient-table">
          <thead>
              <tr>
                  <th>No. Reg</th>
                  <th>Tanggal Reg</th>
                  <th>Nama Dokter</th>
                  <th>Keluhan</th>
                  <th>Kajian</th>
                  <th>Riwayat RM</th>
                  <th>Catatan Asuhan</th>
              </tr>
          </thead>
          <template v-if="(dataRow||[]).length">
              <template v-for="(v,k) in (dataRow||[])" >
                  <tbody :class="v.isHead? 'isHead': ''" :key="k">
                      <tr :class="v.aurm_is_draft == 'Y' ? 'table-warning' : ''">
                          <td>
                              <span>{{v.aur_reg_code||"-"}}</span>
                          </td>
                          <td>
                              <span v-if="v.aur_reg_date">{{ v.aur_reg_date | moment("DD MMM YYYY")}}</span>
                              <span v-else>{{ v.aur_reg_date | moment("DD MMM YYYY")}}</span>
                          </td>
                          <td>
                              <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                          </td>

                          <td>
                              <span>{{v.keluhan||"-"}}</span>
                          </td>
                          
                          <td>
                          
                          <div class="d-flex">
                              <a href="javascript:;" v-if="v.kajianPerawat" @click="toDetailKajianPerawatUGD(v,v.aurm_aur_id)" class="btn btn-sm btn-icon" :class="'alpha-blue border-blue'" v-b-tooltip.hover="'Lihat Kajian Perawat'"><i class="icon-file-eye"></i></a>
                              <span v-else>-</span>
                          </div>

                          </td>
                          
                          <td>
                          <a href="javascript:;" v-if="v.kajianDokter" @click="doOpenRiwayatRMUGD(v)" v-b-tooltip.hover="'Lihat Riwayat Rekam Medis'" class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
                          <span v-else>-</span>
                          </td>

                          <td>
                              <template>
                                  <a href="javascript:;" @click="toCatatanAsuhanDetail(v.aur_id)" class="btn btn-icon alpha-teal border-teal text-teal-800" data-popup="tooltip" v-b-tooltip.hover title="Lihat Catatan Asuhan">
                                  <i class="icon-file-eye"></i>
                                  </a>
                              </template>
                          </td>
                      </tr>
                  </tbody>
              </template>
          </template>
          <tbody v-else-if="!(dataRow||[]).length && dataRow">
              <tr>
                  <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                      Data Tidak Ditemukan
                  </th>
              </tr>      
          </tbody>
          <tbody v-else>
              <tr>
                  <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                      <div class="skeletal-comp mb-2"></div>
                      <div class="skeletal-comp mb-2"></div>
                      <div class="skeletal-comp"></div>
                  </th>
              </tr>
          </tbody>
          <tfoot v-if="(dataRow||[]).length && pageNow < dataProps.last_page">
              <tr>
                  <td class="text-center" colspan="99">
                      <a href="javascript:;" @click="loadMoreUGD" class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
                  </td>
              </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
        <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
    </b-modal>
    
    <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
        <RiwayatRMUGD v-bind="passToSubRMUGD" />
        <div class="row mt-3">
            <div class="col-md-12">
                <a href="javascript:;" @click="doOpenRiwayatRMUGD(openRiwayatData)"
                class="btn float-right btn-primary btn-labeled btn-labeled-left mb-1">
                <b><i class="icon-spinner11"></i></b>
                Muat Ulang
                </a>
            </div>
        </div>
    </b-modal>

     <b-modal v-model="openCatatanAsuhan" title="Catatan Asuhan" size="xl" ok-only ok-title="Tutup">
        <div class="table-responsive">
            <table class="table table-bordered table-sm table-align-top">
                <thead>
                <tr>
                    <td colspan="6" class="bg-info">
                    <span class="font-weight-semibold">Catatan Asuhan Perawat</span>
                    </td>
                </tr>
                <tr>
                    <th>Waktu</th>
                    <th>Asesmen/Tindakan</th>
                    <th>Waktu Evaluasi</th>
                    <th>Evaluasi</th>
                    <th>PPA</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in (dataCatatanAsuhan.data||[])" :key="k">
                    <td>
                    <span>{{v.aucap_date | moment("DD MMMM YYYY") }},{{v.aucap_jam||"-"}}</span>
                    </td>
                    <td>

                    <template v-if="v.aucap_type == 'L' || v['aucap_assesmen_tindakan'] == 'Free Text'">
                        <span v-if="v['aucap_assesmen_tindakan'] != 'Free Text'">{{v.aucap_assesmen_tindakan||"-"}}</span>
                        <span v-else> 
                            {{v.aucap_assesmen_tindakan_text||"-"}}
                        </span>
                        
                        <template v-if="v['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                            <div class="form-inline">
                            <label>Injeksi pada</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                            </div>
                        </template>
                        
                        <template v-if="v['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                            <div class="form-inline">
                            <label>Incici pada</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                            </div>
                        </template>

                        <template v-if="v['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                            <label>Warna gelang</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>
                        </template>
                        
                        <template v-if="v['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                            <label>Nama Obat</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>                            
                        </template>
                        

                        
                        <template v-if="v['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                            <div class="mb-2">
                            <label>Nama Obat</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text||"-"}}</span>                            
                            </div>
                            
                            <div class="mb-2">
                            <label>Dosis</label>
                            <span>{{v.aucap_assesmen_tindakan_sup_text2||"-"}}</span>                            
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div>
                            <span>{{v.aucap_assesmen_tindakan_text||"-"}}</span>
                        </div>
                    </template>

                    </td>
                    <td>
                    <span>{{v.aucap_evaluasi_date | moment("DD MMMM YYYY") }},{{v.aucap_evaluasi_jam||"-"}}</span>
                    </td>
                    <td>
                        <template v-if="v.aucap_type == 'L'">
                            
                        <div v-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan oksigen'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Oksigen terpasang</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>L/menit, SpO2</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan Perekaman EKG'">
                            <div class="mb-2 evalContent">
                                <span>Hasil Terlampir</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang bedside monitor'">
                            <div class="mb-2 evalContent">
                                <span>Monitor terpasang</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemeriksaan cek gula darah acak'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Hasil GDA</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan infus sesuai dengan advis dokter'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                    <label>Infus terpasang cairan</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                        
                                    <label>dengan tetesan</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                    <label>/menit tusukan pada</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                                    <label>dengan abocath</label>
                                    <span class="ml-1">{{v.aucap_evaluasi_t4||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Injeksi masuk lewat</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                        
                                <label>tanda-tanda alergi</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                        
                                <label>keluhan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>                                        
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan kateter tetap'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Kateter terpasang nomor</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>dengan balon WFI</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                <label>urin keluar</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>                                       
                                <label>CC setelah pemasangan</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan suction'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Lendir terhisap, suara nafas</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan penjahitan luka'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Luka terjahit</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>. Simpul dengan jenis dan ukuran benang</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                </div>
                            </div>
                            
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Kondisi luka setelah dilakukan perawatan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan ekstraksi kuku'">
                            <div class="mb-2 evalContent">
                                <span>Kuku bisa terangkat, luka bersih</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>sudah terambil perawatan luka telah dilakukan, luka bekas incici bersih</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Terpasang gelang identitas warna </label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>Pasien maupun keluarga paham penjelasan petugas</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang penanda alergi'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Terpasang gelang alergi warna merah dengan tulisan alergen </label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang bidai/spalk'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Terpasang bidai pada</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>Hasil pengecekan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                <label>tanda tanda kompartemen</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pengambilan benda asing/corpal'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Benda asing pada</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>pada area</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan irigasi mata'">
                            <div class="mb-2 evalContent">
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan lavement'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Lavement masuk</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>                                       
                                <label>CC dengan cairan yang digunakan</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>                                       
                                <label>hasil evaluasi feses</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>                                       
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan pelepasan kateter tetap'">
                            <div class="mb-2 evalContent">
                                <span>Kateter berhasil dilakukan pelepasan dengan bleder training sebelumnya</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Nebulizer telah dilakukan, sesak nafas</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>SpO2</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                <label>sputum</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memasang NGT'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>NGT terpasang dengan ukuran</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>pada hidung sebelah</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                <label>hasil evaluasi terdengar bunyi blup pada area lambung</label>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka combutio'">
                            <div class="mb-2 evalContent">
                                <div class="form-inline">
                                <label>Luka tampak</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t1||"-"}}</span>
                                <label>Bula</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t2||"-"}}</span>
                                <label>perawatan menggunakan topical terapi</label>
                                <span class="ml-1">{{v.aucap_evaluasi_t3||"-"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                            <div class="mb-2 evalContent">
                                <span>Obat masuk melalui syring pump, lancar</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Melakukan CTG'">
                            <div class="mb-2 evalContent">
                                <span>Hasil terlampir</span>
                            </div>
                        </div>
                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Memfasilitasi pasien untuk pemeriksaan radiologi'">
                            <div class="mb-2 evalContent">
                                <span>Hasil terlampir</span>
                            </div>
                        </div>

                        <div class="" v-else-if="dataCatatanAsuhan.data[k]['aucap_assesmen_tindakan'] == 'Free Text'">
                            <span>{{dataCatatanAsuhan.data[k]['aucap_evaluasi_text']||"-"}}</span>
                        </div>

                        </template>
                        <template v-else>
                            <span class="ml-1">{{v.aucap_evaluasi_text||"-"}}</span>
                        </template>
                    
                    </td>
                    <td>
                    <span>{{v.aucap_ppa}}</span>
                    </td>
                    <td>
                    <span v-if="v.aucap_is_done != 'Y'"> - </span>
                    <span v-else>Selesai pada {{ v.aucap_evaluasi_date | moment("DD MMMM YYYY") }} {{v.aucap_evaluasi_jam}}</span>
                    </td>
                </tr>
                <tr v-if="!(dataCatatanAsuhan.data||[]).length">
                    <td colspan="6" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-sm table-align-top">
                <thead>
                <tr>
                    <td colspan="6" class="bg-indigo">
                    <span class="font-weight-semibold">Catatan Asuhan Dokter</span>
                    </td>
                </tr>
                <tr>
                    <th>Waktu</th>
                    <th>Asesmen/Tindakan</th>
                    <th>Waktu Evaluasi</th>
                    <th>Evaluasi</th>
                    <th>PPA</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                
                <template v-if="Object.keys(dataCatatanAsuhan||{}).length ">
                <template v-if="dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp == 'Y'">
                <tr class="dpjpEl">
                    <td>
                        <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                        {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_waktu||"-"}}</span>
                    </td>
                    
                    <td>
                        <div class="tb-content">
                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                        <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama {{dataCatatanAsuhan.rowDataDokter.ausd_keluhan||"-"}} <span v-if="dataCatatanAsuhan.rowDataDokter.ausd_riwayat">, Pasien mempunyai riwayat penyakit dahulu: {{dataCatatanAsuhan.rowDataDokter.ausd_riwayat||"-"}} </span></p>
                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                            <ul class="mb-0" v-if="dataCatatanAsuhan.rowDataDokter.ausd_has_alergi == 'Y'">
                                <li v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.ausd_alergi||[])" :key="k"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}</li>
                            </ul>
                            <span v-else> - </span>
                        </p>
                        <!--
                        <div class="mb-2">
                            <span class="font-weight-semibold">Keluhan Saat ini</span>
                            <div>{{dataCatatanAsuhan.rowDataDokter.ausd_catatan_dokter||"-"}}</div>
                        </div>
                        -->
                        <div class="mb-2">
                            <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                            <div>{{dataCatatanAsuhan.rowDataDokter.aupd_rencana_pengobatan||"-"}}</div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Riwayat Pembedahan</span>
                            <div>
                            <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Invasif</span>
                            <template v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[]).length">
                            <ol class="mb-0"
                            v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else>Tidak ada Tindakan</p>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">A: </span>Keluhan saat ini
                            <div><span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_keluhan||"-"}}</span></div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                            <div>{{dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value}} 
                                <template v-if="(dataCatatanAsuhan.row.ap_usia > 6 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NP'))">
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value == 0">Tidak Ada Nyeri</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 1 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 3">Sedikit Nyeri</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 4 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 5">Agak Mengganggu</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 6 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 7">Mengganggu Aktivitas</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 8 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value <= 9">Sangat Mengganggu</span>
                                    <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 10">Tak Tertahankan</span>
                                </template>
                                
                                <template v-if="(dataCatatanAsuhan.row.ap_usia <= 6) || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null)">
                                    <template v-if="(dataCatatanAsuhan.row.ap_usia > 1 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'FL'))">
                                        <span v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                        <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 1 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                        <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 4 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                    </template>

                                    <template v-if="((dataCatatanAsuhan.row.ap_usia <= 1  && dataCatatanAsuhan.row.ap_usia != null)|| (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                        <span v-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                        <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value >= 3 && dataCatatanAsuhan.rowDataDokter.auod_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tanda-tanda Vital</span>
                            <ul class="mb-0">
                            
                            <li>Tekanan Darah: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_tekanan_darah_min||"-"}}/{{dataCatatanAsuhan.rowDataDokter.auod_ttv_tekanan_darah_max||"-"}} mmHG</li>
                            <li>Nadi: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_nadi||"-"}} x/mnt - {{dataCatatanAsuhan.rowDataDokter.auod_ttv_label||"-"}}</li>
                            <li>Gula Darah: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_gula_darah||"-"}} mg/dL</li>
                            <li>Pernafasan: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_pernafasan||"-"}} x/mnt</li>
                            <li>SPO2: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_spo2||"-"}}%</li>
                            <li>Suhu: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_suhu||"-"}}C</li>
                            <li>Berat Badan {{dataCatatanAsuhan.rowDataDokter.auod_ttv_weight||"-"}} kg</li>
                            <li>Tinggi: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_height||"-"}} cm</li>
                            <li>Lingkar Kepala: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_lingkar_kepala||"-"}} cm</li>
                            <li>Luas Permukaan Tubuh Anak: {{dataCatatanAsuhan.rowDataDokter.auod_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                            <li>Kesadaran: <span>{{dataCatatanAsuhan.rowDataDokter.auod_gcs_total}} | 
                        
                                <span v-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 14"> Composmentis</span> 
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 12 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 13">Apatis</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 10 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 11">Delirium</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 7 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 9">Somonolen</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 5 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 6">Sopor</span>
                                <span v-else-if="dataCatatanAsuhan.rowDataDokter.auod_gcs_total >= 4 && dataCatatanAsuhan.rowDataDokter.auod_gcs_total <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>
                                
                                <span class="ml-2" style="margin-right:20px;">E: 
                                    {{dataCatatanAsuhan.rowDataDokter.auod_response_mata||0}} 
                                </span>
                                <span style="margin-right:20px;">M: 
                                    {{dataCatatanAsuhan.rowDataDokter.auod_response_motorik||0}}  
                                </span>
                                <span style="margin-right:20px;">V: 
                                    {{dataCatatanAsuhan.rowDataDokter.auod_response_verbal||0}}
                                </span>
                            </span></li>
                            </ul>
                        </div>

                        
                        <div class="mb-2">
                            <span class="font-weight-semibold">Pemeriksaan Fisik</span>
                            <ul class="mb-0">
                                <template v-for="(v,k) in (Config.mr.configVitalSignUGD||[])">
                                    <div :key="k" v-if="v.value != 'auod_vs_saraf'">
                                        <li v-if="dataCatatanAsuhan.rowDataDokter[v.value] != 'TP'"> 
                                            {{v.label}} : <span>{{dataCatatanAsuhan.rowDataDokter[v.value+'_text']}}</span>
                                        </li>
                                    </div>
                                    <div :key="k" v-else>
                                        <template v-if="dataCatatanAsuhan.rowDataDokter[v.value] != 'TP'">
                                            <li>
                                                <span> {{v.label}} :</span>
                                            </li>
                                            <p class="mb-0">Refleks Fisiologis : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                            <p class="mb-0">Refleks Patologis : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_r_patologis||"-"}}</p>
                                            <p class="mb-0">Babinski : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_babinski||"-"}}</p>
                                            <p class="mb-0">Lasegue : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_lasegue||"-"}}</p>
                                            <p class="mb-0">Bregard : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_bregard||"-"}}</p>
                                            <p class="mb-0">Sicard : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_sicard||"-"}}</p>
                                            <p class="mb-0">Kaku Duduk : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                            <p class="mb-0">Kernig Sign : {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_kernig_sign||"-"}}</p>
                                            <p class="mb-0">Brudzinski 1: {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_brudzinski||"-"}}</p>
                                            <p class="mb-0">Brudzinski 2: {{dataCatatanAsuhan.rowDataDokter.auod_vs_saraf_brudzinski2||"-"}}</p>
                                        </template>
                                    </div>
                                </template>

                            </ul>
                            <span v-if="!isHavePemeriksaanFisik">Hasil pemeriksaan fisik normal</span>
                        </div>

                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Lab</span>
                            <p>{{selectedLabInput||"-"}}</p>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Radiologi</span>
                            <p>{{selectedRadioInput||"-"}}</p>                                    
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">R</span>
                            <div class="mb-1">
                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                            <template v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <span v-else>Tidak ada Tindakan</span>
                            

                            </div>
                            <div class="mb-1">
                            <span>Usulan kolaborasi</span>
                            <template v-if="(dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <span v-else>Tidak ada Tindakan</span>

                            </div>
                        </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_tanggal_evaluasi}} {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_waktu_evaluasi}}</span>
                    </td>
                    
                    <td>
                        <div class="form-group" 
                    v-for="(vU,kU) in (dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                            <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                            <pre class="pre-input mb-1">{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['value']||"-"}}</pre>
                            <span v-if="dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">
                            Selesai pada {{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                        </div>
                        
                        <div id="verifikasiRanap">
                            <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                            
                            <div class="form-group">
                            <label for="">Nama DPJP</label>
                                <p>{{dataCatatanAsuhan.rowDataDokter.nama_dokter_ranap||"-"}}
                                </p>
                            </div>

                            <div class="form-group">
                            <label>Tanggal</label>
                                <p>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                            </div>
                            
                            <div class="form-group">
                            <label>Jam</label>
                                <p>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                            </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{dataCatatanAsuhan.rowDataDokter.aupd_laporan_dpjp_ppa||"-"}}</span>
                    </td>
                </tr>
                <tr class="dpjpEl" v-for="(v,k) in (dataCatatanAsuhan.catatanDPJPDokter||[])" :key="k">
                    <td>
                        <span>{{v.auldd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                        {{v.auldd_laporan_dpjp_waktu||"-"}}</span>
                    </td>
                    
                    <td>
                        <div class="tb-content">
                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                        <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama {{v.auldd_subjektif||"-"}} <span v-if="v.auldd_riwayat_penyakit">, Pasien mempunyai riwayat penyakit dahulu: {{v.auldd_riwayat_penyakit||"-"}} </span></p>
                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                            <ul class="mb-0">
                                <li v-for="(v1,k1) in (v.auldd_riwayat_alergi||[])" :key="k1"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v1.jenis)}})</span>{{v1.name||"-"}}</li>
                            </ul>
                            <span v-if="!(v.auldd_riwayat_alergi||[]).length"> - </span>
                        </p>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Keluhan Saat Ini</span>
                            <div>{{v.auldd_subjektif||"-"}}</div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                            <div>{{v.auldd_catatan_dokter||"-"}}</div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Riwayat Pembedahan</span>
                            <div>
                            <span>{{v.auldd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Invasif</span>
                            <template v-if="(v.auldd_laporan_dpjp_tindakan_invasif||[]).length">
                            <ol class="mb-0"
                            v-for="(v,k) in (v.auldd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else>Tidak ada Tindakan</p>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">A: </span>Keluhan saat ini
                            <div><span>{{v.auldd_laporan_dpjp_keluhan||"-"}}</span></div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                            <div>{{v.auldd_hasil_skoring_nyeri}}
                            <template v-if="(dataCatatanAsuhan.row.ap_usia > 6 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NP'))">
                                <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="v.auldd_hasil_skoring_nyeri == 0">Tidak Ada Nyeri</span>
                                <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri <= 3">Sedikit Nyeri</span>
                                <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri <= 5">Agak Mengganggu</span>
                                <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 6 && v.auldd_hasil_skoring_nyeri <= 7">Mengganggu Aktivitas</span>
                                <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 8 && v.auldd_hasil_skoring_nyeri <= 9">Sangat Mengganggu</span>
                                <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 10">Tak Tertahankan</span>
                            </template>
                            
                            <template v-if="(dataCatatanAsuhan.row.ap_usia <= 6) || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null)">
                                <template v-if="(dataCatatanAsuhan.row.ap_usia > 1 || (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'FL'))">
                                    <span v-if="v.auldd_hasil_skoring_nyeri == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                    <span v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                    <span v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                </template>

                                <template v-if="((dataCatatanAsuhan.row.ap_usia <= 1  && dataCatatanAsuhan.row.ap_usia != null)|| (dataCatatanAsuhan.row.ap_usia == null && dataCatatanAsuhan.row.ap_dob == null && dataCatatanAsuhan.rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                    <span v-if="v.auldd_hasil_skoring_nyeri < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                    <span v-else-if="v.auldd_hasil_skoring_nyeri >= 3 && v.auldd_hasil_skoring_nyeri < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                </template>
                            </template>
                            </div>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tanda-tanda Vital</span>
                            <ul class="mb-0">
                            
                            <li>Tekanan Darah: {{v.auldd_ttv_tekanan_darah_min||"-"}}/{{v.auldd_ttv_tekanan_darah_max||"-"}} mmHG</li>
                            <li>Nadi: {{v.auldd_ttv_nadi||"-"}} x/mnt - {{v.auldd_ttv_label||"-"}}</li>
                            <li>Gula Darah: {{v.auldd_ttv_gula_darah||"-"}} mg/dL</li>
                            <li>Pernafasan: {{v.auldd_ttv_pernafasan||"-"}} x/mnt</li>
                            <li>SPO2: {{v.auldd_ttv_spo2||"-"}}%</li>
                            <li>Suhu: {{v.auldd_ttv_suhu||"-"}}C</li>
                            <li>Berat Badan {{v.auldd_ttv_weight||"-"}} kg</li>
                            <li>Tinggi: {{v.auldd_ttv_height||"-"}} cm</li>
                            <li>Lingkar Kepala: {{v.auldd_ttv_lingkar_kepala||"-"}} cm</li>
                            <li>Luas Permukaan Tubuh Anak: {{v.auldd_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                            <li>Kesadaran: <span>{{v.auldd_hasil_kesadaran}} | 
                        
                                <span v-if="v.auldd_hasil_kesadaran >= 14"> Composmentis</span> 
                                <span v-else-if="v.auldd_hasil_kesadaran >= 12 && v.auldd_hasil_kesadaran <= 13">Apatis</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 10 && v.auldd_hasil_kesadaran <= 11">Delirium</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 7 && v.auldd_hasil_kesadaran <= 9">Somonolen</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 5 && v.auldd_hasil_kesadaran <= 6">Sopor</span>
                                <span v-else-if="v.auldd_hasil_kesadaran >= 4 && v.auldd_hasil_kesadaran <= 4">Semi-Comma</span>
                                <span v-else>Coma</span>
                                
                                <span class="ml-2" style="margin-right:20px;">E: 
                                    {{v.auldd_hasil_kesadaran_e||0}} 
                                </span>
                                <span style="margin-right:20px;">M: 
                                    {{v.auldd_hasil_kesadaran_m||0}}  
                                </span>
                                <span style="margin-right:20px;">V: 
                                    {{v.auldd_hasil_kesadaran_v||0}}
                                </span>
                            </span>
                            </li>

                            </ul>
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Lab</span>
                            <template v-if="(v.auldd_hasil_lab||[]).length">
                            <ol class="mb-0" v-for="(v3,k3) in (v.auldd_hasil_lab||[])" :key="k3">
                                <li>{{v3.value}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                                    
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">Tindakan Radiologi</span>
                            <template v-if="(v.auldd_hasil_radiologi||[]).length">
                            <ol class="mb-0" v-for="(v4,k4) in (v.auldd_hasil_radiologi||[])" :key="k4">
                                <li>{{v4.value}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                                    
                        </div>
                        <div class="mb-2">
                            <span class="font-weight-semibold">R</span>
                            <div class="mb-1">
                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                            <template v-if="(v.auldd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                                    
                            

                            </div>
                            <div class="mb-1">
                            <span>Usulan kolaborasi</span>
                            <template v-if="(v.auldd_laporan_dpjp_usulan_kolaborasi||[]).length">
                            <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                <li>{{v}}</li>
                            </ol>
                            </template>
                            <p v-else> - </p>                                    

                            </div>
                        </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{v.auldd_laporan_dpjp_tanggal_evaluasi}} {{v.auldd_laporan_dpjp_waktu_evaluasi}}</span>
                    </td>
                    
                    <td>
                        <div class="form-group" 
                        v-for="(vU,kU) in (v.auldd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                            <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                            <pre class="pre-input mb-1">{{ v.auldd_laporan_dpjp_evaluasi_data[kU]['value']||'-' }}</pre>
                                
                            <span v-if="v.auldd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">Selesai pada {{v.auldd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                        </div>

                        <div id="verifikasiRanap">
                            <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                            
                            <div class="form-group">
                            <label for="">Nama DPJP</label>
                                <p>{{v.nama_dokter_ranap||"-"}}
                                </p>
                            </div>

                            <div class="form-group">
                            <label>Tanggal</label>
                                <p>{{v.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                            </div>
                            
                            <div class="form-group">
                            <label>Jam</label>
                                <p>{{v.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                            </div>
                        </div>
                    </td>
                    
                    <td>
                        <span>{{v.auldd_laporan_dpjp_ppa||"-"}}</span>
                    </td>
                </tr>
                </template>
                <tr class="table-info">
                    <td colspan="6"><span class="font-weight-semibold text-info-600">Catatan Asuhan Lainnya</span></td>
                </tr>
                </template>

                <tr v-for="(v,k) in (dataCatatanAsuhan.dataKajianDokter||[])" :key="k">
                    <td>
                    <span>{{v.aucad_tanggal | moment("DD MMMM YYYY")}}, {{v.aucad_jam}}</span>
                    </td>
                    <td>
                    <span>{{v.aucad_assesmen||"-"}}</span>
                    </td>
                    <td>
                    <span>{{v.aucad_tanggal_evaluasi | moment("DD MMMM YYYY")}}, {{v.aucad_jam_evaluasi||"-"}}</span>
                    </td>
                    <td>                            
                    <span>{{v.aucad_evaluasi||"-"}}</span>
                    </td>
                    <td>
                    <span>{{v.aucad_ppa||"-"}}</span>
                    </td>
                    <td>
                    <span v-if="v.aucad_is_active != 'Y'"> - </span>
                    <span v-else>Selesai pada {{v.aucad_done_at | moment("DD MMMM YYYY, HH:mm")}}</span>
                    </td>
                </tr>
                <tr v-if="!(dataCatatanAsuhan.dataKajianDokter||[]).length">
                    <td colspan="6" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
        </div>
        
        <template v-if="Object.keys(dataCatatanAsuhan||{}).length ">
        <div class="table-responsive">
            <table v-if="showIntervensiCA || (row.ap_usia == null && row.ap_dob == null && (dataCatatanAsuhan.dataKajianPerawat.aukprj_ed_value >= 90 || dataCatatanAsuhan.dataKajianPerawat.aukprj_humpty_value >= 12 || dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 25 || 
            getTotalSydneyCA >=6))" class="table table-bordered table-sm table-striped">
            <thead>
                <tr>
                    <td colspan="2" class="bg-indigo">
                    <span class="font-weight-semibold">Intervensi Risiko Jatuh</span>
                    </td>
                </tr>
                <tr>
                    <th width="50%">Tindakan</th>
                    <th width="50%">Evaluasi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang == 'Y'">
                    <td>
                        <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                        
                    <td>
                        <label class="mb-0">Terpasang gelang warna kuning pada</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_gelang_text || "-"}}</p>
                    </td>

                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">
                    <td>
                        <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                    <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">Pagar pengaman tempat tidur terpasang pada kedua sisi</span>
                    <span v-else> - </span>
                    </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y'">
                    <td>
                        <label class="mb-0">Mengunci roda tempat tidur</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                        <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_roda == 'Y'">Roda tempat tidur terkunci dan tidak mudah tergeser</span>
                        <span v-else> - </span>
                    </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y'">
                    <td>
                        <label class="mb-0">Memasang restrain</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                        <label class="mb-0">Terpasang restrain di</label>
                        <p v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain == 'Y'">{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_restrain_text||"-"}}</p>
                        <p v-else> - </p>
                    </td>
                </tr>
                <tr v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">
                    <td>
                        <label class="mb-0">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga</label>
                        <p>{{dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y' ? 'Ya':'Tidak'}}</p>
                    </td>
                    <td>
                        <span v-if="dataCatatanAsuhan.dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga</span>
                        <span v-else> - </span>
                    </td>
                </tr>
            </tbody>      
            </table>
        </div>
        </template>
    </b-modal>
    
  </div>
</template>


<script>

import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

import $ from 'jquery'
const _ = global._

import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD.vue'
import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD.vue'

export default {
  extends: GlobalVue,
  components: {KajianPerawatUGD,RiwayatRMUGD},
  props: {
    dataProps: Object,
    pasienId: Number 
  },
  data(){
    return {    
      pageNow: 1,
      openDetailKajianPerawatUGD: false,
      openRiwayatRMUGD: false,
      regID: null,

      dataKajianPerawat: {},
      dataKajianDokter: {},

      historiKajianPerawat: [],
      historiKajianDokter: [],

      masterKajianPerawat: {},
      masterKajianDokter: {},

      masterTriase: {},
      dataKajianTriase: {},

      dataCatatanAsuhan: {},
      openCatatanAsuhan: false,

      historiKajianRadiologi: [],
      historiKajianLab: [],
      historiKajianTriase: [],

      dataKajianRadiologi: {},
      dataKajianLab: {},

      dataKajianFarmasi: [],
      dataUGDReservasi: {}
    }
  },
  computed: {
      
    showIntervensiCA(){
      if(Object.keys(this.dataCatatanAsuhan||{}).length){
        if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_is_gangguan_jiwa == 'Y'){
          return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_ed_value >= 90
        }
        else{
          if(this.row.ap_usia <= 18){
              return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 12
          }else if(this.row.ap_usia > 18 && this.row.ap_usia <= 60){
              return this.dataCatatanAsuhan.dataKajianPerawat.aukprj_morshe_value >= 25
          }else if(this.row.ap_usia > 60){
              return this.getTotalSydney >=6
          }else{
              return false
          }
        }
      }
      return false
    },
    
    getTotalSydneyCA(){
        let total = 0
        if(Object.keys(this.dataCatatanAsuhan||{}).length){
          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh == "Y" || this.dataCatatanAsuhan.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh2bulan == "Y"){
              total = total + 6
          }

          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_dellirium == "Y" || this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_disorientasi == "Y" ||
          this.dataCatatanAsuhan.dataKajianPerawat.aukprj_status_mental_agitasi == "Y" ){
              total = total + 14
          }
          
          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_kacamata == "Y" || this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_buram == "Y" ||
          this.dataCatatanAsuhan.dataKajianPerawat.aukprj_penglihatan_glaucoma == "Y" ){
              total = total + 1
          }

          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_kebiasaan == "Y"){
              total = total + 2
          }

          if(this.dataCatatanAsuhan.dataKajianPerawat.aukprj_transfer + this.dataCatatanAsuhan.dataKajianPerawat.aukprj_mobilitas > 3){
              total = total + 7
          }
        }
        return total  
    },

    dataRow(){
      return (this.dataProps.data||[])
    },

    passToSubPerawatUGD(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataKajianPerawat: this.dataKajianPerawat,
        historiKajianPerawat: this.historiKajianPerawat,
        masterKajianPerawat: this.masterKajianPerawat,
      })
    },

    passToSubRMUGD(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataKajianDokter: this.dataKajianDokter,
        dataKajianTriase: this.dataKajianTriase,
        historiKajianDokter: this.historiKajianDokter,
        masterKajianDokter: this.masterKajianDokter,
        masterTriase: this.masterTriase,
        historiKajianRadiologi: this.historiKajianRadiologi,
        historiKajianLab: this.historiKajianLab,
        historiKajianTriase: this.historiKajianTriase,
        dataKajianRadiologi: this.dataKajianRadiologi,
        dataKajianLab: this.dataKajianLab,
        dataKajianFarmasi: this.dataKajianFarmasi,
        dataUGDReservasi: this.dataUGDReservasi
      })
    },
  },
  methods: {
    loadMoreUGD(){
        this.loadingOverlay = true
        this.pageNow = this.pageNow + 1
        Gen.apiRest(
            "/do/" + 'RanapPasienPerawatan', {
                data: {
                    type: "get-riwayat-ugd",
                    id: this.pasienId,
                    page: this.pageNow
                }
            },
            "POST"
        ).then(res => {
            this.loadingOverlay = false
            let newData = res.data.dataUGD.data
            for(let i = 0; i < (newData||[]).length; i++){
                this.dataProps.data.push(newData[i])        
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })
        // let url = 'UGDAssesment'+'/'+ this.pasienId + '?regId=' + this.dataRow[0]['aurm_aur_id']
        // let paramsQuery = this.$route.query
        // Gen.apiRest(
        //     "/get/"+url, 
        //     {
        //     params: Object.assign({page: this.pageNow}, paramsQuery)
        //     }
        // ).then(res=>{
        //     this.loadingOverlay = false
        //     let newData = res.data.dataUGD.data
        //     for(let i = 0; i < (newData||[]).length; i++){
        //         this.dataProps.data.push(newData[i])        
        //     }
        // }).catch(()=>{
        //     this.loadingOverlay = false
        // })
    },
    toCatatanAsuhanDetail(regId){
        this.loadingOverlay = true
        Gen.apiRest(
            "/get/"+"UGDCatatanAsuhan"+"/"+regId, 
        ).then(res=>{  
            this.loadingOverlay = false
            this.openCatatanAsuhan = true
            this.dataCatatanAsuhan = res.data
            this.regID = regId
        })
    },
    toDetailKajianPerawatUGD(v,regID){
        this.loadingOverlay = true
        Gen.apiRest(
            "/get/"+'UGDFormPerawat'+'/'+this.pasienId+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id, 
        ).then(res=>{
            this.openDetailKajianPerawatUGD = true    
            this.dataKajianPerawat = res.data
            this.loadingOverlay = false
        })

        Gen.apiRest(
            "/get/"+'UGDFormPerawat'+'/'+this.pasienId+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id+'&master=1', 
        ).then(res=>{
            this.masterKajianPerawat = res.data
        })
    },
    doOpenRiwayatRMUGD(v){
        this.loadingOverlay = true

        Gen.apiRest(
            "/get/"+'UGDFormDokter'+'/'+this.pasienId+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id, 
        ).then(res=>{
            this.openRiwayatRMUGD = true    
            this.dataKajianDokter = res.data
            this.rowReg = res.data.rowReg || {}
            this.row = res.data.row || {}
            this.loadingOverlay = false
            this.openRiwayatData = v
        })

        Gen.apiRest(
            "/get/"+'UGDFormDokter'+'/'+this.pasienId+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id+'&master=1', 
        ).then(res=>{
            this.masterKajianDokter = res.data
        })

        
        Gen.apiRest(
            "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aurm_aur_id, 
        ).then(res=>{
            this.dataKajianTriase = res.data
        })
        
        Gen.apiRest(
            "/get/"+'UGDTriase'+'/'+'master', 
        ).then(res=>{
            this.masterTriase = res.data
        })

        if(v.kajianLab){
            Gen.apiRest(
                "/get/"+'UGDFormLab'+'/'+this.pasienId+'/'+v.kajianLab+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.dataKajianLab = res.data
            })
        }

        if(v.kajianRadiologi){
            Gen.apiRest(
                "/get/"+'UGDFormRadiologi'+'/'+this.pasienId+'/'+v.kajianRadiologi+'?regId='+v.aurm_aur_id, 
            ).then(res=>{
                this.dataKajianRadiologi = res.data
            })
        }

        
        Gen.apiRest(
            "/do/"+'UGDAssesment',
            {data:{type:'get-riwayat-rekam-medis', aur_id: v.aurm_aur_id}}
        ).then(res=>{
            let resp = res.data
            this.historiKajianPerawat = resp.historyKajianPerawat
            this.historiKajianDokter = resp.historyKajianDokter
            this.historiKajianRadiologi = resp.historyKajianRadiologi
            this.historiKajianLab = resp.historyKajianLab
            this.historiKajianTriase = resp.historyKajianTriase
            this.loadingOverlay = false
        })
        

        Gen.apiRest(
            "/do/" + 'UGDAssesment', {
                data: {
                type: 'get-obat-farmasi',
                regId: v.aurm_aur_id
                }
            }
        ).then(res => {
            let resp = res.data
            this.dataKajianFarmasi = resp.data
            this.dataUGDReservasi = resp.dataUGDReservasi
            this.loadingOverlay = false
        })
        
        
        this.openRiwayatRMUGD = true
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
  },
}
</script>
