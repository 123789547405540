<template>
<div>
  <table class="table table-sm table-bordered mb-2">
    <tbody>
      <tr>
        <th class="bg-primary">Tanggal / Jam </th>
        <th class="bg-primary">Keadaan Umum </th>
        <th class="bg-primary">Mamae/Laktasi </th>
        <th class="bg-primary">Uterus </th>
        <th class="bg-primary">Lochea </th>
        <th class="bg-primary">DC </th>
        <th class="bg-primary">Lain-lain </th>
      </tr>
      <tr v-for="(v, k) in dataList" :key="`ln-${k}`">
        <td>{{ v.created_date | moment("LLL") }}</td>
        <td>{{ v.keadaan_umum }}{{ v.keadaan_umum == "Lain-lain" ? `: ${v.keadaan_umum_note}` : "" }}</td>
        <td>{{ v.laktasi }}{{ v.laktasi == "Lain-lain" ? `: ${v.laktasi_note}` : "" }}</td>
        <td>{{ v.uterus }}</td>
        <td>
          <div class="det-check-item">
            <i class="icon-checkmark-circle text-success"></i><span>{{ v.lochea }}</span>
          </div>
        </td>
        <td>
          <div class="det-check-item">
            <i class="icon-checkmark-circle text-success"></i><span>{{ v.dc}}{{ v.dc == "In" ? `: ${v.dc_note}` : "" }}</span>
          </div>
        </td>
        <td><span>{{ v.other }}</span></td>
      </tr>
    </tbody>
  </table>

  <div class="card">
    <div class="card-header bg_head_panel">
      <div class="row">
        <div class="col-md-4">
          <h6 class="card-title font-weight-semibold">Catatan KB</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <table class="table table-bordered table-sm">
        <tr>
          <td>
            <div class="result_tab">
              <h4>Jenis KB</h4>
              <p>{{ data.jenis_kb || '-' }}</p>
            </div>
          </td>
          <td>
            <div class="result_tab">
              <h4>Tanggal Pemasangan</h4>
              <p>{{ data.install_date | moment("LL") }}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data() {
    return {
      keadaanUmum: false,
      activeAnat: 0,
      openModalICDetail: false,
      rowIC: {}
    }
  },

  props: {
    data: Object,
    dataList: Array
  },

  computed: {
    // dataKajian() {
    //   return this.$parent.$parent.kajianData.kajianDokter
    // },
    // dataPenunjang() {
    //   return this.$parent.$parent.kajianData.kajianPenunjang
    // },
    Config() {
      return Config
    },
  },
  methods: {

    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
  }
}
</script>
