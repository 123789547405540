<template>
<div>
  <div class="mb-2">
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label for="">Data Kajian</label>
        <v-select @input="changeRiwayat($event)" placeholder="Pilih Data Kajian" v-model="data.selected_riwayat" :options="data.riwayat" label="text" :reduce="v=>v.value"></v-select>
      </div>
    </div>
  </div>
  
  <div class="mb-3">
    <table class="table table-bordered table-sm">
      <tr>
        <td width="50%">
          <div class="result_tab">
            <h4>Tgl Pengkajian</h4>
            <p>{{ data.kajian_date | moment("LL") }}</p>
          </div>
        </td>
        <td width="50%">
          <div class="result_tab">
            <h4>Jam</h4>
            <p>{{ data.kajian_time }}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="card">
    <div class="card-header bg_head_panel">
      <div class="row">
        <div class="col-md-4">
          <h6 class="card-title font-weight-semibold">Catatan BBL {{(data.catatan_bbl||[]).length ? '#1':''}}</h6>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-sm">
      <tr>
        <td>
          <div class="result_tab">
            <h4>Tanggal Lahir</h4>
            <p>{{ data.dob | moment("LL") }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Jam lahir</h4>
            <p>{{ data.tob }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Bayi Lahir</h4>
            <p class="text-capitalize">{{ (data.lahir||"").toLowerCase() }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Jenis Kelamin</h4>
            <p>{{ data.gender == "W" ? "Perempuan" :  data.gender == "P" ? "Laki-Laki": "Tidak Diketahui" }}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="result_tab">
            <h4>Berat Badan</h4>
            <p>{{ data.berat_badan }}gram</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Panjang Badan</h4>
            <p>{{ data.panjang_badan }}cm</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Lingkar Kepala</h4>
            <p>{{ data.lingkar_kepala }}cm</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Lingkar Dada</h4>
            <p>{{ data.lingkar_dada }}cm</p>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="result_tab">
            <h4>Kelainan Konginetal</h4>
            <p>{{ data.kelainan_konginetal == "Y" ? "Ya" : "Tidak" }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Untuk kelainan Jelek, Lahir Hidup kemudian Meninggal</h4>
            <p>{{ data.kelainan_jelek == "Y" ? "Ada" : "Tidak ada" }} {{ data.kelainan_jelek == "Y" ? `, ${data.kelainan_jelek_time||"-"} ${(data.kelainan_jelek_time_type||"").toLowerCase()}` : "" }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Bayi Lahir Mati</h4>
            <p>{{ data.lahir == "MATI" ? "Ya" : "Tidak" }}  {{ data.lahir == "MATI" ? `, ${data.bayi_lahir_mati_note}` : "" }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Bayi Lahir Kembar</h4>
            <p>{{ data.is_bayi_kembar == "Y" ? "Ya" : "Tidak" }} </p>
          </div>
        </td>
      </tr>
    </table>
  </div>
  
  <template v-if="(data.catatan_bbl||[]).length">
  <div class="card" v-for="(v,k) in (data.catatan_bbl||[])" :key="k">
    <div class="card-header bg_head_panel">
      <div class="row">
        <div class="col-md-4">
          <h6 class="card-title font-weight-semibold">Catatan BBL #{{k+2}}</h6>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-sm">
      <tr>
        <td>
          <div class="result_tab">
            <h4>Tanggal Lahir</h4>
            <p>{{ v.arancb_tanggal_lahir | moment("LL") }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Jam lahir</h4>
            <p>{{ v.arancb_jam_lahir }}:00</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Bayi Lahir</h4>
            <p class="text-capitalize">{{ (v.arancb_bayi_lahir||"").toLowerCase() }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Jenis Kelamin</h4>
            <p>
              {{ v.arancb_jenis_kelamin == "W" ? "Perempuan" :  v.arancb_jenis_kelamin == "P" ? "Laki-Laki": "Tidak Diketahui" }}
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="result_tab">
            <h4>Berat Badan</h4>
            <p>{{ v.arancb_bb }}gram</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Panjang Badan</h4>
            <p>{{ v.arancb_tb }}cm</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Lingkar Kepala</h4>
            <p>{{ v.arancb_lingkar_kepala }}cm</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Lingkar Dada</h4>
            <p>{{ v.arancb_lingkar_dada }}cm</p>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="result_tab">
            <h4>Kelainan Konginetal</h4>
            <p>{{ v.arancb_kelainan_konginetal == "Y" ? "Ya" : "Tidak" }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Untuk kelainan Jelek, Lahir Hidup kemudian Meninggal</h4>
            <p>{{ v.arancb_kelainan_jelek == "Y" ? "Ada" : "Tidak ada" }} {{ v.arancb_kelainan_jelek == "Y" ? `, ${v.arancb_kelainan_jelek_text||"-"} ${(v.arancb_kelainan_jelek_text_2||"").toLowerCase()}` : "" }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Bayi Lahir Mati</h4>
            <p>{{ v.arancb_bayi_lahir == "MATI" ? "Ya" : "Tidak" }}  {{ v.arancb_bayi_lahir == "MATI" ? `, ${v.arancb_sebab_kematian}` : "" }}</p>
          </div>
        </td>
        <td></td>
      </tr>
    </table>
  </div>
  </template>

  <div class="card">
    <div class="card-header bg_head_panel">
      <div class="row">
        <div class="col-md-4">
          <h6 class="card-title font-weight-semibold">Keadaan ibu Paska Persalinan </h6>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-sm">
      <tr>
        <td>
          <div class="result_tab">
            <h4>Keadaan Umum</h4>
            <p>{{ data.ki_keadaan_umum }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Tekanan Darah</h4>
            <p>{{ data.ki_sistolik }}/{{ data.ki_diastolik }}mmHG</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Nadi</h4>
            <p>{{ data.nadi }}x/mnt</p>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="result_tab">
            <h4>Suhu Badan</h4>
            <p>{{ data.suhu_badan }}&deg;C</p>
          </div>
        </td>
        <td colspan="2">
          <div class="result_tab">
            <h4>Uterus</h4>
            <p>{{ data.uterus }}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="result_tab">
            <h4>Lama Kala I</h4>
            <p>{{ data.lama_kala_1_jam || 0 }} Jam {{ data.lama_kala_1_menit || 0 }} Menit</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Lama Kala II</h4>
            <p>{{ data.lama_kala_2_jam || 0 }} Jam {{ data.lama_kala_2_menit || 0 }} Menit</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Perdarahan Kala III</h4>
            <p>{{ data.lama_kala_3_jam || 0 }} Jam {{ data.lama_kala_3_menit || 0 }} Menit</p>
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <div class="result_tab">
            <h4>Lama Persalinan</h4>
            <p>{{ data.lama_persalinan_jam || 0 }} Jam {{ data.lama_persalinan_menit || 0 }} Menit</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Komplikasi Persalinan</h4>
            <p>{{ data.komplikasi_persalinan||'-' }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Ketuban</h4>
            <p>{{ data.ketuban||'-' }}</p>
          </div>
        </td>
      </tr>
      
      <tr>
        <td>
          <div class="result_tab">
            <h4>Perdarahan Kala III</h4>
            <p>{{ data.perdarahan_kala_3||0 }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Perdarahan Kala IV</h4>
            <p>{{ data.perdarahan_kala_4||0 }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Kondisi Bayi</h4>
            <p>{{ data.p_kondisi_bayi||"-" }}</p>
          </div>
        </td>
      </tr>

    </table>
  </div>
  <div class="card">
    <div class="card-header bg_head_panel">
      <div class="row">
        <div class="col-md-4">
          <h6 class="card-title font-weight-semibold">Placenta</h6>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-sm">
      <tr>
        <td>
          <div class="result_tab">
            <h4>Bentuk</h4>
            <p>{{ data.p_bentuk }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Tali Pusat</h4>
            <p>{{ data.p_tali_pusat }}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Kulit Ketuban</h4>
            <p>{{ data.p_kulit_ketuban }}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
</template>

<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data() {
    return {
      keadaanUmum: false,
      activeAnat: 0,
      openModalICDetail: false,
      rowIC: {}
    }
  },
  props: {
    data: Object
  },
  computed: {
    // dataKajian() {
    //   return this.$parent.$parent.kajianData.kajianDokter
    // },
    // dataPenunjang() {
    //   return this.$parent.$parent.kajianData.kajianPenunjang
    // },
    Config() {
      return Config
    },
  },
  methods: {

    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    changeRiwayat(e){
      if((this.data.riwayat||[]).length > 1){
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/" + 'RanapPasienPerawatan', {
              data: {
                type: 'open-log-catatan-persalinan',
                regId: this.data.aranr_id,
                id_batch: e
              }
            }
        ).then(res => {
          let resp = res.data
          this.data = resp.data
          this.data.selected_riwayat = e
        })
      }
    }
  }
}
</script>
