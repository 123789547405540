<template>
  <div class="mt-2">
    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Identitas</h6>
      </div>
      <div class="card-body p-3">
        <table class="table table-bordered">
          <tr>
            <td width="20%">
              <div class="result_tab">
                <h4>Nama Bayi</h4>
                <p>{{dataKajian.row.ap_fullname||"-"}}</p>
              </div>
            </td>
            <td width="20%">
              <div class="result_tab">
                <h4>Tanggal Lahir</h4>
                <p>
                  <span v-if="dataKajian.row.ap_dob">{{dataKajian.row.ap_dob|moment('DD MMMM YYYY')}}</span>
                  <span v-else> - </span>
                </p>
              </div>
            </td>
            <td width="20%">
              <div class="result_tab">
                <h4>Jenis Kelamin</h4>
                <p>{{getConfigDynamic(Config.mr.StatusKelamin,dataKajian.row.ap_gender)||"-"}}</p>
              </div>
            </td>
          </tr>
          <tr>
            <td width="33%">
              <div class="result_tab">
                <h4>Nama Ayah</h4>
                <p>{{dataKajian.row.arpdo_ayah_nama||"-"}}</p>
              </div>
            </td>
            <td width="33%">
              <div class="result_tab">
                <h4>Anak Ke-</h4>
                <p>{{dataKajian.row.arankmb_anak_ke||"-"}}</p>
              </div>
            </td>
            <td width="33%">
              <div class="result_tab">
                <h4>Lahir dengan cara</h4>
                <p>{{dataKajian.row.arankmb_lahir_dengan_cara||"-"}}</p>
              </div>
            </td>
          </tr>
        </table>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-sm table-bordered mt-3">
            <thead>
                <tr>
                <th class="bg-success" colspan="2">Biodata Ibu</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Nama Ibu</td>
                <td>{{dataKajian.row.arpdo_ibu_nama||"-"}}</td>
                </tr>
                <tr>
                <td>Tanggal Lahir</td>
                <td>
                    <span v-if="dataKajian.row.arpdo_ibu_dob">
                    {{dataKajian.row.arpdo_ibu_dob | moment("DD MMMM YYYY")}}
                    </span>
                    <span v-else> - </span>
                </td>
                </tr>
                <tr>
                <td>Umur</td>
                <td>
                    <span v-if="dataKajian.row.arpdo_ibu_dob">
                    {{ dataKajian.row.arpdo_ibu_dob | moment("from", "now", true) }}
                    </span>
                    <span v-else> - </span>
                </td>
                
                </tr>
                <tr>
                <td>Suku/bangsa </td>
                <td>{{dataKajian.row.ibu_suku||"-"}}</td>
                </tr>
                <tr>
                <td>Agama </td>
                <td>{{dataKajian.row.ibu_agama||"-"}}
                <span v-if="dataKajian.row.ibu_agama == 'Lain-lain'">: {{dataKajian.row.arpdo_ibu_agama_text||"-"}}</span>
                </td>
                </tr>
                <tr>
                <td>Pendidikan </td>
                <td>{{dataKajian.row.ibu_pendidikan||"-"}}
                <span v-if="dataKajian.row.ibu_pendidikan == 'Lain-lain'">: {{dataKajian.row.arpdo_ibu_pendidikan_text||"-"}}</span>
                </td>
                
                </tr>
                <tr>
                <td>Pekerjaan</td>
                <td>{{dataKajian.row.ibu_pekerjaan||"-"}}
                <span v-if="dataKajian.row.ibu_pekerjaan == 'Lain-lain'">: {{dataKajian.row.arpdo_ibu_pekerjaan_text||"-"}}</span>
                </td>
                </tr>
                <tr>
                <td>Alamat </td>
                <td>{{dataKajian.row.arpdo_ibu_alamat_ktp||"-"}}</td>
                </tr>
                <tr>
                <td>Status Perkawinan </td>
                <td>{{getConfigDynamic(Config.mr.StatusPernikahan,dataKajian.row.arpdo_ibu_status_perkawinan)||"-"}}</td>
                </tr>
            </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-sm table-bordered mt-3">
            <thead>
                <tr>
                <th class="bg-primary" colspan="2">Biodata Ayah</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Nama Ayah</td>
                <td>{{dataKajian.row.arpdo_ayah_nama||"-"}}</td>
                </tr>
                <tr>
                <td>Tanggal Lahir</td>
                <td>
                    <span v-if="dataKajian.row.arpdo_ayah_dob">
                    {{dataKajian.row.arpdo_ayah_dob | moment("DD MMMM YYYY")}}
                    </span>
                    <span v-else> - </span>
                </td>
                </tr>
                <tr>
                <td>Umur</td>
                <td>
                    <span v-if="dataKajian.row.arpdo_ayah_dob">
                    {{ dataKajian.row.arpdo_ayah_dob | moment("from", "now", true) }}
                    </span>
                    <span v-else> - </span>
                </td>
                
                </tr>
                <tr>
                <td>Suku/bangsa </td>
                <td>{{dataKajian.row.ayah_suku||"-"}}</td>
                </tr>
                <tr>
                <td>Agama </td>
                <td>{{dataKajian.row.ayah_agama||"-"}}
                <span v-if="dataKajian.row.ayah_agama == 'Lain-lain'">: {{dataKajian.row.arpdo_ayah_agama_text||"-"}}</span>
                </td>
                </tr>
                <tr>
                <td>Pendidikan </td>
                <td>{{dataKajian.row.ayah_pendidikan||"-"}}
                <span v-if="dataKajian.row.ayah_pendidikan == 'Lain-lain'">: {{dataKajian.row.arpdo_ayah_pendidikan_text||"-"}}</span>
                </td>
                </tr>
                <tr>
                <td>Pekerjaan</td>
                <td>{{dataKajian.row.ayah_pekerjaan||"-"}}
                <span v-if="dataKajian.row.ayah_pekerjaan == 'Lain-lain'">: {{dataKajian.row.arpdo_ayah_pekerjaan_text||"-"}}</span>
                </td>
                </tr>
                <tr>
                <td>Alamat </td>
                <td>{{dataKajian.row.arpdo_ayah_alamat_ktp||"-"}}</td>
                </tr>
                <tr>
                <td>Status Perkawinan </td>
                <td>{{getConfigDynamic(Config.mr.StatusPernikahan,dataKajian.row.arpdo_ayah_status_perkawinan)||"-"}}</td>
                </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Riwayat Kesehatan</h6>
      </div>
      <div class="card-body p-3">
        <table class="table table-bordered table-sm tr">
          <td>
            <div class="result_tab">
              <h4>Riwayat Ibu</h4>
              <p v-if="dataKajian.row.arankmb_riwayat_ibu == 'Y'">Ada Riwayat Penyakit: {{dataKajian.row.arankmb_riwayat_ibu_text||"-"}}</p>
              <p v-else>Tidak ada Riwayat Penyakit</p>
            </div>
          </td>
        </table>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
      </div>
      <div class="card-body p-3">
        <div>
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="4">Status Praesens</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{getConfigDynamic(Config.mr.genderText,dataKajian.row.arankmb_jenis_kelamin)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Berat Badan</h4>
                    <p v-if="dataKajian.row.arankmb_berat_badan">{{dataKajian.row.arankmb_berat_badan||"-"}} gram</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Panjang</h4>
                    <p v-if="dataKajian.row.arankmb_panjang">{{dataKajian.row.arankmb_panjang||"-"}} cm</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Lingkar Kepala</h4>
                    <p v-if="dataKajian.row.arankmb_lingkar_kepala">{{dataKajian.row.arankmb_lingkar_kepala||"-"}} cm</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Lingkar Dada</h4>
                    <p v-if="dataKajian.row.arankmb_lingkar_dada">{{dataKajian.row.arankmb_lingkar_dada||"-"}} cm</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Nadi</h4>
                    <p v-if="dataKajian.row.arankmb_nadi">{{dataKajian.row.arankmb_nadi||"-"}}x/mnt</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>RR</h4>
                    <p v-if="dataKajian.row.arankmb_rr">{{dataKajian.row.arankmb_rr||"-"}}x/mnt</p>
                    <p v-else> - </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Suhu Badan</h4>
                    <p v-if="dataKajian.row.arankmb_suhu">{{dataKajian.row.arankmb_suhu||"-"}}&deg;C</p>
                    <p v-else> - </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="4">Keadaan Umum</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Kesan</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisKesan,dataKajian.row.arankmb_kesan)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Warna</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisWarna,dataKajian.row.arankmb_warna)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Pernafasan</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisNafas,dataKajian.row.arankmb_pernafasan)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Tonus</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisTonus,dataKajian.row.arankmb_tonus)||"-"}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Pemeriksaan Khusus</h6>
      </div>
      <div class="card-body p-3">
        <div>
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="4">Kepala</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Bentuk</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLBentuk,dataKajian.row.arankmb_kepala_bentuk)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Telinga</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLTelinga,dataKajian.row.arankmb_telinga)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Suturae</h4>
                    <p>{{getConfigDynamic(Config.mr.mNormalTidakNormalText,dataKajian.row.arankmb_suturae_text)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Hidung</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLHidung,dataKajian.row.arankmb_hidung)||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Fontanel</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLFontanel,dataKajian.row.arankmb_fontanel)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Mulut</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLMulut,dataKajian.row.arankmb_mulut)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Caput Succedaneum</h4>
                    <p>{{getConfigDynamic(Config.mr.mAdaTidakAdaText,dataKajian.row.arankmb_caput)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Bibir</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLBibir,dataKajian.row.arankmb_bibir)||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Septal Hematoma</h4>
                    <p>{{getConfigDynamic(Config.mr.mAdaTidakAdaText,dataKajian.row.arankmb_septal_hematoma)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Lidah</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLLidah,dataKajian.row.arankmb_lidah)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Mata</h4>
                    <p>{{getConfigDynamic(Config.mr.mAdaTidakAdaText,dataKajian.row.arankmb_mata)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Palatum</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLBibir,dataKajian.row.arankmb_palatum)||"-"}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="4">Leher</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Bentuk</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLLeher,dataKajian.row.arankmb_bentuk_leher)||"-"}}</p>
                  </div>
                </td>
                <td width="25%"></td>
                <td width="25%"></td>
                <td width="25%"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3">
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="4">Dada</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Pemeriksaan</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLPemeriksaan,dataKajian.row.arankmb_dada_pemeriksaan)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Paru-paru</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLParu,dataKajian.row.arankmb_dada_paru)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Jantung</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLJantung,dataKajian.row.arankmb_dada_jantung)||"-"}}</p>
                  </div>
                </td>
                <td width="25%">
                  <div class="result_tab">
                    <h4>Perut</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLPerut,dataKajian.row.arankmb_dada_perut)||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Tali Pusat</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBaikLayu,dataKajian.row.arankmb_dada_tali_pusat)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Anus Rectum</h4>
                    <p>{{getConfigDynamic(Config.mr.mAnusLubang,dataKajian.row.arankmb_dada_anus_rectum)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Anggota Gerak</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapAnggotaGerak,dataKajian.row.arankmb_dada_anggota_gerak)||"-"}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Bibir</h4>
                    <p>{{getConfigDynamic(Config.mr.mRanapBBLBibir,dataKajian.row.arankmb_bibir)||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <div class="result_tab">
                    <h4>Kelainan lain-lain</h4>
                    <p>{{dataKajian.row.arankmb_dada_kelainan_lain||"-"}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="card border shadow-0 mb-0">
          <div class="bg-light card-header py-2">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>Item ICD-10 dipilih</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (dataKajian.dataICD10||[])" :key="k">
                <td><span>{{v.mi10_name||"-"}} <span
                      class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
              </tr>
              <tr v-if="!(dataKajian.dataICD10||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
            
            <template v-if="dataKajian.row.arankmb_diagnosa_text">
            <thead>
              <tr>
                <th>Diagnosa Lainnya</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dataKajian.row.arankmb_diagnosa_text||"-"}}</td>
              </tr>
            </tbody>
            </template>
          </table>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card border shadow-0 mb-0">
          <div class="bg-light card-header py-2">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>Item ICD-9-CM dipilih</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (dataKajian.dataICD9||[])" :key="k">
                <td><span>{{v.mi9_name||"-"}} <span
                  class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                <td>
                  <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.arankai9d_informed_consent"
                    class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                    <i class="icon-file-eye2"></i>
                  </a>
                  <span v-else> - </span>
                </td>
              </tr>
              <tr v-if="!(dataKajian.dataICD9||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
            <template v-if="dataKajian.row.arankmb_tindakan_text">
            <thead>
              <tr>
                <th colspan="2">Tindakan Lainnya</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dataKajian.row.arankmb_tindakan_text||"-"}}</td>
                <td>
                  <a href="javascript:;"  @click="openInformConsentDetailForm()" v-if="dataKajian.row.arankmb_inform_consent_id"
                    class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                    <i class="icon-file-eye2"></i>
                  </a>
                  <span v-else> - </span>
                </td>
              </tr>
            </tbody>
            </template>
          </table>
        </div>
      </div>
    </div>
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>
  </div>
</template>


<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data(){
    return {
      keadaanUmum: false,
      activeAnat: 0,  
      openModalICDetail: false,
      rowIC: {}
    }
  },
  computed: {
    dataKajian(){
      return this.$parent.$parent.kajianData.kajianDokter
    },
    dataPenunjang(){
      return this.$parent.$parent.kajianData.kajianPenunjang
    },
    Config(){ return Config },
  },
  methods: {
    openInformConsentDetail(v){
        Gen.apiRest('/do/' + 'RanapKAMedis', {
            data: {
                type: 'get-data-ic',
                id: v.arankai9d_informed_consent,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.arankai9d_id
            this.openModalICDetail = true                   
        })
    },
    openInformConsentDetailForm(){
        Gen.apiRest('/do/' + 'RanapKAMedis', {
            data: {
                type: 'get-data-ic',
                id: this.dataKajian.row.arankmb_inform_consent_id,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.mi9_name = this.dataKajian.row.arankmb_tindakan_text
            this.rowIC.mi9_code = ''
            this.rowIC.id_assesment = this.dataKajian.row.arankmb_id
            this.openModalICDetail = true                   
        })
    },

    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
  }
}
</script>