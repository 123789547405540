<template>
<div>
  <table class="table table-sm table-bordered">
    <tbody>
      <tr>
        <th class="bg-primary">Tanggal & Jam </th>
        <th class="bg-primary">Tensi </th>
        <th class="bg-primary">Nadi </th>
        <th class="bg-primary">Suhu </th>
        <th class="bg-primary">HIS </th>
        <th class="bg-primary">DJJ </th>
        <th class="bg-primary">VT </th>
      </tr>
      <tr v-for="(v, k) in data" :key="`lp-${k}`">
        <td>{{ v.date | moment("LLL") }}</td>
        <td>{{ v.sistolik }}/{{ v.diastolik }}mmHG</td>
        <td>{{ v.nadi }}x/mnt</td>
        <td>{{ v.suhu }}&deg;C</td>
        <td>{{ v.his }}</td>
        <td>{{ v.djj }}</td>
        <td>{{ v.vt }}</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data() {
    return {
      keadaanUmum: false,
      activeAnat: 0,
      openModalICDetail: false,
      rowIC: {}
    }
  },

  props: {
    data: [Object, Array]
  },
  computed: {
    // dataKajian() {
    //   return this.$parent.$parent.kajianData.kajianDokter
    // },
    // dataPenunjang() {
    //   return this.$parent.$parent.kajianData.kajianPenunjang
    // },
    Config() {
      return Config
    },
  },
  methods: {

    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
  }
}
</script>
