<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12">
        <table class="table table-bordered table-striped table-hover table-sm patient-table">
            <thead>
                <tr>
                    <th>No. Reg</th>
                    <th>Tanggal Reg</th>
                    <th>Nama Dokter</th>
                    <th>Keluhan</th>
                    <th>Kajian</th>
                    <th>Riwayat RM</th>
                </tr>
            </thead>
            <template v-if="(dataRow||[]).length">
                <template v-for="(v,k) in (dataRow||[])" >
                    <tbody :class="v.isHead? 'isHead': ''" :key="k">
                        <tr :class="v.arm_is_draft == 'Y' ? 'table-warning' : ''">
                            <td>
                                <span>{{v.ar_reg_code||"-"}}</span>
                                
                                <template v-if="v.arm_is_draft == 'Y'">
                                    <span :class="`badge bg-warning ml-1`">
                                    Draft
                                    </span><br/>
                                    <small>{{v.petugas||"-"}}</small>
                                </template>
                            </td>
                            <td>
                                <span v-if="v.ar_reg_date">{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                                <span v-else>{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                            </td>
                            <td>
                                <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                            </td>

                            <td>
                                <span>{{v.keluhan||"-"}}</span>
                            </td>
                            
                            <td>
                                <a href="javascript:;" v-if="v.kajianPerawatId" @click="toDetail(v,v.arm_ar_id)" class="btn btn-sm btn-icon" :class="v.arm_is_kajian_awal == 'Y' ? 'alpha-blue border-blue' : 'alpha-green border-green'" v-b-tooltip.hover="'Lihat Kajian '+(v.arm_is_kajian_awal == 'Y' ? 'Awal' : 'Lanjutan')"><i class="icon-file-eye"></i></a>
                                <span v-else>-</span>
                            </td>
                            
                            
                            <td><a href="javascript:;" v-if="(v.kajianDokter || v.kajianDokterTerapi || v.kajianGizi || v.kajianFisio || v.kajianLab || v.kajianRadiologi )" @click="doOpenRiwayatRM(v)" v-b-tooltip.hover="'Lihat Riwayat Rekam Medis'" class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
                            <span v-else>-</span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </template>
            <tbody v-else-if="!(dataRow||[]).length && dataRow">
                <tr>
                    <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                        Data Tidak Ditemukan
                    </th>
                </tr>      
            </tbody>
            <tbody v-else>
                <tr>
                    <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                        <div class="skeletal-comp mb-2"></div>
                        <div class="skeletal-comp mb-2"></div>
                        <div class="skeletal-comp"></div>
                    </th>
                </tr>
            </tbody>
            <tfoot v-if="(dataRow||[]).length && pageNow < dataProps.last_page">
                <tr>
                    <td class="text-center" colspan="99">
                        <a href="javascript:;" @click="loadMore" class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
                    </td>
                </tr>
            </tfoot>
        </table>
      </div>
    </div>

    <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
        <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
        <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
    </b-modal>
    
        
    <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
        <RiwayatRM v-bind="passToSubRM" />
    </b-modal>
  </div>
</template>


<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

import DetailKajianAwal from '@/components/RiwayatPOLI/DetailKajianAwal.vue'
import DetailKajianLanjutan from '@/components/RiwayatPOLI/DetailKajianLanjutan.vue'
import RiwayatRM from '@/components/RiwayatPOLI/RiwayatRM.vue'

import $ from 'jquery'
const _ = global._

export default {
  extends: GlobalVue,
  components: {DetailKajianAwal,DetailKajianLanjutan,RiwayatRM},
  props: {
    dataProps: Object,
    pasienId: Number 
  },
  data(){
    return {    
      pageNow: 1,

      mPekerjaan: [],
      mAlatBantu: [],
      mSkrininGizi: [],
      mSkrininGiziV2: [],
      diagnosa: [],
      mSDKI: [],
      mSLKI: [],
      mSIKI: [],
      mPoli: [],
      mDokter: [],
      mKesadaran: [],
      mICD9: [],
      mICD10: [],
      mRadiologi: [],
      mLab: [],
      riwayatObat: [],
      resepDokter: [],
      mObatPreferensi: [],
      mDosis: [],
      
      openDetailKajian: false,
      openDetailKajianPerawat: false,
      openRwiayatRM: false,
      dataRiwayatRMDokter: {},
      dataRiwayatRMDokterTerapi: {},
      dataRiwayatRMDokterGizi: {},
      dataRiwayatRMFisio: {},
      dataRiwayatRMRadiologi: {},
      dataRiwyatRMPerawat: {},
      dataRiwayatRMLab: {},
      dataRiwayatRMFarmasi: [],
      dataRiwayatRMFollowUp: {},

      dataRiwyatRMPerawatIS: '',

      statusElektroterapi: [],
      statusMekanoTerapi: [],
      statusLatihan: [],
      mAlatKesehatan: [],
      mRacikan: [],

      historyKajianPerawat: [],
      historyKajianDokter: [],
      historyKajianDokterTerapi: [],
      historyKajianDokterGizi: [],
      historyKajianFisio: [],
      historyKajianRadiologi: [],
      historyKajianLab: [],
    }
  },
  computed: {
    dataRow(){
      return (this.dataProps.data||[])
    },

    passToSub(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mPekerjaan: this.mPekerjaan,
        mAlatBantu: this.mAlatBantu,
        mSkrininGizi: this.mSkrininGizi,
        mSkrininGiziV2: this.mSkrininGiziV2,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSLKI: this.mSLKI,
        mSIKI: this.mSIKI,
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat,
        resepDokter: this.resepDokter,
        mAlergi: this.mAlergi,
      })
    },
    passToSubLanjutan(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSIKI: this.mSIKI,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat,
        resepDokter: this.resepDokter,
        mAlergi: this.mAlergi,
      })
    },
    
    passToSubRM(){
      return _.assign({
        dataRiwayatRMDokter: this.dataRiwayatRMDokter,
        dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
        dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
        dataRiwayatRMFisio: this.dataRiwayatRMFisio,
        dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
        dataRiwayatRMLab: this.dataRiwayatRMLab,
        dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,
        row: this.row,
        rowReg: this.rowReg,
        dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
        historyKajianPerawat: this.historyKajianPerawat,
        historyKajianDokter: this.historyKajianDokter,
        historyKajianDokterTerapi: this.historyKajianDokterTerapi,
        historyKajianDokterGizi: this.historyKajianDokterGizi,
        historyKajianFisio: this.historyKajianFisio,
        historyKajianRadiologi: this.historyKajianRadiologi,
        historyKajianLab: this.historyKajianLab,


      })
    },

  },
  methods: {
    loadMore(){
        this.loadingOverlay = true
        this.pageNow = this.pageNow + 1
        Gen.apiRest(
            "/do/" + 'RanapPasienPerawatan', {
                data: {
                    type: "get-riwayat-poli",
                    id: this.pasienId,
                    page: this.pageNow
                }
            },
            "POST"
        ).then(res => {
            this.loadingOverlay = false
            let newData = res.data.dataPoli.data
            for(let i = 0; i < (newData||[]).length; i++){
                this.dataProps.data.push(newData[i])        
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })
    },
    toDetail(v,ar_id){
        this.loadingOverlay = true
        Gen.apiRest(
            "/get/"+'RekamMedis'+'/'+this.pasienId+'/'+v.kajianPerawatId+'?regId='+ar_id, 
        ).then(res=>{
            this.loadingOverlay = false
            this.openDetailKajian = true
            console.log(res.data)
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
    },
    doOpenRiwayatRM(v){
        this.loadingOverlay = true
        if(v.kajianDokter){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.pasienId+'/'+v.kajianDokter+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMDokter = res.data
                this.dataRiwayatRMDokter.Config = this.Config
                this.openRwiayatRM = true
                this.rowReg = res.data.rowReg
            })
        }else{
            this.dataRiwayatRMDokter = {}
        }

        if(v.kajianDokterTerapi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.pasienId+'/'+v.kajianDokterTerapi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMDokterTerapi = res.data
                this.dataRiwayatRMDokterTerapi.Config = this.Config
                this.openRwiayatRM = true
                this.rowReg = res.data.rowReg
            })
        }else{
            this.dataRiwayatRMDokterTerapi = {}
        }

        if(v.kajianGizi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.pasienId+'/'+v.kajianGizi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMDokterGizi = res.data
                this.dataRiwayatRMDokterGizi.Config = this.Config
                this.openRwiayatRM = true
                this.rowReg = res.data.rowReg
            })
        }else{
            this.dataRiwayatRMDokterGizi = {}
        }

        if(v.kajianFisio){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.pasienId+'/'+v.kajianFisio+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMFisio = res.data
                this.dataRiwayatRMFisio.Config = this.Config
                this.openRwiayatRM = true
                this.rowReg = res.data.rowReg
            })
        }else{
            this.dataRiwayatRMFisio = {}
        }

        if(v.kajianRadiologi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.pasienId+'/'+v.kajianRadiologi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMRadiologi = res.data
                this.dataRiwayatRMRadiologi.Config = this.Config
                this.openRwiayatRM = true
                this.rowReg = res.data.rowReg
            })
        }else{
            this.dataRiwayatRMRadiologi = {}
        }

        if(v.kajianLab){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.pasienId+'/'+v.kajianLab+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMLab = res.data
                this.dataRiwayatRMLab.Config = this.Config
                this.openRwiayatRM = true
                this.rowReg = res.data.rowReg
            })
        }else{
            this.dataRiwayatRMLab = {}
        }

        Gen.apiRest(
            "/do/"+'RekamMedis',
            {data:{type:'get-obat-farmasi', regId: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data
            this.dataRiwayatRMFarmasi = resp.data
            this.loadingOverlay = false
        })

        Gen.apiRest(
            "/do/"+'RekamMedis',
            {data:{type:'get-riwayat-rekam-medis', ar_id: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data

            this.historyKajianPerawat = resp.historyKajianPerawat
            this.historyKajianDokter = resp.historyKajianDokter
            this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
            this.historyKajianDokterGizi = resp.historyKajianDokterGizi
            this.historyKajianFisio = resp.historyKajianFisio
            this.historyKajianRadiologi = resp.historyKajianRadiologi
            this.historyKajianLab = resp.historyKajianLab

            this.loadingOverlay = false
        })

        
        Gen.apiRest(
            "/get/"+'FollowUp'+'/'+this.pasienId+'?regId='+v.arm_ar_id, 
        ).then(res=>{
            this.loadingOverlay = false
            this.dataRiwayatRMFollowUp = res.data
            this.dataRiwayatRMFollowUp.Config = this.Config
            this.openRwiayatRM = true
        })



        setTimeout(()=>{
            this.loadingOverlay = false
            this.openRwiayatRM = true
        },2000)
    },
  },

}
</script>