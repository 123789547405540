<template>
  <div class="page-header page-header-sticky" :class="{'page-header-sticky-open': isOpen}" v-if="!loading">    
    <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
      <div class="page-header-info text-uppercase flex-1">
        <div class="d-flex align-items-lg-center justify-content-between flex-column flex-lg-row">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="phi-main font-weight-semibold mb-2 pb-1 d-flex">
                <div class="d-flex flex-column">
                  <small>Nama</small>
                  <span>{{ patientData.ap_fullname }}</span>
                </div>
                <div title="No Rekam Medis" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>No. Rekam Medis</small>
                  <span>{{ patientData.ap_code||"-" }}</span>
                </div>
                <div title="No Registrasi" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>No. Registrasi</small>
                  <span>{{ patientData.aranr_reg_code||"-" }}</span>
                </div>
                <div title="No KTP" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>NIK</small>
                  <span>{{ patientData.ap_nik||"-" }}</span>
                </div>
                <div title="Ruangan & Kamar" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>Ruangan & Kamar</small>
                  <span>{{ patientData.mranb_name }} - {{ patientData.mrankel_name }}</span>
                </div>
              </h6>
            </div>
            <div class="phi-sub-info d-flex text-uppercase">
              <div class="phi-info d-flex flex-column">
                <small>Tanggal Lahir</small>
                <strong class="font-weight-semibold">{{ patientData.ap_dob | moment("DD MMM YYYY") }}</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Usia</small>
                <strong v-if="patientData.ap_usia_with_ket" class="font-weight-semibold">
                  {{patientData.ap_usia_with_ket}}<br />({{patientData.ap_gol_usia}})
                </strong>
                <span v-else> - </span>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Jenis Kelamin</small>
                <strong class="font-weight-semibold">{{ patientData.cg_label }}</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Opsi Pembayaran</small>
                <strong class="font-weight-semibold">{{ patientData.mcp_name||"-" }}</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Tgl. Masuk Rawat Inap</small>
                <strong class="font-weight-semibold">{{ patientData.aranr_reg_date | moment("DD MMM YYYY") }}</strong>
              </div>
              
              <div v-if="patientData.aranr_jenis_ranap == 2" class="phi-info d-flex flex-column pl-2 ml-3">
                <small>BB Bayi</small>
                <strong v-if="patientData.bbl" class="font-weight-semibold">{{patientData.bbl||"-"}} gram</strong>
                <span v-else> - </span>
              </div>
              
              <div v-else class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Berat Badan</small>
                <strong v-if="patientData.bb" class="font-weight-semibold">{{patientData.bb||"-"}} kg</strong>
                <span v-else> - </span>
              </div>
              
              
            </div>
          </div>
          <div class="d-flex flex-lg-column justify-content-center justify-content-lg-start mt-2 mt-lg-0" v-if="useHistoryData">
            <!-- Lihat kajian lanjutan perawat -->
            <a href="javascript:;" @click="doOpenKajianAwal()" data-toggle="modal" data-target="#modalKajianAwalRanap" class="btn btn-outline-light">Lihat Kajian Awal</a>
            <!-- Lihat Riwayat Rekam medis -->
            <a href="javascript:;" @click="doOpenRiwayat()" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light btn-outline-light mt-lg-1 ml-1 ml-lg-0">Lihat Riwayat Rekam Medis</a>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle" @click="clickInfo()">
      <template v-if="isOpen">
        <i class="icon-arrow-up5 align-middle"></i>
        <span class="align-middle">Sembunyikan Informasi Pasien</span>
      </template>

      <template v-else>
        <i class="icon-arrow-down5 align-middle"></i>
        <span class="align-middle">Tampilkan Informasi Pasien</span>
      </template>
    </a>

    <b-modal v-model="isOpenModalKajian" :title="'Kajian Awal Rawat Inap'" size="xl" ok-only ok-title="Tutup">
      <b-tabs nav-class="nav-tabs-bottom nav-justified mb-0" v-model="tabKajianAwal">
        <KajianAwal :kajianData="kajianData" :tab.sync="tabKajianAwal" />

        <KajianPersalinan :kajianData="kajianPersalinanData"  />

        <CriteriaDischargePlanning :data-criteria="kajianData.dischargePlanningCriteria" v-if="kajianData.dischargePlanningCriteria" />
      </b-tabs>
    </b-modal>

    
    <b-modal v-model="isOpenModalHistory" :title="'Riwayat Rekam Medis'" size="xl" ok-only ok-title="Tutup">
      <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
        <b-tab title="UGD">
          <HistoryUGD :dataProps="dataUGD" :pasienId="patientData.ap_id" />
        </b-tab>
        <b-tab title="Rawat Jalan">
          <HistoryRawatJalan :dataProps="dataPoli" :pasienId="patientData.ap_id" />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>

  <b-overlay :show="loading" v-else>
    <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
      <div class="page-header-info text-uppercase flex-1 py-3">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="phi-main font-weight-semibold mb-2 pb-1 d-flex">
                <div class="d-flex flex-column">
                  <small>Nama</small>
                  <span></span>
                </div>
                <div title="No Rekam Medis" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>No. Rekam Medis</small>
                  <span></span>
                </div>
                <div title="No Registrasi" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>No. Registrasi</small>
                  <span></span>
                </div>
                <div title="No KTP" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>NIK</small>
                  <span></span>
                </div>
                <div title="Ruangan & Kamar" class="d-flex flex-column ml-2 pl-2 border-left">
                  <small>Ruangan & Kamar</small>
                  <span></span>
                </div>
              </h6>
            </div>
            <div class="phi-sub-info d-flex text-uppercase">
              <div class="phi-info d-flex flex-column">
                <small>Tanggal Lahir</small>
                <strong class="font-weight-semibold"></strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Usia</small>
                <strong class="font-weight-semibold"><br />(Dewasa)</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Jenis Kelamin</small>
                <strong class="font-weight-semibold"></strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Opsi Pembayaran</small>
                <strong class="font-weight-semibold"></strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Tgl. Masuk Rawat Inap</small>
                <strong class="font-weight-semibold"></strong>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center justify-content-lg-start">
            <!-- Lihat kajian lanjutan perawat -->
            <a href="javascript:;" data-toggle="modal" data-target="#modalKajianAwalRanap" class="btn btn-outline-light">Lihat Kajian Awal</a>
            <!-- Lihat Riwayat Rekam medis -->
            <a href="javascript:;" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-lg-1 ml-1 ml-lg-0">Lihat Riwayat Rekam Medis</a>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>

  
</template>

<script>
import Gen from '@/libs/Gen.js'
import KajianAwal from '@/components/Ranap/KajianAwal.vue'
import CriteriaDischargePlanning from '@/components/Ranap/CriteriaDischargePlanning.vue'
import KajianPersalinan from '@/components/Ranap/KajianPersalinan.vue'
import HistoryUGD from '@/components/Ranap/HistoryUGD.vue'
import HistoryRawatJalan from '@/components/Ranap/HistoryRawatJalan.vue'

export default {
  data(){
    return {
      isOpen: true,
      isOpenModalKajian: false,
      kajianData: {},
      kajianPersalinanData: {},
      tabKajianAwal: 0,
      isOpenModalHistory: false,
      dataPoli: {},
      dataUGD: {}
    }
  },
  props: {
    patientData: Object,
    loading: Boolean,
    useHistoryData: Boolean,
    regId: {
      type: [String, Number],
      default: null
    },
  },

  components:{KajianAwal, KajianPersalinan, HistoryUGD, HistoryRawatJalan, CriteriaDischargePlanning},

  methods: {
    clickInfo(){
      this.$set(this, 'isOpen', !this.isOpen)
    },
    
    getKajianPersalinanData(){
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian-persalinan",
            regId: this.regId
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        if(!resp.isFound){
          return this.$swal({
            icon: 'error',
            title: 'Data Kajian persalinan Tidak Ditemukan'
          })
        }
        this.kajianPersalinanData = resp.data
      })
    },

    doOpenKajianAwal(){
      this.getKajianPersalinanData()

      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian",
            regId: this.regId
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        if(!resp.isFound){
          return this.$swal({
            icon: 'error',
            title: 'Data Kajian awal Tidak Ditemukan'
          })
        }
        this.isOpenModalKajian = true
        this.kajianData = resp.data
      })
    },

    doOpenRiwayat(){
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-riwayat-poli",
            id: this.patientData.ap_id
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.dataPoli = resp.dataPoli
      })

      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-riwayat-ugd",
            id: this.patientData.ap_id
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.isOpenModalHistory = true
        this.dataUGD = resp.dataUGD
      })

    }
  }
}
</script>

<style scoped>
.page-header-info{
  padding: 1rem 0;
}
@media screen and (max-width: 991px) {
  .page-header.page-header-sticky{
    top: -150px;
  }
  .page-header-info{
    padding-bottom: 2.5rem;
  }
  .page-header.page-header-sticky.page-header-sticky-open{ top: 64px; }
}
</style>