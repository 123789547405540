<template>  
  <div>
    <b-tab title="Kajian Awal Perawatan" v-if="Object.keys(kajianData.kajianPerawat||{}).length">
      <div class="row mt-2">
        <div class="col-md-6 mb-1">
        <label for="">Data Kajian</label>
        <v-select @input="changeKajianPerawat($event)" placeholder="Pilih Data Kajian" v-model="kajianData.kajianPerawat.row.aranrm_id" :options="kajianData.historyKajianPerawat" label="text" :reduce="v=>v.value"></v-select>
        </div>
      </div>       

      <KajianAwalPerawatUmum v-if="kajianData.kajianPerawat.type == 'UMUM'"/>
      <KajianAwalPerawatAnak v-if="kajianData.kajianPerawat.type == 'ANAK'"/>
      <KajianAwalPerawatObsgyn v-if="kajianData.kajianPerawat.type == 'OBSG'"/>
      <KajianAwalPerawatICU v-if="kajianData.kajianPerawat.type == 'ICU'"/>
      <KajianAwalPerawatNICU v-if="kajianData.kajianPerawat.type == 'NICU'"/>
      <KajianAwalPerawatPICU v-if="kajianData.kajianPerawat.type == 'PICU'"/>
      <KajianAwalPerawatBBL v-if="kajianData.kajianPerawat.type == 'PER'"/>
    </b-tab>
    <b-tab title="Kajian Awal Medis" v-if="Object.keys(kajianData.kajianDokter||{}).length">
      <div class="row mt-2">
        <div class="col-md-6 mb-1">
        <label for="">Data Kajian</label>
        <v-select @input="changeKajianDokter($event)" placeholder="Pilih Data Kajian" v-model="kajianData.kajianDokter.row.aranrm_id" :options="kajianData.historyKajianDokter" label="text" :reduce="v=>v.value"></v-select>
        </div>
      </div>       

      <KajianAwalMedis v-if="kajianData.kajianDokter.type == 'MEDIS'"/>
      <KajianAwalMedisBBL v-if="kajianData.kajianDokter.type == 'PER'"/>
    </b-tab>
    <b-tab title="Kajian Awal Dietisien" v-if="Object.keys(kajianData.kajianGizi||{}).length">
      <div class="row mt-2">
        <div class="col-md-6 mb-1">
        <label for="">Data Kajian</label>
        <v-select @input="changeKajianGizi($event)" placeholder="Pilih Data Kajian" v-model="kajianData.kajianGizi.row.aranrm_id" :options="kajianData.historyKajianGizi" label="text" :reduce="v=>v.value"></v-select>
        </div>
      </div> 

      <KajianAwalGizi/>
    </b-tab>
  </div>
</template>

<script>
import KajianAwalPerawatUmum from '@/components/Ranap/KajianAwalCmp/KajianAwalPerawatUmum.vue'
import KajianAwalPerawatAnak from '@/components/Ranap/KajianAwalCmp/KajianAwalPerawatAnak.vue'
import KajianAwalPerawatObsgyn from '@/components/Ranap/KajianAwalCmp/KajianAwalPerawatObsgyn.vue'
import KajianAwalPerawatNICU from '@/components/Ranap/KajianAwalCmp/KajianAwalPerawatNICU.vue'
import KajianAwalPerawatPICU from '@/components/Ranap/KajianAwalCmp/KajianAwalPerawatPICU.vue'
import KajianAwalPerawatICU from '@/components/Ranap/KajianAwalCmp/KajianAwalPerawatICU.vue'
import KajianAwalPerawatBBL from '@/components/Ranap/KajianAwalCmp/KajianAwalPerawatBBL.vue'
import Gen from '@/libs/Gen.js'
import KajianAwalMedis from '@/components/Ranap/KajianAwalCmp/KajianAwalMedis.vue'
import KajianAwalMedisBBL from '@/components/Ranap/KajianAwalCmp/KajianAwalMedisBBL.vue'
import KajianAwalGizi from '@/components/Ranap/KajianAwalCmp/KajianAwalGizi.vue'

export default {
  data(){
    return {
      idKajian: null,
      activeTab: 1,
      activeTabPerawat: 1,      
    }
  },
  props: {
    kajianData: Object,
    tab: Number
  },
  computed:{
    loadingOverlay:{
      get(){ return this.$root.var.loadingOverlay },
      set(v){ return this.$root.var.loadingOverlay = v },
    },
    isParent(){
      return this.$parent.$parent.$parent.$parent
    }
  },
  methods: {
    changeKajianPerawat(e){
      this.loadingOverlay = true
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian-perawat",
            idKajian: e
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.loadingOverlay = false
        this.isParent.kajianData.kajianPerawat = resp.data.kajianPerawat
      })
      // kajianData
    },
    changeKajianDokter(e){
      this.loadingOverlay = true
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian-dokter",
            idKajian: e
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.loadingOverlay = false
        this.isParent.kajianData.kajianDokter = resp.data.kajianDokter
      })
    },
    changeKajianGizi(e){
      this.loadingOverlay = true
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-detail-kajian-gizi",
            idKajian: e
          }
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.loadingOverlay = false
        this.isParent.kajianData.kajianGizi = resp.data.kajianGizi
      })
    },
  },
  components:{
    KajianAwalPerawatUmum,
    KajianAwalPerawatAnak,
    KajianAwalPerawatObsgyn,
    KajianAwalPerawatNICU,
    KajianAwalPerawatPICU,
    KajianAwalPerawatICU,
    KajianAwalPerawatBBL,
    KajianAwalMedis,
    KajianAwalMedisBBL,
    KajianAwalGizi
  }
}
</script>