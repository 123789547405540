<template>
  <div class="content-wrapper">
    <div v-if="isList" class="content pt-0">
      <bo-page-title />
      <div class="card">

        <ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
          <li class="nav-item"><a href="javascript:;" @click="changeTab('R')"
              :class="isObat == 'R' ? 'nav-link active' : 'nav-link'">Obat Racikan</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab('J')"
              :class="isObat == 'J' ? 'nav-link active' : 'nav-link'" class="nav-link">Obat Jadi</a></li>
        </ul>

        <div class="bg-white card-header">
          <div class="row align-items-center">
            <div class="col-md-4 col-lg-5">
              <div class="form-row align-items-center">
                <div class="col-lg-4">
                  <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                </div>
                <div class="col-lg-8">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
                        :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
                        :ranges="datePickerConfig.ranges" @update="updateValues">
                        <template v-slot:input="picker">
                          {{ picker.startDate | date }} - {{ picker.endDate | date }}
                        </template>
                      </date-range-picker>
                      <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-header">
          <div class="row">
            <div class="col-md-8 col-lg-9">
              <div class="row gx-1">
                <div class="col-md-auto">
                  <div class="form-group mb-0">
                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField"
                      data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i
                        class="icon-spinner11"></i></button>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row g-1">
                    <div class="col-md-6 col-lg-3">
                      <b-form-group class="mb-0">
                        <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"
                          :options="mDokterDPJP" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <b-form-group class="mb-0">
                        <v-select placeholder=" -- Pilih Kelas -- " @input="doFill" v-model="filter.kelas"
                          :options="mKelas" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <b-form-group class="mb-0">
                        <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ruangan"
                          :options="mRuangan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                <input class="form-control" v-model="filter.search" @input="doSearch"
                  placeholder="Ketik Nama/No. RM Pasien" />
                <div class="form-control-feedback">
                  <i class="icon-search4 text-indigo"></i>
                </div>

                <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn"
                  @click="doResetData()">Reset</b-button>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
            <thead>
              <tr>
                <th>No.</th>
                <th>Aksi</th>
                <th>No Kedatangan</th>
                <th>Status</th>
                <th>Nama / No. KTP</th>
                <th>No. RM</th>
                <th>Waktu Masuk</th>
                <th>Ruangan</th>
                <th>Dokter DPJP</th>
                <th>Cara Bayar</th>
                <th>Tgl Lahir</th>
                <th>Usia</th>
                <th>Jenis Kelamin</th>
              </tr>
            </thead>


            <tbody v-if="(dataList||[]).length">
              <template v-for="(v,k) in (dataList||[])">
                <tr :key="k" :class="v.aranrrp_status == 'WAITING' ? 'table-warning':''">
                  <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                  <td class="text-center">
                    <div class="d-flex">
                      <a href="javascript:;" @click="toDetail(v.aranrrp_id)" v-b-tooltip.hover title="Lihat Detail"
                        class="btn btn-icon rounded-round btn-sm alpha-danger border-danger mr-1"><i
                          class="icon-eye"></i></a>

                      <template v-if="v.aranrrp_is_non_obat != 'Y'">
                      <a href="javascript:;"
                        v-if="moduleRole('tambah_catatan_pemberian_obat') && (v.aranrrp_status == 'PROSES')"
                        @click="toModal(v,'xl','Catatan Pemberian Obat','FormCatatanPemberian')"
                        class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800 mr-1"
                        v-b-tooltip.hover title="Tambah Catatan Pemberian Obat">
                        <i class="fa-solid fa-pencil"></i>
                      </a>

                      <a href="javascript:;" v-if="moduleRole('lihat_catatan') && (v.aranrrp_status == 'DONE')"
                        @click="toModal(v,'xl','Catatan Pemberian Obat','FormCatatanPemberianDetail')"
                        class="btn btn-icon btn-sm rounded-round btn-sm alpha-info border-info text-info-800 mr-1"
                        v-b-tooltip.hover title="Lihat Catatan Pemberian Obat">
                        <i class="fas fa-file-prescription"></i>
                      </a>

                      <!-- v.aranrrp_status != 'DONE' && -->
                      <a href="javascript:;" v-if="moduleRole('batalkan') && v.aranrrp_status != 'DONE' && (v.aranrrp_status != 'DELIVERED' && v.aranrrp_status != 'VOID')"
                        @click="modalStop(v)"
                        class="btn btn-icon btn-sm rounded-round btn-sm alpha-danger border-danger text-danger-800"
                        v-b-tooltip.hover title="Batalkan Pemberian">
                        <i class="icon-notification2"></i>
                      </a>
                      </template>
                    </div>
                  </td>
                  <td><span class="font-weight-semibold">{{v.aranr_reg_code}}</span>
                  </td>
                  <td>
                    <span v-if="v.aranrrp_is_draft == 'Y'" class="badge badge-warning">Draft</span>
                    <template v-else>
                      <span v-if="v.aranrrp_status == 'WAITING'" class="badge badge-warning">Belum Diproses</span>
                      <span v-else-if="v.aranrrp_status == 'PROSES'" class="badge badge-info">Berikan Obat</span>
                      <span v-else-if="v.aranrrp_status == 'DONE'" class="badge badge-success">Sudah diberikan ke
                        perawat</span>
                      <span v-else-if="v.aranrrp_status == 'DELIVERED'" class="badge badge-success">Sudah diberikan ke
                        pasien</span>
                      <span v-else-if="v.aranrrp_status == 'VOID'" class="badge badge-danger">Dibatalkan</span>
                    </template>

                    <span v-if="v.aranrrp_is_non_obat == 'Y'" class="badge badge-info mt-1">
                      Non Obat
                    </span>

                    <span v-if="v.is_stop" class="badge badge-danger mt-1">Obat Dihentikan</span>
                  </td>
                  <td>
                    <div class="d-flex flex-column align-items-start">
                      <a href="javascript:;" @click="toCPPT(v)" class="font-weight-semibold border-bottom"
                        v-b-tooltip.hover title="Lihat Informasi Perawatan Pasien">{{v.ap_fullname||"-"}}</a>
                      <small>{{v.ap_code||"-"}}</small>
                    </div>
                    <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}
                      WIB</small>
                  </td>

                  <td>
                    {{v.ap_code||"-"}}
                  </td>


                  <td>{{v.aranrrp_date |  moment("DD MMMM YYYY, HH:mm")}} WIB</td>
                  <td>
                    <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div> - 
                    <small><span>{{getConfigDynamic(mRuangan,v.aranres_bangsal)||"-"}} </span></small>
                    <span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>
                  </td>
                  <td>{{getConfigDynamic(mDokterDPJP,v.aranr_dokter_id)||"-"}} </td>
                  <td>{{v.cara_bayar||"-"}}</td>

                  <td>{{v.ap_dob | moment("DD MMMM YYYY")}}</td>
                  <td>
                    <div>{{v.ap_usia_with_ket||"-"}}</div>
                    <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                  </td>
                  <td>{{v.ap_gender == 1 ? "Laki-Laki" : "Perempuan"}}</td>
                </tr>
              </template>
            </tbody>
            <tbody v-if="data.data.length == 0">
              <tr>
                <td colspan="99">
                  <div class="text-center">
                    <h4 align="center"><span
                        v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian
                        tidak ditemukan</span>
                      <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!data.data">
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
              <tr>
                <td colspan="99">
                  <div class="skeletal-comp"></div>
                </td>
              </tr>
            </tbody>
          </table>


          <div
            :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
            <a href="javascript:;" data-scroll="left"
              class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-left"></i>
            </a>
            <a href="javascript:;" data-scroll="right"
              class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-right"></i>
            </a>
          </div>
        </div>

        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
          <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
        </b-card-footer>

      </div>


      <b-modal v-model="openModal" :size="modalConfig.size" no-close-on-backdrop hide-footer :title="modalConfig.title">
        <FormCatatanPemberian v-if="isModal == 'FormCatatanPemberian'" />
        <FormCatatanPemberianDetail v-if="isModal == 'FormCatatanPemberianDetail'" />
      </b-modal>

      <validation-observer ref="VFormStopObat">
        <b-modal v-model="openModalStop" :title="'Pembatalan Obat'" size="sm" @ok.prevent="stopObat">
          <div class="row">
            <div class="col-md-12">
              <label for="monitoringGizi">Alasan Pembatalan <strong class="text-danger">*</strong></label>
              <b-form-textarea v-model="row.aranrrp_notes" :formatter="normalText" name="Montoring" id="Montoring" rows="3" class="form-control"></b-form-textarea>
              <VValidate name="Alasan Pembatalan" v-model="row.aranrrp_notes"
                :rules="{required: 1, min: 3, max: 128}" />
            </div>
          </div>
        </b-modal>
      </validation-observer>
    </div>
    <div v-else>
      <Form />
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  const moment = require('moment')
  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'
  import Form from './Form.vue'
  import FormCatatanPemberian from './FormCatatanPemberian.vue'
  import FormCatatanPemberianDetail from './FormCatatanPemberianDetail.vue' 


  export default {
    extends: GlobalVue,
    components:{
      DateRangePicker,Form,FormCatatanPemberian,FormCatatanPemberianDetail
    },
    data(){
      return {
        idKey:'aranres_id',
        datePickerConfig: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
          autoApply: true,
          ranges: {
              'Hari Ini': [new Date(), new Date()],
              '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
              '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
          },
          applyClass: 'btn-sm btn-primary',
          cancelClass: 'btn-sm btn-light',
          locale: {
              applyLabel: 'Terapkan',
              cancelLabel: 'Batal',
              direction: 'ltr',
              format: 'mm/dd/yyyy',
              separator: ' - ',
          }
        },
        dateRange: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        },
        mDokterDPJP: [],
        mKelas: [],
        mRuangan: [],

        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,
        isObat: 'R',
        resepObat: [],
        resepObatView: [],
        mBangsal: [],
        mObat: [],
        openModalStop: false
      }
    },
    methods: {
      modalStop(row){
        this.rowEdit = row
        this.openModalStop = true
      },
      stopObat(){
        this.$refs['VFormStopObat'].validate().then(status => {
          if(status){
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan membatalkan obat ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                this.loadingOverlay = true
                Gen.apiRest(
                  "/do/" + this.modulePage, {
                    data: {
                      id: this.rowEdit.aranrrp_id,
                      type: 'void-resep',
                      alasan: this.rowEdit.aranrrp_notes
                    }
                  },
                  "POST"
                ).then(res => {
                  this.loadingOverlay = false
                  this.$swal({
                    title: "Berhasil membatalkan pemberian obat",
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    if (result.value) {
                      this.openModalStop = false
                      this.apiGet()
                    }
                  })
                }).catch(() => {
                  setTimeout(() => {
                    this.loadingOverlay = false
                    this.$swal({
                      title: "Gagal membatalkan pemberian obat",
                      icon: 'error',
                      confirmButtonText: 'Ok'
                    })
                  }, 700)
                })
              }
            })
          }
        })
      },
      toDetail(regID){
        this.$router.push({ name: this.modulePage, params: {pageSlug: regID}}).catch(()=>{})
      },
      changeTab(isObat){
        this.isObat = isObat
        this.filter.isObat = isObat
        
        this.doPageOne()
        this.doFilter()
      },
      toCPPT(row){
        this.$router.push({ name: 'RanapCPPT', params: {pageSlug: row.aranrrp_aranr_id}}).catch(()=>{})
      },
      setSlide(){
        const responsiveTable = document.querySelector('.table-responsive')
        if(responsiveTable){
          const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
          const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
          if(responsiveTableScroll){
            // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
            Array.from(responsiveTableScrollActions).forEach(action => {
              action.addEventListener('click', e => {
                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                  
                responsiveTable.scroll({
                    left: scrollAmount,
                    behavior: 'smooth'
                })
              })
            })
          }
        }
      },
      doResetData(){
        this.doReset()
        this.dateRange =  {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        }
        this.doConvertDate()
      },
      doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      },
      doPageOne(){
        this.filter.page = 1
      },
      doSearch: _.debounce(function(){
        this.doPageOne()
        this.doFilter()
      },500),
      doFill(){
        this.doPageOne()
        this.doFilter()
      },
      updateValues(e){
        this.doConvertDate()
        this.doFill()
      },
      doRefreshData(){
        this.apiGet()
        window.scrollTo(0,0)
      },
      getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
      },
      toModal(row,size,title,isModal){
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-farmasi-data', id: row.aranrrp_aranr_id, idFarmasi: row.aranrrp_id}}, 
            "POST"
        ).then(res=>{
          this.loadingOverlay = false
          this.resepObat = res.data.resepObat  
          this.resepObatView = res.data.resepObatView

          this.rowEdit = row
          this.modalConfig = {
            title: title,
            size: size
          }
          this.isModal = isModal
          this.openModal = true
        })
      },
    },
    mounted(){
      this.filter.dpjp = 'ALL'
      this.filter.kelas = 'ALL'
      this.filter.ruangan = 'ALL'
      this.filter.isObat = "R"
      this.filter.bangsal = 'ALL'


      this.doConvertDate()      
      this.doFill()
          
      setTimeout(()=>{
        this.setSlide()
      },1000)
    },
    filters: {
      date(val) {
        return val ? moment(val).format("D MMM YYYY") : ""
      }
    },
    watch:{
      $route(){
        this.apiGet()
        setTimeout(()=>{
          if(this.isList){
            this.setSlide()
          }
        },1000)
      }
    }
  }
</script>