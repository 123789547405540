<template>
  <div class="mt-2">
    <table class="table table-bordered table-sm">
      <tr>
        <td colspan="2">
          <div class="result_tab">
            <h4>Keluhan Utama</h4>
            <p>{{dataKajian.row.arankm_keluhan_utama||"-"}}</p>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="result_tab">
            <h4>Riwayat Penyakit</h4>
            <p>{{dataKajian.row.arankm_riwayat_penyakit||"-"}}</p>
          </div>
        </td>
        <td>
          <div class="result_tab">
            <h4>Riwayat Penyakit Sekarang</h4>
            <p>{{dataKajian.row.arankm_riwayat_penyakit_sekarang||"-"}}</p>

          </div>
        </td>
      </tr>
    </table>
    <div class="form-row mt-3 mb-3">
      <div class="col-md-6">
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Alergi</h6>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <div class="col-md-12">
                <div class="table-responsive" v-if="dataKajian.row.arankm_has_alergi == 'Y'">
                  <table class="table table-bordered">
                    <tbody>
                        <tr>
                          <th><strong>Jenis</strong></th>
                          <th><strong>Nama</strong></th>
                        </tr>
                        <tr v-for="(v,k) in (dataKajian.row.arankm_alergi||[])" :key="k+'ob'">
                          <td>
                              <div class="result_tab">
                              {{v.jenis||"-"}}
                              </div>
                          </td>
                          <td>
                              <div class="result_tab">
                              {{v.name||"-"}}
                              </div>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-row" v-else>
                  <span>Tidak Ada Alergi</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Obat Rutin</h6>
          </div>
          <div class="card-body p-3">
            <div class="form-row">
              <div class="col-md-12">
                <div v-if="dataKajian.row.arankm_has_obat_rutin == 'Y'" class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Keterangan</h4>
                            <p>{{dataKajian.row.arankm_keterangan_obat_rutin||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="form-row" v-else>
                  <span>Tidak Ada Alergi</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mt-2">
      <div class="card mb-0">
        <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
        </div>
        <div class="card-body p-3">
          <div class="form-row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Tekanan Darah</h4>
                            <p v-if="dataKajian.row.arankm_ttv_tekanan_darah_min || dataKajian.row.arankm_ttv_tekanan_darah_max">{{dataKajian.row.arankm_ttv_tekanan_darah_min}} / {{dataKajian.row.arankm_ttv_tekanan_darah_max}} mmHG</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Nadi</h4>
                            <p v-if="dataKajian.row.arankm_ttv_nadi">{{dataKajian.row.arankm_ttv_nadi||"-"}}/mnt</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Gula Darah</h4>
                            <p v-if="dataKajian.row.arankm_ttv_gula_darah">{{dataKajian.row.arankm_ttv_gula_darah||"-"}}mg/dL</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Pernafasan</h4>
                            <p v-if="dataKajian.row.arankm_ttv_pernafasan">{{dataKajian.row.arankm_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>SPO2</h4>
                            <p v-if="dataKajian.row.arankm_ttv_spo2">{{dataKajian.row.arankm_ttv_spo2||"-"}}%</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Suhu</h4>
                            <p v-if="dataKajian.row.arankm_ttv_suhu">{{dataKajian.row.arankm_ttv_suhu||"-"}}C</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Berat Badan</h4>
                            <p v-if="dataKajian.row.arankm_ttv_weight">{{dataKajian.row.arankm_ttv_weight||"-"}}kg</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Tinggi</h4>
                            <p v-if="dataKajian.row.arankm_ttv_height">{{dataKajian.row.arankm_ttv_height||"-"}}Cm</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Lingkar Kepala</h4>
                            <p v-if="dataKajian.row.arankm_ttv_lingkar_kepala">{{dataKajian.row.arankm_ttv_lingkar_kepala||"-"}}cm</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-if="dataKajian.row.ap_usia >= 1">
                        <div class="result_tab">
                            <h4>BMI</h4>
                            <p v-if="dataKajian.row.arankm_ttv_bmi">{{dataKajian.row.arankm_ttv_bmi}}m2</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td v-if="dataKajian.row.ap_usia <= 15">
                            <div class="result_tab">
                            <h4>Luas Permukaan Tubuh Anak</h4>
                            <p v-if="dataKajian.row.arankm_ttv_luas_permukaan_anak">{{dataKajian.row.arankm_ttv_luas_permukaan_anak}}kg/m2</p>
                            <p v-else> - </p>
                            </div>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Kesadaran GCS</h6>
      </div>
      <div class="card-body p-3">
        <table class="table table-bordered">
        <tbody>
            <tr>
            <td>
                <div class="result_tab">
                <h4>Respons Mata</h4>
                <p>{{getConfigDynamic(Config.mr.ksResponseMata,dataKajian.row.arankm_response_mata)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.arankm_response_mata||0}})</span></p>
                </div>
            </td>
            <td>
                <div class="result_tab">
                <h4>Respons Verbal</h4>
                <p>{{getConfigDynamic(Config.mr.ksResponseVerbal,dataKajian.row.arankm_response_verbal)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.arankm_response_verbal||0}})</span></p>
                </div>
            </td>
            <td>
                <div class="result_tab">
                <h4>Respons Motorik</h4>
                <p>{{getConfigDynamic(Config.mr.ksResponseMotorik,dataKajian.row.arankm_response_motorik)||"-"}}<span class="font-weight-semibold text-info">({{dataKajian.row.arankm_response_motorik||0}})</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                <h4>Nilai GCS</h4>
                <p>{{dataKajian.row.arankm_response_gcs||0}} - 
                <span v-if="dataKajian.row.arankm_response_gcs >= 14"> Composmentis</span> 
                <span v-else-if="dataKajian.row.arankm_response_gcs >= 12 && dataKajian.row.arankm_response_gcs <= 13">Apatis</span>
                <span v-else-if="dataKajian.row.arankm_response_gcs >= 10 && dataKajian.row.arankm_response_gcs <= 11">Delirium</span>
                <span v-else-if="dataKajian.row.arankm_response_gcs >= 7 && dataKajian.row.arankm_response_gcs <= 9">Somonolen</span>
                <span v-else-if="dataKajian.row.arankm_response_gcs >= 5 && dataKajian.row.arankm_response_gcs <= 6">Sopor</span>
                <span v-else-if="dataKajian.row.arankm_response_gcs >= 4 && dataKajian.row.arankm_response_gcs <= 4">Semi-Comma</span>
                <span v-else>Coma</span>

                <span class="ml-2" style="margin-right:20px;">E: 
                    {{dataKajian.row.arankm_response_mata||0}} 
                </span>
                <span style="margin-right:20px;">M: 
                    {{dataKajian.row.arankm_response_motorik||0}}                                 
                </span>
                <span style="margin-right:20px;">V: 
                    {{dataKajian.row.arankm_response_verbal||0}}
                </span>
                </p>
                </div>
            </td>
            </tr>
        </tbody>
        </table>
      </div>
    </div>
    
    <div class="card">
      <div class="card-header bg_head_panel" @click="keadaanUmum = !keadaanUmum">
      <h6 class="card-title font-weight-semibold" 
      >Keadaan Umum</h6>
      </div>
      <div class="card-body p-3" v-if="keadaanUmum">
      <table class="table table-bordered">
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Kepala</h4>
              <p v-if="dataKajian.row.arankm_vs_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_kepala == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_kepala_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Mata</h4>
              <p v-if="dataKajian.row.arankm_vs_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_mata == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_mata_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Hidung</h4>
              <p v-if="dataKajian.row.arankm_vs_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_hidung == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_hidung_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Rambut</h4>
              <p v-if="dataKajian.row.arankm_vs_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_rambut == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_rambut_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Bibir</h4>
              <p v-if="dataKajian.row.arankm_vs_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_bibir == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_bibir_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Gigi Geligi</h4>
              <p v-if="dataKajian.row.arankm_vs_gigi == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_gigi == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_gigi_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Lidah</h4>
              <p v-if="dataKajian.row.arankm_vs_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_lidah == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_lidah_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Langit-Langit</h4>
              <p v-if="dataKajian.row.arankm_vs_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_langit == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_langit_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Leher</h4>
              <p v-if="dataKajian.row.arankm_vs_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_leher == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_leher_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Tenggorokan</h4>
              <p v-if="dataKajian.row.arankm_vs_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_tenggorokan == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_tenggorokan_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Tonsil</h4>
              <p v-if="dataKajian.row.arankm_vs_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_tonsil == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_tonsil_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Dada</h4>
              <p v-if="dataKajian.row.arankm_vs_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_dada == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_dada_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Payudara</h4>
              <p v-if="dataKajian.row.arankm_vs_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_payudara == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_payudara_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Punggung</h4>
              <p v-if="dataKajian.row.arankm_vs_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_punggung == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_punggung_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Perut</h4>
              <p v-if="dataKajian.row.arankm_vs_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_perut == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_perut_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Genital</h4>
              <p v-if="dataKajian.row.arankm_vs_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_genital == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_genital_text||"-"}}</p>
              </div>
          </td>
          
          <td width="33%">
              <div class="result_tab">
              <h4>Anus</h4>
              <p v-if="dataKajian.row.arankm_vs_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_anus == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_anus_text||"-"}}</p>
              </div>
          </td>
          
          <td width="33%">
              <div class="result_tab">
              <h4>Lengan Atas</h4>
              <p v-if="dataKajian.row.arankm_vs_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_lengan_atas == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_lengan_atas_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Lengan Bawah</h4>
              <p v-if="dataKajian.row.arankm_vs_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_lengan_bawah == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_lengan_bawah_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Jari Tangan</h4>
              <p v-if="dataKajian.row.arankm_vs_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_jari_tangan == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_jari_tangan_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Kuku Tangan</h4>
              <p v-if="dataKajian.row.arankm_vs_kuku == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_kuku == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_kuku_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Sendi Tangan</h4>
              <p v-if="dataKajian.row.arankm_vs_sendi_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_sendi_tangan == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_sendi_tangan_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Tungkai Atas</h4>
              <p v-if="dataKajian.row.arankm_vs_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_tungkai_atas == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_tungkai_atas_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Tungkai Bawah</h4>
              <p v-if="dataKajian.row.arankm_vs_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_tungkai_bawah_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <td width="33%">
              <div class="result_tab">
              <h4>Jari Kaki</h4>
              <p v-if="dataKajian.row.arankm_vs_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_jari_kaki == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_jari_kaki_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Kuku Kaki</h4>
              <p v-if="dataKajian.row.arankm_vs_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_kuku_kaki == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_kuku_kaki_text||"-"}}</p>
              </div>
          </td>
          <td width="33%">
              <div class="result_tab">
              <h4>Sendi Kaki</h4>
              <p v-if="dataKajian.row.arankm_vs_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
              <p v-else-if="dataKajian.row.arankm_vs_persendian_kaki == 'TP'">Tidak Diperiksa</p>
              <p v-else>{{dataKajian.row.arankm_vs_persendian_kaki_text||"-"}}</p>
              </div>
          </td>
          </tr>
          <tr>
              <td width="33%" colspan="3">
                  <div class="result_tab">
                  <h4>Saraf</h4>
                  <p v-if="dataKajian.row.arankm_vs_saraf == 'N'">TAK (Tidak Ada Kelainan)</p>
                  <p v-else-if="dataKajian.row.arankm_vs_saraf == 'TP'">Tidak Diperiksa</p>
                  <p v-else>
                      <table class="table table-bordered">
                          <tr>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Refleks Fisiologis</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_r_fisiologis||"-"}}</p>
                                  </div>
                              </td>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Refleks Patologis</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_r_patologis||"-"}}</p>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Babinski</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_babinski||"-"}}</p>
                                  </div>
                              </td>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Lasegue</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_lasegue||"-"}}</p>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Bregard</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_bregard||"-"}}</p>
                                  </div>
                              </td>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Sicard</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_sicard||"-"}}</p>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Kaku Duduk</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_kaku_duduk||"-"}}</p>
                                  </div>
                              </td>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Kernig Sign</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_kernig_sign||"-"}}</p>
                                  </div>
                              </td>
                          </tr>
                          <tr>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Brudzinski 1</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_brudzinski||"-"}}</p>
                                  </div>
                              </td>
                              <td width="50%">
                                  <div class="result_tab">
                                  <h4>Brudzinski 2</h4>
                                  <p>{{dataKajian.row.arankm_vs_saraf_brudzinski2||"-"}}</p>
                                  </div>
                              </td>
                          </tr>
                      </table>
                  </p>
                  </div>
              </td>
          </tr>
      </table>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-6">
        <div class="card border shadow-0 mb-0">
          <div class="bg-light card-header py-2">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>Item ICD-10 dipilih</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (dataKajian.dataICD10||[])" :key="k">
                <td><span>{{v.mi10_name||"-"}} <span
                      class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
              </tr>
              <tr v-if="!(dataKajian.dataICD10||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
            
            <template v-if="dataKajian.row.arankm_diagnosa_text">
            <thead>
              <tr>
                <th>Diagnosa Lainnya</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dataKajian.row.arankm_diagnosa_text||"-"}}</td>
              </tr>
            </tbody>
            </template>
          </table>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card border shadow-0 mb-0">
          <div class="bg-light card-header py-2">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th>Item ICD-9-CM dipilih</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (dataKajian.dataICD9||[])" :key="k">
                <td><span>{{v.mi9_name||"-"}} <span
                  class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                <td>
                  <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.arankai9d_informed_consent"
                    class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                    <i class="icon-file-eye2"></i>
                  </a>
                  <span v-else> - </span>
                </td>
              </tr>
              <tr v-if="!(dataKajian.dataICD9||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
            <template v-if="dataKajian.row.arankm_tindakan_text">
            <thead>
              <tr>
                <th colspan="2">Tindakan Lainnya</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dataKajian.row.arankm_tindakan_text||"-"}}</td>
                <td>
                  <a href="javascript:;"  @click="openInformConsentDetailForm()" v-if="dataKajian.row.arankm_inform_consent_id"
                    class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-b-tooltip.hover title="Lihat Informed Consent">
                    <i class="icon-file-eye2"></i>
                  </a>
                  <span v-else> - </span>
                </td>
              </tr>
            </tbody>
            </template>
          </table>
        </div>
      </div>
    </div>
        
    <div class="card mt-3">
      <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">RENCANA PENGOBATAN DAN TINDAKAN</h6>
      </div>
      <div class="card-body p-3">
        <table class="table table-bordered">
          <tr>
            <td colspan="99">
              <div class="result_tab">
                <p>{{dataKajian.row.arankm_rencana_tindakan||"-"}}</p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>


    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.aranic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.aranic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.aranic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.aranic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.aranic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.aranic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.aranic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.aranic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>
  </div>
</template>


<script>
import Config from '@/libs/Config'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  data(){
    return {
      keadaanUmum: false,
      activeAnat: 0,  
      openModalICDetail: false,
      rowIC: {}
    }
  },

  computed: {
    dataKajian(){
      return this.$parent.$parent.kajianData.kajianDokter
    },
    dataPenunjang(){
      return this.$parent.$parent.kajianData.kajianPenunjang
    },
    Config(){ return Config },
  },
  
  methods: {
    openInformConsentDetail(v){
        Gen.apiRest('/do/' + 'RanapKAMedis', {
            data: {
                type: 'get-data-ic',
                id: v.arankai9d_informed_consent,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.arankai9d_id
            this.openModalICDetail = true                   
        })
    },
    openInformConsentDetailForm(){
        Gen.apiRest('/do/' + 'RanapKAMedis', {
            data: {
                type: 'get-data-ic',
                id: this.dataKajian.row.arankm_inform_consent_id,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.mi9_name = this.dataKajian.row.arankm_tindakan_text
            this.rowIC.mi9_code = ''
            this.rowIC.id_assesment = this.dataKajian.row.arankm_id
            this.openModalICDetail = true                   
        })
    },
    changeTabAnat(k, isAdd = false) {
      this.activeAnat = k
    },
    assetLocal(img){
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
      img = def+img
      return img
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
  }
}
</script>