<template>
    <div>
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
          <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" 
          :class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kajian Awal</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" 
          :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kajian Nyeri</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" 
          :class="activeTab == 3 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Risiko Jatuh </a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" 
          :class="activeTab == 4 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Tingkat Kesadaran </a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(5)" 
          :class="activeTab == 5 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Skrining Gizi</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(6)" 
          :class="activeTab == 6 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Asuhan Keperawatan</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(7)" 
          :class="activeTab == 7 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Resep Alkes</a></li>
        </ul>
        <div class="tab-content" v-if="Object.keys(dataKajianPerawat||{}).length">
          <div :class="activeTab == 1 ? 'tab-pane fade show active':'tab-pane'" id="per-awal-kajian-awal">
            <div class="card">
              <div class="card-header bg_head_panel">
                <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
              </div>
              <div class="card-body p-3">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>No. Rekam Medis</h4>
                          <p>{{dataKajianPerawat.row.ap_code||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Nama Pasien</h4>
                          <p>{{dataKajianPerawat.row.ap_fullname||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tempat, Tanggal Lahir</h4>
                          <p>{{dataKajianPerawat.row.pob||"-"}}, {{dataKajianPerawat.row.dob | moment("DD MMMM YYYY") }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Jenis Kelamin</h4>
                          <p>{{dataKajianPerawat.row.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Keluhan Utama</h4>
                        <p>{{dataKajianPerawat.row.aukpa_keluhan_utama||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Riwayat Penyakit</h4>
                        <p>Dahulu : {{dataKajianPerawat.row.aukpa_riwayat_penyakit_old||"-"}}</p>
                        <p>Sekarang : {{dataKajianPerawat.row.aukpa_riwayat_penyakit||"-"}}</p>

                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Riwayat Pengobatan</h4>
                        <p>{{dataKajianPerawat.row.aukpa_riwayat_pengobatan||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Last Oral Intake</h4>
                        <p>{{dataKajianPerawat.row.aukpa_last_oral_intake||"-"}}</p>                      
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-row mt-2">
              <div class="col-md-6">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Alergi</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="form-row" v-if="dataKajianPerawat.row.aukpa_has_alergi == 'Y'">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                                <tr v-for="(v,k) in (dataKajianPerawat.row.aukpa_alergi||[])" :key="k+'ob'">
                                    <td>
                                        <div class="result_tab">
                                        {{v.jenis||"-"}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        {{v.name||"-"}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="form-row" v-else>
                        <div class="col-md-12">
                            <span>Tidak Ada Alergi</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Obat Rutin</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="form-row" v-if="dataKajianPerawat.row.aukpa_has_obat_rutin == 'Y'">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Keterangan</h4>
                                    <p>{{dataKajianPerawat.row.aukpa_keterangan_obat_rutin||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="form-row" v-else>
                        <div class="col-md-12">
                            <span>Tidak Ada Obat Rutin</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="card">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Tekanan Darah</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_tekanan_darah_min || dataKajianPerawat.row.aukpa_ttv_tekanan_darah_max">{{dataKajianPerawat.row.aukpa_ttv_tekanan_darah_min}} / {{dataKajianPerawat.row.aukpa_ttv_tekanan_darah_max}} mmHG</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Nadi</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_nadi">{{dataKajianPerawat.row.aukpa_ttv_nadi||"-"}}/mnt</p>
                                  <p v-else> - </p>
                                
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Gula Darah</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_gula_darah">{{dataKajianPerawat.row.aukpa_ttv_gula_darah||"-"}}mg/dL</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Pernafasan</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_pernafasan">{{dataKajianPerawat.row.aukpa_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>SPO2</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_spo2">{{dataKajianPerawat.row.aukpa_ttv_spo2||"-"}}%</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Suhu</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_suhu">{{dataKajianPerawat.row.aukpa_ttv_suhu||"-"}}C</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Berat Badan</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_weight">{{dataKajianPerawat.row.aukpa_ttv_weight||"-"}}kg</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Tinggi</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_height">{{dataKajianPerawat.row.aukpa_ttv_height||"-"}}Cm</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Lingkar Kepala</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_lingkar_kepala">{{dataKajianPerawat.row.aukpa_ttv_lingkar_kepala||"-"}}cm</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>BMI</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_bmi">{{dataKajianPerawat.row.aukpa_ttv_bmi}}m2</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td v-if="dataKajianPerawat.row.ap_usia <= 15">
                                <div class="result_tab">
                                  <h4>Luas Permukaan Tubuh Anak</h4>
                                  <p v-if="dataKajianPerawat.row.aukpa_ttv_luas_permukaan_anak">{{dataKajianPerawat.row.aukpa_ttv_luas_permukaan_anak}}kg/m2</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <!--
                              <td>
                                <div class="result_tab">
                                  <h4>Kesadaran</h4>
                                  <p>{{getConfigDynamic(masterKajianPerawat.mKesadaran,dataKajianPerawat.row.aukpa_ttv_kesadaran)||"-"}}</p>
                                </div>
                              </td>
                              -->
                              <td colspan="2">
                                <div class="result_tab">
                                  <h4>IMT</h4>
                                  <p>{{dataKajianPerawat.row.aukpa_ttv_imt||"-"}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Risiko Luka Decubitus</h6>
                </div>
                <div class="card-body p-3">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Kondisi Fisik</h4>
                            <p>{{getConfigDynamic(Config.mr.rldKondisiFisik,dataKajianPerawat.row.aukpa_risiko_luka_kondisi_fisik)||"-"}} <strong class="text-primary">({{dataKajianPerawat.row.aukpa_risiko_luka_kondisi_fisik||0}})</strong></p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Kesadaran</h4>
                            <p>{{getConfigDynamic(Config.mr.rldKesadaran,dataKajianPerawat.row.aukpa_risiko_luka_kesadaran)||"-"}} <strong class="text-primary">({{dataKajianPerawat.row.aukpa_risiko_luka_kesadaran}})</strong></p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Aktivitas</h4>
                            <p>{{getConfigDynamic(Config.mr.rldAktivitas,dataKajianPerawat.row.aukpa_risiko_luka_aktivitas)||"-"}} <strong class="text-primary">({{dataKajianPerawat.row.aukpa_risiko_luka_aktivitas}})</strong></p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Mobilitas</h4>
                            <p>{{getConfigDynamic(Config.mr.rldMobilitas,dataKajianPerawat.row.aukpa_risiko_luka_mobilitas)||"-"}} <strong class="text-primary">({{dataKajianPerawat.row.aukpa_risiko_luka_mobilitas}})</strong></p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Inkontinensia</h4>
                            <p>{{getConfigDynamic(Config.mr.rldInkontinensia,dataKajianPerawat.row.aukpa_risiko_luka_inkontinensia)||"-"}} 
                            <strong class="text-primary">({{dataKajianPerawat.row.aukpa_risiko_luka_inkontinensia}})</strong></p>
                          </div>
                        </td>
                      </tr>
                      <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                            <span>SKOR RISIKO: {{risikoDecTotal}}</span>
                            <span v-if="risikoDecTotal < 14" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Decubitus Tinggi</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Decubitus Rendah</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
            <div class="form-row mt-2" v-if="((dataKajianPerawat.row.ap_gender == 2||dataKajianPerawat.row.ap_gender == 0) 
            && dataKajianPerawat.row.ap_usia >= 12 && dataKajianPerawat.row.ap_usia <= 60) || (row.ap_gender == null || row.ap_gender == 3 || row.ap_gender == 4)">
                <div class="col-md-12">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="form-row">
                        <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                            <tbody>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Pasien Sedang Hamil</h4>
                                    <p>{{dataKajianPerawat.row.aukpa_is_hamil == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Pasien Menyusui</h4>
                                    <p>{{dataKajianPerawat.row.aukpa_is_menyusui == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                    </div>
                                </td>
                                <td v-if="dataKajianPerawat.row.aukpa_is_hamil == 'Y'">
                                    <div class="result_tab">
                                    <h4>Gravid</h4>
                                    <p>{{dataKajianPerawat.row.aukpa_gravid || "-"}}</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td v-if="dataKajianPerawat.row.aukpa_is_hamil == 'Y'">
                                    <div class="result_tab">
                                    <h4>Para</h4>
                                    <p>{{dataKajianPerawat.row.aukpa_para || "-"}}</p>
                                    </div>
                                </td>
                                <td v-if="dataKajianPerawat.row.aukpa_is_hamil == 'Y'">
                                    <div class="result_tab">
                                    <h4>Abortus</h4>
                                    <p>{{dataKajianPerawat.row.aukpa_abortus || "-"}}</p>
                                    </div>
                                </td>
                                <td v-if="dataKajianPerawat.row.aukpa_is_hamil == 'Y'">
                                    <div class="result_tab">
                                    <h4>HPHT</h4>
                                    <p>{{dataKajianPerawat.row.aukpa_hpht | moment("DD MMM YYYY")}}</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td v-if="dataKajianPerawat.row.aukpa_is_hamil == 'Y'">
                                    <div class="result_tab">
                                    <h4>HPL</h4>
                                    <p v-if="dataKajianPerawat.row.aukpa_hpl">{{dataKajianPerawat.row.aukpa_hpl | moment("DD MMM YYYY")}}</p>
                                    <p v-else> - </p>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            
            <div class="form-row mt-2">
              <div class="col-md-6">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Sosial Ekonomi</h6>
                  </div>
                  <div class="card-body p-3">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Pendidikan</h4>
                              <p>{{getConfigDynamic(masterKajianPerawat.mPendidikan,dataKajianPerawat.row.aukpa_pendidikan)}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Pekerjaan</h4>
                              <p>{{getConfigDynamic(masterKajianPerawat.mPekerjaan,dataKajianPerawat.row.aukpa_pekerjaan)}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Penghasilan</h4>
                              <p>{{getConfigDynamic(masterKajianPerawat.mPenghasilan,dataKajianPerawat.row.aukpa_penghasilan)}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Hubungan dengan Keluarga</h4>
                              <p>{{getConfigDynamic(Config.mr.baikNoOpt,dataKajianPerawat.row.aukpa_hubungan_keluarga)}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Psikologi</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Status Psikologis Pasien</h4>
                                <p>{{getConfigDynamic(Config.mr.psikologiPot,dataKajianPerawat.row.aukpa_status_psikologi)||"-"}}</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Spiritual</h6>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <div class="result_tab">
                              <h4>Agama Pasien</h4>
                              <p>{{getConfigDynamic(masterKajianPerawat.mAgama,dataKajianPerawat.row.aukpa_agama)||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Keyakinan</h4>
                              <p>
                                {{dataKajianPerawat.row.aukpa_keyakinan||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Nilai-nilai Pasien atau Keluarga</h4>
                              <p>
                                {{dataKajianPerawat.row.aukpa_nilai_nilai||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Fungsional</h6>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Alat Bantu</h4>
                              <p>{{dataKajianPerawat.row.aukpa_is_alat_bantu == 'Y' ? 'Ya' : 'Tidak'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                                <h4>Alat Bantu</h4>
                                <p v-if="dataKajianPerawat.row.aukpa_is_alat_bantu == 'Y'">
                                {{getConfigDynamic(masterKajianPerawat.mAlatBantu,dataKajianPerawat.row.aukpa_alat_bantu)||"-"}}</p>
                                <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>ADL (Aktifitas Sehari-hari)</h4>
                              <p> {{getConfigDynamic(Config.mr.adlStatus,dataKajianPerawat.row.aukpa_adl)||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Pengkajian Batuk</h6>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive" v-if="dataKajianPerawat.row.aukpa_is_batuk == 'Y'">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Apakah pasien mengalami demam saat ini dan beberapa hari terakhir?</h4>
                              <p>{{dataKajianPerawat.row.aukpa_is_demam == 'Y' ? 'Ya' : 'Tidak'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Apakah pasien berkeringat pada malam hari tanpa beraktivitas?	</h4>
                              <p>{{dataKajianPerawat.row.aukpa_is_keringat == 'Y' ? 'Ya' : 'Tidak'}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Apakah pasien memiliki riwayat bepergian dari daerah wabah?	</h4>
                              <p>{{dataKajianPerawat.row.aukpa_is_wadah == 'Y' ? 'Ya' : 'Tidak'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Apakah pasien memiliki riwayat pemakaian obat jangka panjang?	</h4>
                              <p>{{dataKajianPerawat.row.aukpa_is_jangka_panjang == 'Y' ? 'Ya' : 'Tidak'}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Apakah pasien mengalami penurunan BB tanpa sebab yang diketahui?	</h4>
                              <p>{{dataKajianPerawat.row.aukpa_is_bb == 'Y' ? 'Ya' : 'Tidak'}}</p>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <span v-else> Tidak ada Kajian Batuk </span>
                </div>
              </div>
            </div>
          </div>
          <div :class="activeTab == 2 ? 'tab-pane fade show active':'tab-pane'" id="per-awal-kajian-nyeri">
            <div>
              <div class="card" v-if="row.ap_usia > 6 || (row.ap_usia == null && row.ap_dob == null &&dataKajianPerawat.row.aukpn_nyeri_type == 'NP')">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-group">
                        <label for="">Skala Nyeri</label>
                        <div class="pain-scale no-pain" v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value == 0">
                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Tidak Ada Nyeri</strong>
                        </div>
                        <div class="pain-scale mild-pain" v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 1 && dataKajianPerawat.row.aukpn_skala_nyeri_value <= 3">
                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Sedikit Nyeri</strong>
                        </div>
                        <div class="pain-scale moderate-pain" v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 4 && dataKajianPerawat.row.aukpn_skala_nyeri_value <= 5">
                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Agak Mengganggu</strong>
                        </div>
                        <div class="pain-scale severe-pain" v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 6 && dataKajianPerawat.row.aukpn_skala_nyeri_value <= 7">
                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Mengganggu Aktivitas</strong>
                        </div>
                        <div class="pain-scale worst-pain" v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 8 && dataKajianPerawat.row.aukpn_skala_nyeri_value <= 9">
                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Sangat Mengganggu</strong>
                        </div>
                        <div class="pain-scale worst-pain" v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 10">
                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Tak Tertahankan</strong>
                        </div>
                  </div>
                </div>
              </div>
              
              <div class="card" v-else-if="(row.ap_usia <= 6 && dataKajianPerawat.row.ap_usia >= 1) || (row.ap_usia == null && row.ap_dob == null && dataKajianPerawat.row.aukpn_nyeri_type == 'FL')">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">FLACC Scale</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-group">
                    <label for="kidPainScale">Skala Nyeri</label>
                    <table class="table table-bordered pain-scale-table">
                      <thead>
                        <tr>
                          <th>Pengkajian</th>
                          <th>Hasil</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in dataKajianPerawat.row.aukpn_skala_nyeri" :key="k+'akn'">
                            <th class="table-warning text-center" style="width: 30%;"><img :src="$parent.$parent.$parent.assetLocal(v.img)"
                                style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                                {{v.name}}</th>
                            <td class="p-2">
                                {{getConfigDynamic(v.configVal,v.value)||"-"}}
                            </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="table-info">
                          <td colspan="4" class="text-info-700">
                            <span>NILAI SKALA NYERI: {{dataKajianPerawat.row.aukpn_skala_nyeri_value||"-"}}</span>
                            <span v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                            <span v-else-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 1 && dataKajianPerawat.row.aukpn_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                            <span v-else-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 4 && dataKajianPerawat.row.aukpn_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card" v-else-if="(row.ap_usia == null && row.ap_dob == null && dataKajianPerawat.row.aukpn_nyeri_type == 'NIPS')">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-group">
                    <label for="kidPainScale">Skala Nyeri</label>
                    <table class="table table-bordered pain-scale-table">
                      <thead>
                        <tr>
                          <th>Pengkajian</th>
                          <th>Hasil</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in dataKajianPerawat.row.aukpn_skala_nyeri_nips" :key="k+'akn'">
                            <th class="table-warning text-center" style="width: 30%;"><img :src="$parent.$parent.$parent.assetLocal(v.img)"
                                style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                                {{v.name}}</th>
                            <td class="p-2">
                                {{getConfigDynamic(v.configVal,v.value)||"-"}}
                            </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="table-info">
                          <td colspan="4" class="text-info-700">
                            <span>NILAI SKALA NYERI: {{dataKajianPerawat.row.aukpn_skala_nyeri_value||0}}</span>
                            
                            <span v-if="dataKajianPerawat.row.aukpn_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                            <span v-else-if="dataKajianPerawat.row.aukpn_skala_nyeri_value >= 3 && dataKajianPerawat.row.aukpn_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered">
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Lokasi Nyeri</h4>
                          <p>{{dataKajianPerawat.row.aukpn_lokasi_nyeri||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Penyebab Nyeri</h4>
                          <p>{{dataKajianPerawat.row.aukpn_penyebab_nyeri||"-"}}</p>                        
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Durasi Nyeri</h4>
                          <p>{{dataKajianPerawat.row.aukpn_durasi_nyeri||"-"}}</p>                        
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Frekuensi Nyeri</h4>
                          <p>{{dataKajianPerawat.row.aukpn_frekuensi_nyeri||"-"}}</p>                        
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div :class="activeTab == 3 ? 'tab-pane fade show active':'tab-pane'" id="per-awal-risiko-jatuh">
            <div class="row">
              <div class="col-md-12 mt-2">
                <div class="result_tab mb-3">
                <h4>Apakah Pasien Mengalami Gangguan Jiwa?</h4>
                <p>{{dataKajianPerawat.row.aukprj_is_gangguan_jiwa == 'Y' ? 'Ya':'Tidak'}}</p>
                </div>
                <div class="card" v-if="dataKajianPerawat.row.aukprj_is_gangguan_jiwa == 'Y'">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Kajian Edmonson</h6>
                  </div>
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Umur</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_umur">{{getConfigDynamic(Config.mr.edUmur,dataKajianPerawat.row.aukprj_ed_umur)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_umur}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Status Mental</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_status_mental">{{getConfigDynamic(Config.mr.edStatusMental,dataKajianPerawat.row.aukprj_ed_status_mental)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_status_mental}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Pola BAB/BAK</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_pola_pikir">{{getConfigDynamic(Config.mr.edPolaBAB,dataKajianPerawat.row.aukprj_ed_pola_pikir)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_pola_pikir}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Pengobatan</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_pengobatan">{{getConfigDynamic(Config.mr.edPengobatan,dataKajianPerawat.row.aukprj_ed_pengobatan)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_pengobatan}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Diagnosis</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_diagnosis">{{getConfigDynamic(Config.mr.edDiagnosis,dataKajianPerawat.row.aukprj_ed_diagnosis)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_diagnosis}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Ambulasi / Keseimbangan</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_ambulasi">{{getConfigDynamic(Config.mr.edAmbulasi,dataKajianPerawat.row.aukprj_ed_ambulasi)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_ambulasi}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Nutrisi</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_nutrisi != null">{{getConfigDynamic(Config.mr.edNutrisi,dataKajianPerawat.row.aukprj_ed_nutrisi)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_nutrisi}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Riwayat Jatuh</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_ed_riwayat_jatuh">{{getConfigDynamic(Config.mr.edRiwayatjatuh,dataKajianPerawat.row.aukprj_ed_riwayat_jatuh)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_ed_riwayat_jatuh}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                          <span class="text-uppercase">Tingkat Risiko Edmonson: {{edTotal||0}}</span>
                          
                          <span v-if="edTotal < 90" class="border-left ml-2 pl-2 font-weight-semibold"> Tidak Berisiko</span>
                          <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Berisiko Tinggi</span>
                          
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <template v-if="dataKajianPerawat.row.aukprj_is_gangguan_jiwa == 'N'">
                <div class="card" v-if="dataKajianPerawat.row.ap_usia <= 18 || (row.ap_usia == null && row.ap_dob == null 
                && dataKajianPerawat.row.aukprj_resiko_type == '1')">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Kajian Humpty Dumpty</h6>
                  </div>
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Umur</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_humpty_umur != null">{{getConfigDynamic(Config.mr.hdUmur,dataKajianPerawat.row.aukprj_humpty_umur)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_humpty_umur}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Jenis Kelamin</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_humpty_jenis_kelamin != null">{{getConfigDynamic(Config.mr.hdJK,dataKajianPerawat.row.aukprj_humpty_jenis_kelamin)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_humpty_jenis_kelamin}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Diagnosis</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_humpty_diagnosis != null">{{getConfigDynamic(Config.mr.hdDiagnosis,dataKajianPerawat.row.aukprj_humpty_diagnosis)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_humpty_diagnosis}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Gangguan Kognitif</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_humpty_gangguan_kognitif != null">{{getConfigDynamic(Config.mr.hdGangguanKognitif,dataKajianPerawat.row.aukprj_humpty_gangguan_kognitif)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_humpty_gangguan_kognitif}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Faktor Lingkungan</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_humpty_faktor_lingkungan != null">{{getConfigDynamic(Config.mr.hdFaktorLingkungan,dataKajianPerawat.row.aukprj_humpty_faktor_lingkungan)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_humpty_faktor_lingkungan}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Respon Pembedahan</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_humpty_respon_pembedahan != null">{{getConfigDynamic(Config.mr.hdPembedahan,dataKajianPerawat.row.aukprj_humpty_respon_pembedahan)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_humpty_respon_pembedahan}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Penggunaan obat</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_humpty_respon_penggunaan_obat != null">{{getConfigDynamic(Config.mr.hdPenggunaanObat,dataKajianPerawat.row.aukprj_humpty_respon_penggunaan_obat)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_humpty_respon_penggunaan_obat}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                          <span class="text-uppercase">Tingkat Risiko Humpty Dumpty: {{hdTotal}}</span>
                          <span v-if="hdTotal < 7" class="border-left ml-2 pl-2 font-weight-semibold">Tidak Beresiko</span>
                          <span v-else-if="hdTotal >= 7 && hdTotal <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                          <span v-else-if="hdTotal >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="card" v-else-if="dataKajianPerawat.row.ap_usia > 18 && dataKajianPerawat.row.ap_usia <= 60 || (row.ap_usia == null && row.ap_dob == null && dataKajianPerawat.row.aukprj_resiko_type == '2')">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Kajian Morse Fall Scale</h6>
                  </div>
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Riwayat Jatuh atau dalam 3 bulan terakhir</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_morshe_riwayat_jatuh != null">{{getConfigDynamic(Config.mr.snRiwayatJatuh,dataKajianPerawat.row.aukprj_morshe_riwayat_jatuh)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_morshe_riwayat_jatuh}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Diagnosis medis sekunder > 1</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_morshe_diagnosa != null">{{getConfigDynamic(Config.mr.snDiagnosa,dataKajianPerawat.row.aukprj_morshe_diagnosa)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_morshe_diagnosa}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Alat bantu jalan</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_morshe_alat_bantu != null">{{getConfigDynamic(Config.mr.snAlatBantuJalan,dataKajianPerawat.row.aukprj_morshe_alat_bantu)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_morshe_alat_bantu}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Pakai infus</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_morshe_inpus != null">{{getConfigDynamic(Config.mr.snPakaiInfus,dataKajianPerawat.row.aukprj_morshe_inpus)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_morshe_inpus}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Cara berjalan / berpindah</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_morshe_cara_jalan != null">{{getConfigDynamic(Config.mr.snCaraJalan,dataKajianPerawat.row.aukprj_morshe_cara_jalan)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_morshe_cara_jalan}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Status mental</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_morshe_status_mental != null">{{getConfigDynamic(Config.mr.snStatusMental,dataKajianPerawat.row.aukprj_morshe_status_mental)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_morshe_status_mental}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                          <span class="text-uppercase">Tingkat Risiko Morse Fall Scale: {{morsheTotal}}</span>
                          <span v-if="morsheTotal < 24" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                          <span v-else-if="morsheTotal >= 25 && morsheTotal <= 44" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                          <span v-else-if="morsheTotal > 45" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="row no-gutters">
                    <div class="col-md-6">
                      <table class="table table-sm table-striped table-bordered">
                        <thead>
                          <th>Hasil</th>
                          <th>Tindakan</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Risiko Rendah</td>
                            <td>Lakukan intervensi jatuh standar</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card" v-else-if="dataKajianPerawat.row.ap_usia > 60 || (row.ap_usia == null && row.ap_dob == null && dataKajianPerawat.row.aukprj_resiko_type == '3')">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Kajian Skala Ontario Modified Stratify - Sydney Scoring</h6>
                  </div>
                  <table class="table table-sm table-bordered">
                    <tbody>
                      <tr class="table-warning">
                        <th colspan="3">
                          <span class="font-weight-semibold">Riwayat Jatuh <span class="text-info">({{dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh=='Y' || dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh2bulan=='Y'?6:0}})</span></span>
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah pasien datang ke rumah sakit karena jatuh?</h4>
                            
                            <p v-if="dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh)}}</p>
                            <p v-else> - </p>

                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Jika tidak, apakah pasien mengalami jatuh dalam 2 bulan terakhir?</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh2bulan != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh2bulan)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                      <tr class="table-warning">
                        <th colspan="3">
                          <span class="font-weight-semibold">Status Mental <span class="text-info">({{(dataKajianPerawat.row.aukprj_status_mental_dellirium == "Y" || dataKajianPerawat.row.aukprj_status_mental_disorientasi == "Y" ||
                          dataKajianPerawat.row.aukprj_status_mental_agitasi == "Y") ? 14 : 0}})</span></span>
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah pasien delirium? (tidak membuat keputusan, pola pikir tidak terorganisir, gangguan daya ingat)</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_status_mental_dellirium != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_status_mental_dellirium)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah pasien disorientasi? (salah menyebutkan waktu, tempat atau orang)</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_status_mental_disorientasi != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_status_mental_disorientasi)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah pasien mengalami agatis? (ketakutan, gelisah dan cemas)</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_status_mental_agitasi != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_status_mental_agitasi)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th colspan="3">
                          <span class="font-weight-semibold">Penglihatan <span class="text-info">(
                            {{(dataKajianPerawat.row.aukprj_status_mental_dellirium == "Y" || dataKajianPerawat.row.aukprj_status_mental_disorientasi == "Y" ||
                            dataKajianPerawat.row.aukprj_status_mental_agitasi == "Y" )?1:0}}
                            )</span></span>
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah pasien memakai kacamata?</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_penglihatan_kacamata != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_penglihatan_kacamata)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah pasien mengeluh penglihatan buram?</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_penglihatan_buram != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_penglihatan_buram)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah pasien mempunyai glaucoma, katarak, atau degenerasi makula?</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_penglihatan_glaucoma != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_penglihatan_glaucoma)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th colspan="3">
                          <span class="font-weight-semibold">Kebiasaan <span class="text-info">({{dataKajianPerawat.row.aukprj_kebiasaan == 'Y'?2:0}})</span></span>
                        </th>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <div class="result_tab">
                            <h4>Apakah terdapat perubahan perilaku berkemih? (frekuensi, urgensi, inkontinensia, nokturia)</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_kebiasaan != null">{{getConfigDynamic(Config.mr.yesNoOptV2,dataKajianPerawat.row.aukprj_kebiasaan)}}</p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th colspan="3">
                          <span class="font-weight-semibold">Transfer (dari tempat tidur ke kursi dan kembali ke tempat tidur)</span>
                        </th>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <div class="result_tab">
                            <h4>Transfer</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_transfer != null">{{getConfigDynamic(Config.mr.syTransfer,dataKajianPerawat.row.aukprj_transfer)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_transfer}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                      <tr class="table-warning">
                        <th colspan="3">
                          <span class="font-weight-semibold">Mobilitas</span>
                        </th>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <div class="result_tab">
                            <h4>Mobilitas</h4>
                            <p v-if="dataKajianPerawat.row.aukprj_mobilitas != null">{{getConfigDynamic(Config.mr.syMobilitas,dataKajianPerawat.row.aukprj_mobilitas)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukprj_mobilitas}})</strong></p>
                            <p v-else> - </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                          <span class="text-uppercase">Tingkat Risiko Ontario Modified Stratify - Sydney Scoring: {{getTotalSydney}}</span>

                          <span v-if="getTotalSydney < 6" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                          <span v-else-if="getTotalSydney >= 6 && getTotalSydney < 17" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                          <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                        </td>
                        <!-- Penilaian Tingkat Risiko Ontario Modified Stratify - Sydney Scoring
                        0 - 5 = Risiko Rendah
                        6 - 16 = Risiko Sedang
                        17 - 30 = Risiko Tinggi
                        -->
                      </tr>
                    </tfoot>
                  </table>
                </div>
                </template>
              </div>

              <div class="col-md-12 mt-2" v-if="showIntervensi" >
                <div class="table-responsive">
                    <table class="table table-bordered table-sm table-striped">
                    <thead>
                        <tr>
                            <td colspan="2" class="bg-indigo">
                            <span class="font-weight-semibold">Intervensi Risiko Jatuh</span>
                            </td>
                        </tr>
                        <tr>
                        <th width="50%">Tindakan</th>
                        <th width="50%">Evaluasi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="dataKajianPerawat.row.aukprj_memasang_gelang == 'Y'">
                            <td>
                                <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                                <p>{{dataKajianPerawat.row.aukprj_memasang_gelang == 'Y' ? 'Ya':'Tidak'}}</p>
                            </td>
                                
                            <td>
                                <label class="mb-0">Terpasang gelang warna kuning pada</label>
                                <p>{{dataKajianPerawat.row.aukprj_memasang_gelang_text || "-"}}</p>
                            </td>
        
                        </tr>
                        <tr v-if="dataKajianPerawat.row.aukprj_memasang_pengaman == 'Y'">
                            <td>
                                <label class="mb-0">Memasang gelang kuning risiko jatuh</label>
                                <p>{{dataKajianPerawat.row.aukprj_memasang_pengaman == 'Y' ? 'Ya':'Tidak'}}</p>
                            </td>
                            <td>
                            <span v-if="dataKajianPerawat.row.aukprj_memasang_pengaman == 'Y'">Pagar pengaman tempat tidur terpasang pada kedua sisi</span>
                            <span v-else> - </span>
                            </td>
                        </tr>
                        <tr v-if="dataKajianPerawat.row.aukprj_memasang_roda == 'Y'">
                            <td>
                                <label class="mb-0">Mengunci roda tempat tidur</label>
                                <p>{{dataKajianPerawat.row.aukprj_memasang_roda == 'Y' ? 'Ya':'Tidak'}}</p>
                            </td>
                            <td>
                                <span v-if="dataKajianPerawat.row.aukprj_memasang_roda == 'Y'">Roda tempat tidur terkunci dan tidak mudah tergeser</span>
                                <span v-else> - </span>
                            </td>
                        </tr>
                        <tr v-if="dataKajianPerawat.row.aukprj_memasang_restrain == 'Y'">
                            <td>
                                <label class="mb-0">Memasang restrain</label>
                                <p>{{dataKajianPerawat.row.aukprj_memasang_restrain == 'Y' ? 'Ya':'Tidak'}}</p>
                            </td>
                            <td>
                                <label class="mb-0">Terpasang restrain di</label>
                                <p v-if="dataKajianPerawat.row.aukprj_memasang_restrain == 'Y'">{{dataKajianPerawat.row.aukprj_memasang_restrain_text||"-"}}</p>
                                <p v-else> - </p>
                            </td>
                        </tr>
                        <tr v-if="dataKajianPerawat.row.aukprj_memasang_edukasi == 'Y'">
                            <td>
                                <label class="mb-0">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga</label>
                                <p>{{dataKajianPerawat.row.aukprj_memasang_edukasi == 'Y' ? 'Ya':'Tidak'}}</p>
                            </td>
                            <td>
                                <span v-if="dataKajianPerawat.row.aukprj_memasang_edukasi == 'Y'">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga</span>
                                <span v-else> - </span>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
              </div>

            </div>
            

          </div>
          <div :class="activeTab == 4 ? 'tab-pane fade show active':'tab-pane'" id="per-awal-tingkat-kesadaran">
            <table class="table table-bordered table-sm patient-table">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Respons Mata</h4>
                      <p v-if="dataKajianPerawat.row.aukptk_response_mata">{{getConfigDynamic(Config.mr.ksResponseMata,dataKajianPerawat.row.aukptk_response_mata)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukptk_response_mata}})</strong></p>
                      <p v-else> - </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Respons Verbal</h4>
                      <p v-if="dataKajianPerawat.row.aukptk_response_verbal">{{getConfigDynamic(Config.mr.ksResponseVerbal,dataKajianPerawat.row.aukptk_response_verbal)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukptk_response_verbal}})</strong></p>
                      <p v-else> - </p>
                    </div>
                  </td>  
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Respons Motorik</h4>
                      <p v-if="dataKajianPerawat.row.aukptk_response_motorik">{{getConfigDynamic(Config.mr.ksResponseMotorik,dataKajianPerawat.row.aukptk_response_motorik)}} <strong class="text-info-800">({{dataKajianPerawat.row.aukptk_response_motorik}})</strong></p>
                      <p v-else> - </p>                    
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="table-info">
                  <td>
                    <span class="font-weight-semibold">Nilai GCS: {{dataKajianPerawat.row.aukptk_nilai_gcs||0}}</span> 

                    <span v-if="dataKajianPerawat.row.aukptk_nilai_gcs >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                    <span v-else-if="dataKajianPerawat.row.aukptk_nilai_gcs >= 12 && dataKajianPerawat.row.aukptk_nilai_gcs <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                    <span v-else-if="dataKajianPerawat.row.aukptk_nilai_gcs >= 10 && dataKajianPerawat.row.aukptk_nilai_gcs <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                    <span v-else-if="dataKajianPerawat.row.aukptk_nilai_gcs >= 7 && dataKajianPerawat.row.aukptk_nilai_gcs <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                    <span v-else-if="dataKajianPerawat.row.aukptk_nilai_gcs >= 5 && dataKajianPerawat.row.aukptk_nilai_gcs <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                    <span v-else-if="dataKajianPerawat.row.aukptk_nilai_gcs >= 4 && dataKajianPerawat.row.aukptk_nilai_gcs <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div :class="activeTab == 5 ? 'tab-pane fade show active':'tab-pane'" id="per-awal-skrining-gizi">
            <div class="row mt-2">
              <div class="col-md-12">
                <h5 class="font-weight-semibold">Apakah Pasien Mengalami Penurunan Berat Badan yang Tidak Diinginkan
                  Selama 6 Bulan Terakhir?<strong class="text-danger">*</strong>
                </h5>
                <table class="table table-bordered table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th>PARAMETER</th>
                      <th>SKOR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{dataKajianPerawat.row.aukpg_penurunan_berat == "TY" ? "Tidak yakin/Tidak tahu/Baju terasa longgar" : "Tidak ada penurunan berat badan"}}
                      </td>
                      <td>{{dataKajianPerawat.row.aukpg_penurunan_berat == "TY" ? 2 : 0}}</td>
                    </tr>
                    <template v-if="dataKajianPerawat.row.aukpg_penurunan_berat == 'TY'">
                    <tr class="table-info">
                      <td colspan="2">
                        Ya, dengan penurunan berat
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{getConfigDynamic(Config.mr.cfPenurunanBeratUGDLain,dataKajianPerawat.row.aukpg_penurunan_berat_detail)}}
                      </td>
                      <td>{{dataKajianPerawat.row.aukpg_penurunan_berat_detail||0}}</td>
                    </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-bordered table-striped  table-sm patient-table">
                  <thead>
                    <tr>
                      <th>SKOR: </th>
                      <th class="table-danger">{{dataKajianPerawat.row.aukpg_penurunan_value||0}}</th>
                      <th class="table-danger">{{dataKajianPerawat.row.aukpg_penurunan_value > 1 ? "TINGGI" : "RENDAH"}}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="col-12 mt-2">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah asupan makanan berkurang karena tidak nafsu makan?</h4>
                            <p>{{dataKajianPerawat.row.aukpg_tidak_nafsu == 'Y'?"Ya":"Tidak"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Diagnosis Khusus Pasien</h4>
                            <p v-if="dataKajianPerawat.row.aukpg_diagnosis_khusus_pasien != 'L'">{{getConfigDynamic(Config.mr.diagnosaKhusus,dataKajianPerawat.row.aukpg_diagnosis_khusus_pasien)||"-"}}</p>
                            <p v-else>{{dataKajianPerawat.row.aukpg_diagnosis_khusus_pasien_text||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div class="col-md-12">
                <div class="card">
                <div class="card-header bg-info"><h5 class="card-title font-weight-semibold">Riwayat Penggunaan Obat</h5></div>
                <div class="card-body">
                    <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Nama Obat</th>
                        <th>Dosis</th>
                        <th>Waktu Penggunaan</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (dataKajianPerawat.row.aukpg_riwayat_penggunaan_obat||[])" :key="k">
                        <td>{{k+1}}</td>
                            <td>{{v.nama_obat||"-"}}</td>
                            <td>{{v.dosis||"-"}}</td>
                            <td>{{v.waktu_penggunaan||"-"}}</td>
                        </tr>
                        <tr v-if="!(dataKajianPerawat.row.aukpg_riwayat_penggunaan_obat||[]).length">
                          <td colspan="3">Tidak ada Riwayat Penggunaan Obat</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="activeTab == 6 ? 'tab-pane fade show active':'tab-pane'" id="per-awal-asuhan-keperawatan">
            
            <div class="form-row">
                <div class="col-md-12">
                <div class="card border shadow-0">
                    <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)</h5>
                    </div>
                    <div class="card-body">
                    <ul class="nav nav-tabs nav-tabs-bottom">
                        <li class="nav-item" v-for="(v,k) in (dataKajianPerawat.diagnosa||[])" :key="k">
                            <a href="javascript:;" @click="changeTabSDKI(k+1)" data-toggle="tab" 
                            :data-target="'#diagTab'+(k+1)" 
                            :class="activeTabSDKI == (k+1) ? 'nav-link active' : 'nav-link'">
                            {{v.msd_code + " - " +v.msd_name||"Diagnosa baru"}}                
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content card-body p-0">
                        <div v-for="(v,k) in (dataKajianPerawat.diagnosa||[])" :key="k+'a'" 
                        :class="activeTabSDKI == (k+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#diagTab'+(k+1)">
                        <div class="row">
                            <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Diagnosa</h4>
                                        <p>{{v.msd_code ? v.msd_code + ' - ' + v.msd_name : '-'}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        <div class="form-row mt-2">
                            <div class="col-md-6">
                            <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Penyebab</h6>
                                </div>
                                <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <template v-for="(v,k) in (dataKajianPerawat.diagnosa[k].aukpaksd_penyebab||[])">
                                        <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                        </div>
                                    </template>
                                    <p v-if="!(dataKajianPerawat.diagnosa[k].aukpaksd_penyebab||[]).length">Tidak Ada</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            
                            <div class="col-md-6">
                            <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
                                </div>
                                <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-6">
                                    <template v-for="(v,k) in (dataKajianPerawat.diagnosa[k].aukpaksd_faktor_resiko||[])">
                                        <div :key="k+'faktor-res'" class="d-flex align-items-center  mb-2">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                        </div>
                                    </template>
                                    <p v-if="!(dataKajianPerawat.diagnosa[k].aukpaksd_faktor_resiko||[]).length">Tidak Ada</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-6">
                            <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
                                </div>
                                <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-6">
                                    <h6 class="font-weight-semibold">Subjective</h6>
                                    <template v-for="(v,k) in (dataKajianPerawat.diagnosa[k].aukpaksd_gejala_mayor_subjektif||[])">
                                        <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                        </div>
                                    </template>
                                    <p v-if="!(dataKajianPerawat.diagnosa[k].aukpaksd_gejala_mayor_subjektif||[]).length">Tidak Ada</p>
                                    </div>
                                    <div class="col-md-6">
                                    <h6 class="font-weight-semibold">Objective</h6>
                                    <template v-for="(v,k) in (dataKajianPerawat.diagnosa[k].aukpaksd_gejala_mayor_objektif||[])">
                                        <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                        </div>
                                    </template>
                                    <p v-if="!(dataKajianPerawat.diagnosa[k].aukpaksd_gejala_mayor_objektif||[]).length">Tidak Ada</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-6">
                            <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
                                </div>
                                <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-6">
                                    <h6 class="font-weight-semibold">Subjective</h6>
                                    <template v-for="(v,k) in (dataKajianPerawat.diagnosa[k].aukpaksd_gejala_minor_subjektif||[])">
                                        <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                        </div>
                                    </template>
                                    <p v-if="!(dataKajianPerawat.diagnosa[k].aukpaksd_gejala_minor_subjektif||[]).length">Tidak Ada</p>
                                    </div>
                                    <div class="col-md-6">
                                    <h6 class="font-weight-semibold">Objective</h6>
                                    <template v-for="(v,k) in (dataKajianPerawat.diagnosa[k].aukpaksd_gejala_minor_objektif||[])">
                                        <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                        </div>
                                    </template>
                                    <p v-if="!(dataKajianPerawat.diagnosa[k].aukpaksd_gejala_minor_objektif||[]).length">Tidak Ada</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-12">
                            <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Standar Luaran Keperawatan Indonesia
                                    (SLKI)
                                </h6>
                                </div>
                                <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <ul class="nav nav-tabs nav-tabs-bottom">
                                        <li class="nav-item" v-for="(v1,k1) in v.slki" :key="'Luaran'+k1">
                                        <a href="javascript:;" @click="changeTabSLKI(k1+1,k)" data-toggle="tab" 
                                        :data-target="'#luarTab'+(k1+1)" :class="v.aukpaksd_last_tab_slki == (k1+1) ? 'nav-link active' : 'nav-link'">
                                        {{  v1.msl_code + " - " + v1.msl_name || "Luaran Baru"}}</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content card-body p-0">
                                        <div v-for="(v1,k1) in v.slki" :key="k1+'lu'" :class="v.aukpaksd_last_tab_slki == (k1+1) ? 'tab-pane fade show active':'tab-pane fade'"  :id="'#luarTab'+(k1+1)">
                                        <div class="row">
                                            <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                    <td>
                                                        <div class="result_tab">
                                                        <h4> Luaran</h4>
                                                        <p>{{v1.msl_code ? v1.msl_code + ' - ' + v1.msl_name : '-'}}</p>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                            </div>
                                            <div class="col-md-12">
                                            <table
                                                class="table table-bordered table-striped table-hover table-sm patient-table">
                                                <thead>
                                                <tr>
                                                    <th>Kriteria</th>
                                                    <th>Skor</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(v11,k11) in (v1.aukpaksl_kriteria_hasil||[])" :key="k11+'kh'">
                                                    <td>
                                                    {{v11.name||"-"}}
                                                    </td>
                                                    <td>{{v11.hasil||"-"}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-12">
                            <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Standar Intervensi Keperawatan Indonesia
                                    (SIKI)
                                </h6>
                                </div>
                                <div class="card-body p-3">
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <ul class="nav nav-tabs nav-tabs-bottom">
                                            
                                        <li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
                                        <a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab" 
                                        :data-target="'#intvTab'+(k2+1)" :class="v.aukpaksd_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
                                        {{ v2.msi_code + " - " +v2.msi_name || "Intervensi Baru"}}
                                        </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content card-body p-0">
                                        <div v-for="(v2,k2) in v.siki" :key="k2+'sik'" :class="v.aukpaksd_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#intvTab1'+(k2+1)">
                                        <div class="row">
                                            <div class="col-md-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                    <td>
                                                        <div class="result_tab">
                                                        <h4> Intervensi</h4>
                                                        <p>{{v2.msi_code ? v2.msi_code + ' - ' + v2.msi_name : '-'}}</p>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                            </div>
                                            <div class="col-md-12">
                                            <table
                                                class="table table-bordered table-striped table-hover table-sm patient-table">
                                                <thead>
                                                    <tr>
                                                    <th class="table-primary" colspan="99">Tindakan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    <th colspan="2" class="table-light bg_head_panel">OBSERVASI</th>
                                                    </tr>
                                                    <template v-for="(vo,ko) in dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_observasi']" >
                                                        <tr :key="ko+'ko'" v-if="vo.value">
                                                            <td colspan="2">{{vo.name}}</td>
                                                        </tr>
                                                    </template>
                                                    <tr v-if="!countTindakan(dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_observasi'])">
                                                        <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                    <th colspan="2" class="table-light bg_head_panel">TERAPEUTIK</th>
                                                    </tr>
                                                    <template v-for="(vt,kt) in dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_terapeutik']" >
                                                        <tr :key="kt+'kt'" v-if="vt.value">
                                                            <td colspan="2">{{vt.name}}</td>
                                                        </tr>
                                                    </template>
                                                    <tr v-if="!countTindakan(dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_terapeutik'])">
                                                        <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                    </tr>
                                                </tbody>
                                                
                                                <tbody>
                                                    <tr>
                                                    <th colspan="2" class="table-light bg_head_panel">EDUKASI</th>
                                                    </tr>
                                                    <template v-for="(ve,ke) in dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_edukasi']" >
                                                        <tr :key="ke+'ke'" v-if="ve.value">
                                                            <td colspan="2">{{ve.name}}</td>
                                                        </tr>
                                                    </template>
                                                    <tr v-if="!countTindakan(dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_edukasi'])">
                                                        <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                    </tr>
                                                    
                                                </tbody>
                                                
                                                <tbody>
                                                    <tr>
                                                    <th colspan="2" class="table-light bg_head_panel">KOLABORASI</th>
                                                    </tr>
                                                    <template v-for="(vk,kk) in dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_kolaborasi']" >
                                                        <tr :key="kk+'kk'" v-if="vk.value">
                                                            <td colspan="2">{{vk.name}}</td>
                                                        </tr>
                                                    </template>
                                                    <tr v-if="!countTindakan(dataKajianPerawat.diagnosa[k]['siki'][k2]['aukpaksi_tindakan_kolaborasi'])">
                                                        <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>         
                        <div class="card border shadow-0">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Tindakan Keperawatan</h6>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>Diagnosa</th>
                                    <th>Tindakan Keperawatan</th>
                                    <th>PPA</th>
                                    <th>Waktu</th>
                                    <th>Tindakan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(vti,kti) in (v.aukpaksd_tindakan_keperawatan||[])" :key="kti+'tper'">
                                        <td v-if="kti == 0" :rowspan="(v.aukpaksd_tindakan_keperawatan||[]).length">Nyeri Akut</td>
                                        <td>{{vti.name||"-"}}</td>
                                        <td>
                                            <span>{{vti.ppa||"-"}}</span><br/>
                                            <span>{{vti.ppaLainnya||"-"}}</span>
                                        </td>
                                        <td>
                                            <span v-if="vti.dates">
                                                {{vti.dates | moment("DD MMM YYYY")}}
                                                <span v-if="vti.time" class="ml-2">
                                                    {{vti.time.HH}}:{{vti.time.mm}}
                                                </span>
                                            </span>
                                            <span v-else> - </span>
                                        </td>
                                        <td>{{vti.followup||"-"}}</td>
                                    </tr>
                                    <tr v-if="(v.aukpaksd_tindakan_keperawatan||[]).length">
                                        <td> - </td>
                                        <td>{{v.aukpaksd_informasi_tindakan||"-"}}</td>
                                        <td>      
                                            <span>Perawat</span>
                                            <span>{{v.aukpaksd_nama_perawat||""||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>
                                                {{dataKajianPerawat.row.dates | moment("DD MMM YYYY")}}
                                                <span class="ml-2">
                                                    {{v.aukpaksd_tindakan_pada | moment("h:mm") }}
                                                </span>
                                            </span>
                                        </td>
                                        <td>
                                            {{v.aukpaksd_follow_up||"-"}}
                                        </td>
                                    </tr>
                                    <tr v-if="!(v.aukpaksd_tindakan_keperawatan||[]).length">
                                        <td colspan="99">Tidak Ada Tindakan Keperawatan</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            
          </div>
          <div :class="activeTab == 7 ? 'tab-pane fade show active':'tab-pane'" id="per-resep-alkes">
            <div class="form-row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Resep Alkes</h6>
                  </div>
                  <div class="card-body p-3">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>Jenis</th>
                          <th>Nama</th>
                          <th>Jumlah</th>
                          <th>Frekuensi</th>
                          <th>Keterangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (dataKajianPerawat.resepDokter||[])" :key="'resepDokter'+k">
                          <td>
                            <div class="result_tab">
                              <p>{{v.aurd_jenis == 1 ? 'Obat' : v.aurd_jenis == 2 ? 'Alat Kesehatan' : v.aurd_jenis == 3 ? 'Racikan' : 'Paket Alkes'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p v-if="v.aurd_item_id == 99999">
                              {{v.aurd_item_lainnya||"-"}}
                              </p>
                              <p v-else>
                              {{v.aurd_nama||"-"}}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.aurd_jumlah||"-"}} {{v.aurd_satuan||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>
                                <span v-if="v.aurd_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                <span v-else>{{v.aurd_frekuensi_lainnya || "-"}}</span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.aurd_keterangan || "-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!(dataKajianPerawat.resepDokter||[]).length" class="table-info">
                          <td class="text-center" colspan="99">Tidak Ada Data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
            -
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')



export default{
    props:{
        row:Object,
        rowReg:Object,
        Config: Object,
        dataKajianPerawat: Object,
        historiKajianPerawat: Array,
        masterKajianPerawat: Object,
        masterKajianDokter: Object
    },
    data(){
        return {
            activeTab : 1,
            activeTabSDKI: 1,
            activeTabSLKI: 1,
            activeTabSIKI: 1
        }
    },
    methods: {
        changeTabSDKI(k){
            this.activeTabSDKI = k
        },
        changeTabSLKI(k1,k){
            this.dataKajianPerawat.diagnosa[k].aukpaksd_last_tab_slki = k1
        },
        changeTabSIKI(k2,k){
            this.dataKajianPerawat.diagnosa[k].aukpaksd_last_tab_siki = k2
        },
        getConfigDynamic(master,value){
            let text = ''
            if(value != null){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        changeTab(e){
            this.activeTab = e
        },
        countTindakan(data){
            let total = 0
            for(let i = 0; i < (data||[]).length; i++){
                if(data[i]['value']){
                    total += 1
                }
            }
            return total
        },
    },
    computed: {    
        isPoliAdj(){      
          return this.$parent.$parent.$parent.isPoliAdj
        },
        showIntervensi(){
            if(this.dataKajianPerawat.row.aukprj_is_gangguan_jiwa == 'Y'){
                return this.edTotal >= 90
            } else{
                if(this.dataKajianPerawat.row.ap_usia <= 18){
                    return this.hdTotal >= 12
                }else if(this.dataKajianPerawat.row.ap_usia > 18 && this.dataKajianPerawat.row.ap_usia <= 60){
                    return this.morsheTotal >= 25
                }else if(this.dataKajianPerawat.row.ap_usia > 60){
                    return this.getTotalSydney >=6
                }else{
                    return false
                }
            }
        },
        

        risikoDecTotal(){
            return (this.dataKajianPerawat.row.aukpa_risiko_luka_kondisi_fisik||0) + 
            (this.dataKajianPerawat.row.aukpa_risiko_luka_kesadaran||0) + 
            (this.dataKajianPerawat.row.aukpa_risiko_luka_aktivitas||0) + 
            (this.dataKajianPerawat.row.aukpa_risiko_luka_mobilitas||0) + 
            (this.dataKajianPerawat.row.aukpa_risiko_luka_inkontinensia||0) 
        },
        
        edTotal(){
            return (this.dataKajianPerawat.row.aukprj_ed_umur||0) + 
            (this.dataKajianPerawat.row.aukprj_ed_status_mental||0) + 
            ((this.dataKajianPerawat.row.aukprj_ed_pola_pikir == 11 ? (11+1): this.dataKajianPerawat.row.aukprj_ed_pola_pikir)||0) + 
            (this.dataKajianPerawat.row.aukprj_ed_pengobatan||0) + 
            (this.dataKajianPerawat.row.aukprj_ed_diagnosis||0) + 
            (this.dataKajianPerawat.row.aukprj_ed_ambulasi||0) + 
            (this.dataKajianPerawat.row.aukprj_ed_nutrisi||0) + 
            (this.dataKajianPerawat.row.aukprj_ed_riwayat_jatuh||0) 
        },

        hdTotal(){
            return (this.dataKajianPerawat.row.aukprj_humpty_umur||0) + 
            (this.dataKajianPerawat.row.aukprj_humpty_jenis_kelamin||0) + 
            (this.dataKajianPerawat.row.aukprj_humpty_diagnosis||0) + 
            (this.dataKajianPerawat.row.aukprj_humpty_gangguan_kognitif||0) + 
            (this.dataKajianPerawat.row.aukprj_humpty_faktor_lingkungan||0) + 
            (this.dataKajianPerawat.row.aukprj_humpty_respon_pembedahan||0) +
            (this.dataKajianPerawat.row.aukprj_humpty_respon_penggunaan_obat||0) 
        },

        morsheTotal(){
            return (this.dataKajianPerawat.row.aukprj_morshe_riwayat_jatuh||0) + 
            (this.dataKajianPerawat.row.aukprj_morshe_diagnosa||0) + 
            (this.dataKajianPerawat.row.aukprj_morshe_alat_bantu||0) + 
            (this.dataKajianPerawat.row.aukprj_morshe_inpus||0) + 
            (this.dataKajianPerawat.row.aukprj_morshe_cara_jalan||0) + 
            (this.dataKajianPerawat.row.aukprj_morshe_status_mental||0) 
        },

        
        getTotalSydney(){
            let total = 0
            
            if(this.dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh == "Y" || this.dataKajianPerawat.row.aukprj_riwayat_jatuh_is_jatuh2bulan == "Y"){
                total = total + 6
            }

            if(this.dataKajianPerawat.row.aukprj_status_mental_dellirium == "Y" || this.dataKajianPerawat.row.aukprj_status_mental_disorientasi == "Y" ||
            this.dataKajianPerawat.row.aukprj_status_mental_agitasi == "Y" ){
                total = total + 14
            }
            
            if(this.dataKajianPerawat.row.aukprj_penglihatan_kacamata == "Y" || this.dataKajianPerawat.row.aukprj_penglihatan_buram == "Y" ||
            this.dataKajianPerawat.row.aukprj_penglihatan_glaucoma == "Y" ){
                total = total + 1
            }

            if(this.dataKajianPerawat.row.aukprj_kebiasaan == "Y"){
                total = total + 2
            }

            if(this.dataKajianPerawat.row.aukprj_transfer + this.dataKajianPerawat.row.aukprj_mobilitas > 3){
                total = total + 7
            }
            return total  
        },
    }
}

</script>