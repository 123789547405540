<template>
  <div>
    <validation-observer ref="VFormCatatanPemberian">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <small class="text-info">*Data Pemberian Obat Pasien tidak termasuk Alat Kesehatan</small>        
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <b-table
                bordered
                striped
                small
                hover
                :fields="farmTableFields"
                :items="isParent.resepObat"
            >
                <template #cell(aranrrpr_jenis)="rowTable">
                    <template>
                    {{getConfigDynamic(Config.mr.configResepRanap,rowTable.value)}}
                    </template>
                </template>
                <template #cell(aranrrpr_item_id)="rowTable">
                    <template>
                        <a v-if="rowTable.item.aranrrpr_jenis == 3" href="javascript:;"  @click="toRacikan(isParent.resepObat[rowTable.index],rowTable.index)" data-popup="tooltip" title="" data-toggle="modal" data-target="#modalRacikanDetail" class="font-weight-semibold border-bottom" data-original-title="Lihat Detail Resep">{{rowTable.item.aranrrpr_nama||"-"}}</a>
                        <span v-else>
                            <template v-if="rowTable.value !== 99999">
                                    {{rowTable.item.aranrrpr_nama||"-"}}
                            </template>
                            <template v-else>
                                    {{rowTable.item.aranrrpr_item_lainnya||"-"}}
                            </template>
                        </span><br/>
                    </template>
                </template>
                <template #row-details="rowTable">
                    <div class="card mb-0">
                        <div class="card-body">
                            <h5 class="card-title font-weight-semibold">Komposisi Racikan</h5>
                            <p>{{rowRacikan.aranrrpr_jumlah||"-"}} {{rowRacikan.aranrrpr_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri dari:</p>
                            <table class="table table-striped table-sm table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th width="40">#</th>
                                        <th>Nama Obat</th>
                                        <th>Qty</th>
                                        <th>Frekuensi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in rowRacikan.aranrrpr_racikan" :key="k+'rowracik'">
                                        <td>{{k+1}}</td>
                                        <td>{{getObat(v.value)||"-"}}</td>
                                        <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                                        <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <button @click="rowTable.toggleDetails" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Tutup</button>
                        </div>
                    </div>
                </template>
                <template #cell(aranrrpr_jumlah)="rowTable">
                  {{rowTable.value||"-"}} {{rowTable.item.aranrrpr_satuan||"-"}}
                </template>
                <template #cell(aranrrpr_frekuensi)="rowTable">
                  <span v-if="rowTable.value !== 99999">
                      {{rowTable.item.mdo_name || "-"}}
                  </span>
                  <span v-else>
                      {{rowTable.item.aranrrpr_frekuensi_lainnya || "-"}}
                  </span>
                </template>
                <template #cell(aranrrpr_keterangan)="rowTable">
                  {{rowTable.value || "-"}}
                </template>
            </b-table>
          </div>
        </div>
        
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="text-right">
              <button type="button" @click="isParent.openModal = false" class="btn" data-dismiss="modal">Tutup</button>
              <button type="submit" class="btn btn-primary ml-1">Submit Data <i class="icon-paperplane ml-2"></i> 
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  data() {
    return {
      farmTableFields: [
        { key: 'aranrrpr_jenis', label: 'Jenis', thAttr: { width: 160 } },
        { key: 'aranrrpr_item_id', label: 'Nama', thAttr: { width: 320 } },
        { key: 'aranrrpr_jumlah', label: 'Jumlah', thAttr: { width: 150 } },
        { key: 'aranrrpr_frekuensi', label: 'Frekuensi', thAttr: { width: 210 } },
        { key: 'aranrrpr_keterangan', label: 'Keterangan' },
      ],
      mObat: [],
      rowRacikan: {}
    }
  },
  methods: {
    toRacikan(v,k=null){
        console.log(v)
        this.loadingOverlay = true
        let data = {
            id: v.aranrrpr_item_id,
            type: 'get-racikan-data'
        }

        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        ).then(res=>{
            this.loadingOverlay = false
            this.rowRacikan = {
                ...v,
                ...res.data.row
            }
            this.isParent.resepObat[k]['_showDetails'] = true
            this.mObat = res.data.mObat
        })
    },
    getObat(val){
        let index = this.mObat.findIndex(x => x.value == val)        
        if(index !== -1){
            return this.mObat[index]['text']
        }else{
            return null
        }
    },
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    doSubmit(){
      this.$refs['VFormCatatanPemberian'].validate().then(success => {  
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowEdit
              data.type = 'submit-pemberian'
              data.resepObat = this.isParent.resepObat
              data.isSubmit = true

              this.isParent.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.isParent.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.isParent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.isParent.openModal = false
                    this.isParent.apiGet()
                  })
              }).catch(err => {
                  this.isParent.loadingOverlay = false
                  if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                  }
                  this.isParent.doSetAlertForm(err)
              })
            }
          }) 
        }
      })
    },
  },
}
</script>